import React, { useState } from 'react';
import { SpinnerBlock, useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { Select } from 'antd';

const WHERE = [];
const defaultStyle = { minWidth: '6rem' };

const ReferenceChoice = React.forwardRef(
  ({ value, onChange, collection, where = WHERE, render, order, style = defaultStyle }, ref) => {
    const firestore = useFirestore();
    const { data, loading: loadingMaterials } = useCollectionWhere(collection, null, where, order);
    const basePath = firestore().collection(collection);

    const initial = value ? value.id : null;
    const [state, setState] = useState(initial);

    const dispatchChange = val => {
      setState([val]);
      onChange(basePath.doc(val));
    };

    if (loadingMaterials) {
      return <SpinnerBlock />;
    }

    return (
      <Select onChange={dispatchChange} value={state} ref={ref} style={style}>
        {data.map(d => (
          <Select.Option key={d.__id} value={d.__id}>
            {render(d)}
          </Select.Option>
        ))}
      </Select>
    );
  },
);

export default ReferenceChoice;
