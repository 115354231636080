/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Upload,
  Icon,
  List,
  notification,
  PageHeader,
  Row,
  Typography,
  Form,
  Input,
  Popconfirm,
  Radio,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import {
  useCollectionWhere,
  useFirestore,
  useFunction,
  useHandleSubmit,
  ImportContent,
} from '@betwyll/btw-core-backoffice';
import Paragraph from 'antd/es/typography/Paragraph';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import htmlProcess from '../../lib/htmlProcess';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function ContentItem({ item, update, delete: deleteFn, loading }) {
  return (
    <List.Item
      key={item.__id}
      extra={[
        <Link key="3" to={`contents/${item.__id}/blocks/`}>
          <Button loading={loading}>
            <Icon type="form" />
          </Button>
        </Link>,
        <Divider key="4" type="vertical" />,
        <Popconfirm
          key="5"
          onConfirm={() => deleteFn({ contentDocumentId: item.__id })}
          title="Are you sure you want to delete this content?"
        >
          <Button type="danger" loading={loading}>
            <Icon type="delete" />
          </Button>
        </Popconfirm>,
      ]}
    >
      <List.Item.Meta
        title={
          <Paragraph editable={{ onChange: title => update(item.__id, title) }}>
            {item.title}
          </Paragraph>
        }
      />
    </List.Item>
  );
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? '#eee' : 'white',
  paddingLeft: isDragging ? '1rem' : null,

  // styles we need to apply on draggables
  ...draggableStyle,
});

function DraggableContentItem({ item, index }) {
  return (
    <Draggable draggableId={item.__id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <List.Item
            extra={[
              <Icon
                key="handler"
                type="drag"
                {...provided.dragHandleProps}
                style={{ marginRight: '2rem' }}
              />,
            ]}
          >
            <List.Item.Meta title={item.title} />
          </List.Item>
        </div>
      )}
    </Draggable>
  );
}

async function sortUpdate([sortedData], { onSuccess, firestore, materialId }) {
  try {
    const batch = firestore().batch();
    const base = firestore()
      .collection('materials')
      .doc(materialId)
      .collection('contents');
    sortedData.forEach((sortedItem, index) => {
      if (index !== sortedItem.index) {
        batch.update(base.doc(sortedItem.__id), { index });
      }
    });

    await batch.commit();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

async function updateTitle([id, title], { firestore, materialId }) {
  try {
    const base = firestore()
      .collection('materials')
      .doc(materialId)
      .collection('contents');

    await base.doc(id).update({ title });
  } catch (e) {
    notification.error({ message: e.message });
  }
}

function CreateContentForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('contents');
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  return (
    <Form {...formLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('title')}>
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('content')} help={t('content_hint')}>
        {getFieldDecorator('content', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input.TextArea rows={4} />)}
      </Form.Item>
      <Form.Item label={t('separator')} help={t('separator_hint')}>
        {getFieldDecorator('doubleNewLine', {
          initialValue: true,
          rules: [
            {
              required: true,
            },
          ],
        })(
          <Radio.Group>
            <Radio value={true}>{t('option_doubleline')}</Radio>
            <Radio value={false}>{t('option_singleline')}</Radio>
          </Radio.Group>,
        )}
      </Form.Item>
      <Row type="flex" justify="end" style={{ marginTop: '1.5rem' }}>
        <Button htmlType="submit" loading={loading} type="primary">
          {t('create')}
        </Button>
      </Row>
    </Form>
  );
}

const ContentForm = Form.create()(CreateContentForm);

async function createContent([data, clean], { firestore, materialId, lastIndex }) {
  try {
    const base = firestore()
      .collection('materials')
      .doc(materialId);
    const contentRef = await base
      .collection('contents')
      .add({ title: data.title, index: lastIndex });
    const blocks = htmlProcess(
      data.content,
      () => contentRef.collection('blocks').doc().id,
      null,
      data.doubleNewLine,
    );
    const chunkSize = Math.ceil(blocks.length / 500);
    const parallelChunks = [];
    for (let i = 0; i < chunkSize; i += 1) {
      const batch = firestore().batch();

      const sliced = blocks.slice(i * 500, (i + 1) * 500);
      sliced.forEach(({ __id, ...other }) => {
        batch.set(contentRef.collection('blocks').doc(__id), other);
      });

      parallelChunks.push(batch.commit());
    }

    await Promise.all(parallelChunks);

    clean();
  } catch (e) {
    console.log(e);
    notification.error({ message: e.message });
  }
}

const WHERE = [];
const ORDER = ['index'];
export default function ContentsList() {
  const firestore = useFirestore();
  const { materialId } = useParams();
  const [sorting, setIsSorting] = useState(false);
  const [sortData, setSortData] = useState([]);

  const { t } = useTranslation(['contents', 'common']);

  const basePath = firestore()
    .collection('materials')
    .doc(materialId);

  const { data, loading } = useCollectionWhere('contents', basePath, WHERE, ORDER);

  const setSorting = useCallback(state => {
    setIsSorting(state);
    setSortData(cloneDeep(data));
  });

  const onDragEnd = useCallback(result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(sortData, result.source.index, result.destination.index);

    setSortData(items);
  });

  const { run: runSort, isPending: isSavingSort } = useAsync({
    deferFn: sortUpdate,
    firestore,
    materialId,
    onSuccess: () => setSorting(false),
  });

  const { run: runUpdateTitle } = useAsync({
    deferFn: updateTitle,
    firestore,
    materialId,
  });

  const [deleting, setDeleting] = useState(false);
  const onStartDeleting = useCallback(() => setDeleting(true), []);
  const onEndDeleting = useCallback(() => setDeleting(false), []);

  const runDelete = useFunction({
    name: 'contentDeleteRequest',
    preBody: { materialDocumentId: materialId },
    onStart: onStartDeleting,
    onSuccess: onEndDeleting,
    onError: e => {
      notification.error({ message: e.message });
      onEndDeleting();
    },
  });

  const { run: runCreateContent, isPending: creatingContent } = useAsync({
    deferFn: createContent,
    firestore,
    materialId,
    lastIndex: data.length,
  });

  return (
    <>
      <PageHeader title={t('contents')} />
      <DragDropContext onDragEnd={onDragEnd}>
        {!sorting && (
          <List
            footer={
              data.length > 1 && (
                <Row type="flex" justify="end" gutter={16}>
                  <Col>
                    <Button onClick={() => setSorting(true)}>{t('sort')}</Button>
                  </Col>
                </Row>
              )
            }
            dataSource={data}
            loading={loading}
            renderItem={item => (
              <ContentItem
                item={item}
                update={runUpdateTitle}
                delete={runDelete}
                loading={deleting}
              />
            )}
          />
        )}
        {sorting && (
          <>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sortData.map((item, index) => (
                    <DraggableContentItem item={item} index={index} key={item.__id} />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Row type="flex" justify="end" gutter={16}>
              <Col>
                <Button onClick={() => setSorting(false)}>{t('common:cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" loading={isSavingSort} onClick={() => runSort(sortData)}>
                  {t('common:save')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </DragDropContext>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Paragraph>{t('bulk_create')}</Typography.Paragraph>
          <Upload.Dragger name="file">
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{t('bulk_create_upload')}</p>
          </Upload.Dragger>
          <Typography.Paragraph style={{ marginTop: '1.5rem ' }}>
            {t('import-exported-content')}
          </Typography.Paragraph>
          <ImportContent id={materialId} />
        </Col>
        <Col span={12}>
          <Typography.Paragraph>{t('manually_create')}</Typography.Paragraph>
          <ContentForm onSubmit={runCreateContent} loading={creatingContent} />
        </Col>
      </Row>
    </>
  );
}
