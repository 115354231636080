import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerBlock, useDocument } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';

async function update([value], { reference }) {
  await reference.doc('pearsonIes').set({
    syncDisabled: value,
  });
}

export default function SyncToggle({ reference }) {
  const { t } = useTranslation('users');

  const { data, isLoading } = useDocument('pearsonIes', null, reference);
  const { run } = useAsync({
    deferFn: update,
    reference,
  });

  if (isLoading) {
    return <SpinnerBlock />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <Switch title={t('pearson-sync-disable')} checked={data.syncDisabled} onChange={run} />
      <span style={{ marginLeft: '.5rem' }}>{t('pearson-sync-disable')}</span>
    </>
  );
}
