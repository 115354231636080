import React, { useCallback, useState } from 'react';
import {
  Button,
  Icon,
  Input,
  PageHeader,
  Table,
  DatePicker,
  Switch,
  Select,
  Form,
  Tag,
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { collectionData } from 'rxfire/firestore';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { some, uniqBy, intersectionBy } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { compose } from 'recompose';

import { merge, map, first } from 'rxjs/operators';
import withFirestore from '../../components/withFirestore';
import { ROLES, ROLES_KEYES } from '../../permissions';
import ReferenceRender from '../../components/ReferenceRender';
import { withPermissions } from '../../contexts/Permissions';
import RoleSelect from '../../components/RoleSelect';
import RemoteSelect from '../../components/RemoteSelect';

const columns = (t, firestore) => [
  {
    title: t('nickname'),
    dataIndex: 'nickname',
    sorter: (a, b) => {
      const { nickname: nameA = '' } = a;
      const { nickname: nameB = '' } = b;
      return nameA.localeCompare(nameB);
    },
  },
  {
    title: t('name'),
    dataIndex: 'name',
    sorter: (a, b) => {
      const { name: nameA = '' } = a;
      const { name: nameB = '' } = b;
      return nameA.localeCompare(nameB);
    },
  },
  {
    title: t('twylls'),
    dataIndex: 'twyllsCount',
    sorter: (a, b) => {
      const { twyllsCount: parsedA = 0 } = a;
      const { twyllsCount: parsedB = 0 } = b;
      return parsedA - parsedB;
    },
  },
  {
    title: t('last-login'),
    render: (_, record) => {
      const ref = firestore()
        .collection('users')
        .doc(record.__id)
        .collection('userStatus')
        .doc('lastSeenOnline');
      return (
        <ReferenceRender
          reference={ref}
          render={data => {
            return data.lastSeenOnlineDate
              ? moment(data.lastSeenOnlineDate.toDate()).format('DD/MM/YYYY H:mm')
              : t('never');
          }}
        />
      );
    },
  },
  {
    title: t('roles'),
    render: (_, record) => {
      const ref = firestore()
        .collection('users')
        .doc(record.__id);

      return (
        <>
          {ROLES_KEYES.map(rk => (
            <ReferenceRender
              key={rk}
              reference={ref.collection('userRoles').doc(ROLES[rk])}
              render={({ value }) => {
                return value ? (
                  <>
                    <Tag style={{ marginBottom: '.5rem' }}>{t(`roles:${ROLES[rk]}`)}</Tag>
                    <br />
                  </>
                ) : null;
              }}
            />
          ))}
        </>
      );
    },
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
      </span>
    ),
  },
];

async function extractParents(ref) {
  const res = [];

  const qs = await ref.get();
  const docQs = [];
  qs.forEach(doc => {
    docQs.push(doc.ref.parent.parent);
  });

  await Promise.all(
    docQs.map(docRef => {
      return docRef.get().then(ds => res.push({ ...ds.data(), __id: docRef.id }));
    }),
  );

  return res;
}

function getTitle(t, options = {}) {
  if (options.following) {
    return t('following-project');
  }
  return t('home:users');
}

function InputFilter({ onChange }) {
  const [state, setState] = useState();
  const dispatch = useCallback(
    e => {
      const val = e.target.value;
      setState(val);
      onChange(val);
    },
    [onChange],
  );

  return <Input value={state} onChange={dispatch} />;
}

function FromToSelector({ onChange }) {
  return <DatePicker.RangePicker onChange={onChange} />;
}

function LogicSwitch({ onChange, value }) {
  return (
    <>
      <Switch checked={value} onChange={onChange} />
    </>
  );
}

const WHERE_NOT_PROJECT_DELETED = [
  {
    field: 'deletedAt',
    op: '==',
    value: null,
  },
];

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.logicAnd$ = new BehaviorSubject(true);
    this.startsWithUsernameSbj$ = new BehaviorSubject(null);
    this.modifiedFromToSbj$ = new BehaviorSubject(null);
    this.followProjectsSbj$ = new BehaviorSubject(null);
    this.followUsersSbj$ = new BehaviorSubject(null);
    this.roleSbj$ = new BehaviorSubject(null);
    this.pearsonIesSbj$ = new BehaviorSubject(null);
    this.emailSbj$ = new BehaviorSubject(null);
    this.statusSbj$ = new BehaviorSubject(null);

    this.state = {
      data: [],
      filteredData: [],
      loading: true,
      filters: [false],
      logicAnd: true,
      projectSelected: [],
    };
  }

  componentDidMount() {
    this.subscriptions = [];
    const { firestore, location } = this.props;
    const usersRef = firestore().collection('users');
    this.users$ = collectionData(usersRef, '__id');
    let s = this.users$.subscribe(data => {
      this.setState(st => ({ ...st, data, loading: false }));
    });
    this.subscriptions.push(s);

    const params = queryString.parse(location.search);

    if (params && params.following) {
      this.setProjects([params.following]);
    }

    this.filteredUsers$ = combineLatest(
      this.startsWithUsernameSbj$,
      this.modifiedFromToSbj$,
      this.followProjectsSbj$,
      this.followUsersSbj$,
      this.roleSbj$,
      this.pearsonIesSbj$,
      this.emailSbj$,
      this.statusSbj$,
    ).pipe(
      merge(),
      map(e => {
        console.log(e);
        // remove filters setted as null
        let res = e.filter(_ => _);
        if (this.logicAnd$.getValue()) {
          // filter using AND
          res = intersectionBy(...res, '__id');
        } else {
          // filter using OR
          res = uniqBy(res.flat(), '__id');
        }
        return res;
      }),
    );

    s = this.filteredUsers$.subscribe(data => {
      this.setState(st => ({ ...st, filteredData: data }));
    });
    this.subscriptions.push(s);
  }

  componentWillUnmount() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setStartWithQuery = strSearch => {
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (strSearch) {
      const strlength = strSearch.length;
      const strFrontCode = strSearch.slice(0, strlength - 1);
      const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
      const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
      usersRef = usersRef.where('nickname', '>=', strSearch).where('nickname', '<', endcode);

      const users$ = collectionData(usersRef, '__id');
      users$.pipe(first()).subscribe(data => {
        this.setState(st => {
          const filtering = [...st.filters];
          filtering[0] = true;
          return { ...st, loading: false, filters: filtering };
        });

        this.startsWithUsernameSbj$.next(data);
      });
    } else {
      this.startsWithUsernameSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[0] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setFromToQuery = async ([from, to]) => {
    const { firestore } = this.props;
    try {
      const fromT = firestore.Timestamp.fromDate(from.toDate());
      const toT = firestore.Timestamp.fromDate(to.toDate());
      const usersRef = firestore()
        .collectionGroup('userStatus')
        .where('lastSeenOnlineDate', '>=', fromT)
        .where('lastSeenOnlineDate', '<=', toT);
      const data = await extractParents(usersRef);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[1] = true;
        return { ...st, loading: false, filters: filtering };
      });
      this.modifiedFromToSbj$.next(data);
    } catch (e) {
      console.log(e);
      this.modifiedFromToSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[1] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setLogic = val => {
    this.setState(st => ({
      ...st,
      logicAnd: val,
    }));
    this.logicAnd$.next(val);
    this.startsWithUsernameSbj$.next(this.startsWithUsernameSbj$.getValue());
  };

  setProjects = projectsId => {
    const { firestore } = this.props;
    let projectsRef = firestore().collection('projects');
    let usersRef = firestore().collection('users');

    if (projectsId && projectsId.length > 0) {
      projectsRef = projectsId.map(id => projectsRef.doc(id));
      usersRef = usersRef.where('followingProjectsRefs', 'array-contains-any', projectsRef);

      const users$ = collectionData(usersRef, '__id');
      users$.pipe(first()).subscribe(data => {
        this.setState(st => {
          const filtering = [...st.filters];
          filtering[2] = true;
          return { ...st, loading: false, filters: filtering, selectedProjects: projectsId };
        });

        this.followProjectsSbj$.next(data);
      });
    } else {
      this.followProjectsSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[2] = false;
        return { ...st, filters: filtering, selectedProjects: [] };
      });
    }
  };

  setUsers = usersId => {
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (usersId && usersId.length > 0) {
      const followingRef = usersId.map(id => usersRef.doc(id));
      usersRef = usersRef.where('followingUsersRefs', 'array-contains-any', followingRef);

      const users$ = collectionData(usersRef, '__id');
      users$.pipe(first()).subscribe(data => {
        this.setState(st => {
          const filtering = [...st.filters];
          filtering[3] = true;
          return { ...st, loading: false, filters: filtering };
        });

        this.followUsersSbj$.next(data);
      });
    } else {
      this.followUsersSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[3] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setRole = async role => {
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (role && role !== 'any') {
      usersRef = firestore()
        .collectionGroup('userRoles')
        .where('roleId', '==', role);

      const users = await extractParents(usersRef);

      this.setState(st => {
        const filtering = [...st.filters];
        filtering[4] = true;
        return { ...st, loading: false, filters: filtering };
      });

      this.roleSbj$.next(users);
    } else {
      this.roleSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[4] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setPearsonIES = async pearsonId => {
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (pearsonId) {
      usersRef = firestore()
        .collectionGroup('userAccounts')
        .where('pearsonIesUserId', '==', pearsonId)
        .where('providerId', '==', 'pearsonIes');

      const users = await extractParents(usersRef);

      this.setState(st => {
        const filtering = [...st.filters];
        filtering[5] = true;
        return { ...st, loading: false, filters: filtering };
      });

      this.roleSbj$.next(users);
    } else {
      this.roleSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[5] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setEmail = async strSearch => {
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (strSearch) {
      const strlength = strSearch.length;
      const strFrontCode = strSearch.slice(0, strlength - 1);
      const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
      const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

      usersRef = firestore()
        .collectionGroup('userAccounts')
        .where('email', '>=', strSearch)
        .where('email', '<', endcode)
        .where('providerId', '==', 'emailPassword');

      const users = await extractParents(usersRef);

      this.setState(st => {
        const filtering = [...st.filters];
        filtering[6] = true;
        return { ...st, loading: false, filters: filtering };
      });

      this.roleSbj$.next(users);
    } else {
      this.roleSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[6] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  setStatus = async status => {
    console.log(status);
    const { firestore } = this.props;
    let usersRef = firestore().collection('users');

    if (status && ['SUSPENDED', 'ACTIVE'].includes(status)) {
      switch (status) {
        case 'SUSPENDED':
          usersRef = usersRef.where('suspendedAt', '<=', firestore.Timestamp.now());
          break;
        case 'ACTIVE':
          usersRef = usersRef.where('suspendedAt', '==', null);
          break;
        default:
          break;
      }

      const users$ = collectionData(usersRef, '__id');
      users$.pipe(first()).subscribe(data => {
        this.setState(st => {
          const filtering = [...st.filters];
          filtering[7] = true;
          return { ...st, loading: false, filters: filtering };
        });

        this.statusSbj$.next(data);
      });
    } else {
      this.statusSbj$.next(null);
      this.setState(st => {
        const filtering = [...st.filters];
        filtering[7] = false;
        return { ...st, filters: filtering };
      });
    }
  };

  navigateToNotifications = () => {
    const { history } = this.props;
    const { data, filteredData, filters } = this.state;

    const isFiltering = some(filters);
    const ids = isFiltering ? filteredData : data;

    history.push(
      '/app/notifications/send/',
      ids.map(u => u.__id),
    );
  };

  render() {
    const { t, firestore, permissions } = this.props;
    const { data, filteredData, loading, filters, logicAnd, selectedProjects } = this.state;

    const isFiltering = some(filters);

    console.log(filteredData);

    return (
      <>
        <PageHeader
          title={getTitle(t)}
          extra={[
            <Link to="create/" key="1">
              <Button>{t('create')}</Button>
            </Link>,
            (permissions[ROLES.ADMIN] || permissions[ROLES.GROWTH_MANAGER]) && (
              <Button key="sendNotification" onClick={this.navigateToNotifications}>
                {t('send-notification')}
              </Button>
            ),
          ]}
        />
        <Form layout="inline">
          <Form.Item label={t('intersect-queries')}>
            <LogicSwitch value={logicAnd} t={t} onChange={this.setLogic} />
          </Form.Item>
          <Form.Item label={t('nickname')}>
            <InputFilter onChange={this.setStartWithQuery} />
          </Form.Item>
          <Form.Item label={t('last-login')}>
            <FromToSelector onChange={this.setFromToQuery} />
          </Form.Item>
          <Form.Item label={t('follows-projects')}>
            <RemoteSelect
              extraWhere={WHERE_NOT_PROJECT_DELETED}
              collection="projects"
              field="name"
              onChange={this.setProjects}
              value={selectedProjects}
            />
          </Form.Item>
          <Form.Item label={t('followers-of')}>
            <RemoteSelect collection="users" field="nickname" onChange={this.setUsers} />
          </Form.Item>
          <Form.Item label={t('has-role')}>
            <RoleSelect onChange={this.setRole} />
          </Form.Item>
          <Form.Item label={t('pearson-ies')}>
            <InputFilter onChange={this.setPearsonIES} />
          </Form.Item>
          <Form.Item label={t('email')}>
            <InputFilter onChange={this.setEmail} />
          </Form.Item>
          <Form.Item label={t('status')}>
            <Select defaultValue="null" style={{ minWidth: '7rem' }} onChange={this.setStatus}>
              <Select.Option value="null">{t('all')}</Select.Option>
              <Select.Option value="SUSPENDED">{t('suspended')}</Select.Option>
              <Select.Option value="ACTIVE">{t('active')}</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Table
          columns={columns(t, firestore)}
          dataSource={isFiltering ? filteredData : data}
          loading={loading}
          rowKey="__id"
        />
      </>
    );
  }
}

const enhance = compose(
  withPermissions,
  withRouter,
  withTranslation(['users', 'home', 'roles']),
  withFirestore,
);

export default enhance(UserList);
