import React, { useMemo } from 'react';
import { Button, Col, Form, Input, notification, PageHeader, Row } from 'antd';
import { useBack, useHandleSubmit, useFirestore, useAuth } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import ReferenceChoice from '../../components/ReferenceChoice';
import { getLabel } from '../../lib/utils';

const WHERE = {
  field: 'publishedAt',
  op: '!=',
  value: null,
};

const ORDER = ['index', 'asc'];

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 6 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;

  const { t, i18n } = useTranslation('materials');

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('title')} extra={t('title_hint')}>
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('label')}>
        {getFieldDecorator('label', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('material')}>
        {getFieldDecorator('materialRef', {
          rules: [{ required: true }],
        })(<ReferenceChoice collection="materials" where={WHERE} render={m => m.title} />)}
      </Form.Item>
      <Form.Item label={t('defaultDidacticCategory')}>
        {getFieldDecorator('defaultDidacticCategoryRef', {
          rules: [{ required: true }],
        })(
          <ReferenceChoice
            collection="didacticCategories"
            order={ORDER}
            render={cat => getLabel(cat.labelL10n, activeLang)}
          />,
        )}
      </Form.Item>
      <Row type="flex" justify="end">
        <Col span={4}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('create')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const DidacticSetCreateForm = Form.create()(CreateForm);

async function createDidacticSet([data, clean], { ref, onSuccess }) {
  try {
    await ref.set(data);
    clean();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function DidacticSetsCreate() {
  const onBack = useBack();
  const firestore = useFirestore();
  const auth = useAuth();

  const ref = firestore()
    .collection('didacticSets')
    .doc();

  const { run, isPending: creating } = useAsync({
    deferFn: createDidacticSet,
    firestore,
    ref,
    auth,
    onSuccess: onBack,
  });

  const { t } = useTranslation('materials');

  return (
    <>
      <PageHeader title={t('new_didacticSet')} onBack={onBack} />
      <DidacticSetCreateForm onSubmit={run} loading={creating} id={ref.id} />
    </>
  );
}
