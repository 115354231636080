import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Icon, Input } from 'antd';
import { useLogin } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';

function LoginForm({ form }) {
  const handleSubmit = useLogin(form);
  const { t } = useTranslation('login');

  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [{ required: true, message: t('email_required_message') }],
        })(<Input prefix={<Icon type="user" style={{ fontSize: 13 }} />} placeholder="Email" />)}
      </Form.Item>

      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: t('password_required_message') }],
        })(
          <Input
            prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
            type="password"
            placeholder="Password"
          />,
        )}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          {t('login')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Form.create()(LoginForm);

LoginForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
};
