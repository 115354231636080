import React, { useMemo } from 'react';
import { Button, Col, Icon, PageHeader, Row, Table } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLabel } from '../../lib/utils';

const columns = (t, lang) => [
  {
    title: t('label'),
    dataIndex: 'labelL10n',
    render: labels => getLabel(labels, lang),
  },
  {
    title: t('index'),
    dataIndex: 'index',
  },
  {
    title: t('actions'),
    render: record => (
      <span>
        <Link to={`${record.__id}/`}>
          <Button>
            <Icon type="edit" />
          </Button>
        </Link>
        {/* <Divider type="vertical" /> */}
        {/* <Popconfirm title={t('are-you-sure')} onConfirm={() => record.__ref.delete()}> */}
        {/*  <Button type="danger"> */}
        {/*    <Icon type="delete" /> */}
        {/*  </Button> */}
        {/* </Popconfirm> */}

        {/* <Icon type="delete" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} /> */}
      </span>
    ),
  },
];

const ORDER = ['index', 'asc'];
const WHERE = [];

export default function MaterialsList() {
  const history = useHistory();
  const { data, loading } = useCollectionWhere('didacticCategories', null, WHERE, ORDER);
  const { t, i18n } = useTranslation(['materials']);

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader
        title={t('didacticCategories')}
        onBack={() => history.push('/app/didacticSet/')}
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: '1.5rem' }}>
        <Col span={4}>
          <Link to="create/">
            <Button>{t('add_new')}</Button>
          </Link>
        </Col>
      </Row>
      <Table columns={columns(t, activeLang)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}
