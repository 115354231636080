import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import React from 'react';
import { ROLES, ROLES_KEYES } from '../permissions';

const MIN_WIDTH = { minWidth: '10rem' };

export default function RoleSelect({ onChange }) {
  const { t } = useTranslation('roles');

  return (
    <Select onChange={onChange} defaultValue="any" style={MIN_WIDTH} filterOption={false}>
      <Select.Option value="any">{t('any')}</Select.Option>
      {ROLES_KEYES.map(r => (
        <Select.Option key={ROLES[r]}>{t(ROLES[r])}</Select.Option>
      ))}
    </Select>
  );
}
