import { Switch, Route } from 'react-router-dom';
import React from 'react';
import Send from './Send';
import NotificationsList from './List';
import NotificationCreate from './Create';

export default function Notifications() {
  return (
    <Switch>
      <Route path="/app/notifications/send/" component={Send} />
      <Route path="/app/notifications/create/" component={NotificationCreate} />
      <Route path="/app/notifications/" component={NotificationsList} />
    </Switch>
  );
}
