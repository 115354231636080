import { Button, Col, Form, Icon, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FieldFormWrapper from './FieldFormWrapper';

const manipulate = (transform, fn) => (data, ...args) => fn(transform(data), ...args);

export default function EditableField({
  data,
  label,
  field,
  render = _ => _,
  InputComponent = <Input />,
  initial,
  update,
  updating,
  transform,
  readonly = false,
  decoratorExtra = {},
  inputSpan = 10,
  labelSpan = 3,
  layout,
  align = 'start',
  normalize = _ => _,
}) {
  const [editing, setEditing] = useState(false);
  const toggleEdit = () => setEditing(!editing);
  const { t } = useTranslation('common');

  const onSubmit = transform ? manipulate(transform, update) : update;

  if (editing) {
    return (
      <FieldFormWrapper onSubmit={onSubmit} onSuccess={toggleEdit} layout={layout}>
        {fieldDecorator => (
          <>
            <Form.Item label={label}>
              {fieldDecorator(field || 'field', {
                initialValue: normalize(initial || data[field]),
                ...decoratorExtra,
              })(InputComponent)}
              <Row type="flex" gutter={16} justify="end">
                <Col>
                  <Button onClick={toggleEdit}>{t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" loading={updating}>
                    {t('save')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </>
        )}
      </FieldFormWrapper>
    );
  }

  return (
    <Row gutter={16} style={{ marginTop: '1.5rem' }}>
      {label && <Col span={labelSpan}>{label}</Col>}
      <Col span={inputSpan}>
        <Row gutter={16} type="flex" justify="space-between" align={align}>
          <Col span={20}>{field ? render(data[field]) : render(data)}</Col>
          {!readonly && (
            <Col span={4}>
              <Button onClick={toggleEdit}>
                <Icon type="edit" />
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
