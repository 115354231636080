export const normalizeUpload = e => {
  if (Array.isArray(e)) {
    return [e[e.length - 1]].filter(_ => _);
  }
  return e && [e.fileList[e.fileList.length - 1]].filter(_ => _);
};

export const normalizeUploadMultiple = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export function getLabel(labels, language) {
  console.log(labels);
  if (labels[language]) {
    return labels[language];
  }
  if (labels.en) {
    return labels.en;
  }
  const keys = Object.keys(labels);
  if (keys.length > 0) {
    return labels[keys[0]];
  }
  return 'empty';
}

export default null;
