export const NOTIFICATION_TYPES = t => ({
  broadcast_link_project: t('project'),
  broadcast_link_news: t('news'),
  broadcast_external_resource: t('external-resource'),
});

export const NOTIFICATION_TYPES_OPTIONS = t =>
  Object.keys(NOTIFICATION_TYPES(t)).map(name => ({
    value: name,
    label: NOTIFICATION_TYPES(t)[name],
  }));
