import React from 'react';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  InputNumber,
  notification,
  PageHeader,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd';
import { useBack, useHandleSubmit, useFirestore, useAuth } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import { normalizeUpload } from '../../lib/utils';
import ArrayField from '../../components/ArrayField';
import useUploadProductRequest from '../../hooks/useUploadProductRequest';
import { ROLES } from '../../permissions';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading, id }) {
  const { getFieldDecorator, getFieldValue } = form;

  const { t } = useTranslation(['products', 'home', 'visibility', 'roles']);

  const customRequest = useUploadProductRequest({ productId: id });

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  const isSubscription = getFieldValue('isSubscription');

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('sku')} extra={t('sku_hint')}>
        {getFieldDecorator('sku', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('name')} extra={t('name_hint')}>
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')} extra={t('abstract_hint')}>
        {getFieldDecorator('description', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input.TextArea rows={4} />)}
      </Form.Item>
      <Form.Item label={t('isSubscription')}>
        {getFieldDecorator('isSubscription', {
          valuePropName: 'checked',
          initialValue: false,
        })(<Switch />)}
      </Form.Item>
      {isSubscription && (
        <Form.Item label={t('subscriptionId')}>
          {getFieldDecorator('subscriptionId', {
            rules: [
              {
                required: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
      )}
      {isSubscription && (
        <Form.Item label={t('planId')}>
          {getFieldDecorator('planId', {
            rules: [
              {
                required: true,
              },
            ],
          })(<Input />)}
        </Form.Item>
      )}
      {isSubscription && (
        <>
          <Form.Item label={t('ownedGroupsLimit')}>
            {getFieldDecorator('ownedGroupsLimit', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item label={t('groupUsersLimit')}>
            {getFieldDecorator('groupUsersLimit', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item label={t('ownedPrivateProjectsLimit')}>
            {getFieldDecorator('ownedPrivateProjectsLimit', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item label={t('privateProjectUsersLimit')}>
            {getFieldDecorator('privateProjectUsersLimit', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item label={t('privateProjectGroupsLimit')}>
            {getFieldDecorator('privateProjectGroupsLimit', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
        </>
      )}

      <Form.Item label={t('cover')}>
        {getFieldDecorator('image', {
          valuePropName: 'fileList',
          initialValue: null,
          getValueFromEvent: normalizeUpload,
        })(
          <Upload.Dragger
            customRequest={customRequest}
            listType="picture"
            showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{t('cover_upload_text')}</p>
          </Upload.Dragger>,
        )}
      </Form.Item>
      <Form.Item label={t('linked-objects')}>
        {getFieldDecorator('linkedObjectsIds', {
          initialValue: null,
          rules: [],
        })(<ArrayField />)}
      </Form.Item>
      <Form.Item label={t('grantsRole')}>
        {getFieldDecorator('grantsRole', {
          initialValue: null,
          rules: [],
        })(
          <Select>
            <Select.Option value={ROLES.STRONG}>{t(`roles:${ROLES.STRONG}`)}</Select.Option>
            <Select.Option value={ROLES.SUPER}>{t(`roles:${ROLES.SUPER}`)}</Select.Option>
          </Select>,
        )}
      </Form.Item>
      <Row type="flex" justify="end" gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const ProductCreateForm = Form.create()(CreateForm);

async function createProduct([data, clean], { firestore, ref, onSuccess }) {
  try {
    await ref.doc(data.sku.trim()).set({
      isSubscription: false,
      ...omit(data, 'image', 'sku'),
      image: data.image && data.image[0] ? data.image[0].response.download : null,
      createdAt: firestore.Timestamp.now(),
      modifiedAt: firestore.Timestamp.now(),
      deletedAt: null,
    });
    clean();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function ProductsCreate() {
  const onBack = useBack();
  const firestore = useFirestore();

  const ref = firestore().collection('products');

  const auth = useAuth();
  const { run, isPending: creating } = useAsync({
    deferFn: createProduct,
    firestore,
    ref,
    auth,
    onSuccess: onBack,
  });

  const { t } = useTranslation('products');

  return (
    <>
      <PageHeader title={t('new_product')} onBack={onBack} />
      <ProductCreateForm onSubmit={run} loading={creating} id={ref.id} />
    </>
  );
}
