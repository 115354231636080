import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MaterialsList from './List';
import MaterialsCreate from './Create';
import EditMaterial from './Edit';
import Blocks from './Blocks';

export default function DidacticSet() {
  return (
    <Switch>
      <Route path="/app/didacticSet/create/" exact component={MaterialsCreate} />
      <Route
        path="/app/didacticSet/:didacticSetId/materials/:materialId/contents/:contentId/"
        component={Blocks}
      />
      <Route path="/app/didacticSet/:didacticSetId/" component={EditMaterial} />
      <Route path="/app/didacticSet/" component={MaterialsList} />
    </Switch>
  );
}
