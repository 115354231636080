import { PageHeader, Spin, Table } from 'antd';
import { useBack, useDocument } from '@betwyll/btw-core-backoffice';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReferenceLink from '../../components/ReferenceLink';

const peopleColumns = t => [
  {
    title: t('name'),
    render: (_, record) => (
      <ReferenceLink
        formatter={({ nickname }) => nickname}
        to={({ __id }) => `/app/users/${__id}/`}
        reference={record}
      />
    ),
  },
];

const projectsColumns = t => [
  {
    title: t('title'),
    render: (_, record) => (
      <ReferenceLink
        formatter={({ title }) => title}
        to={({ __id }) => `/app/projects/${__id}/`}
        reference={record}
      />
    ),
  },
];

export default function EditGroup() {
  const { groupId } = useParams();
  const { t } = useTranslation('groups');
  const { data, loading } = useDocument(groupId, 'groups');
  const goBack = useBack('../');

  console.log(data);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('group')} {loading ? <Spin /> : data.title}
          </>
        }
        onBack={goBack}
      />
      <PageHeader title={t('people')} />
      <Table columns={peopleColumns(t)} dataSource={data.usersRefs} loading={loading} rowKey="id" />
      <PageHeader title={t('projects')} />
      <Table
        columns={projectsColumns(t)}
        dataSource={data.projectsRefs}
        loading={loading}
        rowKey="id"
      />
    </>
  );
}
