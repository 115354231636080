import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  Popconfirm,
  Typography,
  notification,
  Table,
  Drawer,
  Form,
  Select,
  DatePicker,
  Divider,
  Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import * as firebase from 'firebase';
import moment from 'moment';
import find from 'lodash/find';

import ReferenceLink from '../../components/ReferenceLink';

async function deleteProduct([id], { reference }) {
  await reference
    .collection('userProducts')
    .doc(id)
    .delete();
}

const WHERE = [];
const WHERE_NOT_DELETED = {
  field: 'deletedAt',
  op: '==',
  value: null,
};

const ORDER = ['createdAt', 'desc'];

function AddProduct({ form, t, reference, ownedProducts }) {
  const { getFieldDecorator, resetFields } = form;
  const [search, setSearch] = useState('');
  const firestore = useFirestore();

  const submit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, data) => {
        if (!err) {
          await reference
            .collection('userProducts')
            .doc(data.product)
            .set({
              expiredAt:
                (data.expiredAt && firestore.Timestamp.fromDate(data.expiredAt.toDate())) || null,
              createdAt: firestore.FieldValue.serverTimestamp(),
              modifiedAt: firestore.FieldValue.serverTimestamp(),
              userRef: reference,
              enabled: true,
              productRef: firestore()
                .collection('products')
                .doc(data.product),
            });

          notification.success({ message: t('product-added') });

          resetFields();
        }
      });
    },
    [form, reference, resetFields, t],
  );

  const where = useMemo(() => {
    if (!search) {
      return [WHERE_NOT_DELETED];
    }
    const strSearch = search;
    const strlength = strSearch.length;
    const strFrontCode = strSearch.slice(0, strlength - 1);
    const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
    const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return [
      WHERE_NOT_DELETED,
      {
        field: 'name',
        op: '>=',
        value: strSearch,
      },
      {
        field: 'name',
        op: '<',
        value: endcode,
      },
    ];
  }, [search]);

  const { data: products } = useCollectionWhere('products', null, where);

  return (
    <>
      <Form onSubmit={submit}>
        <Form.Item label={t('product')} required>
          {getFieldDecorator('product', {
            rules: [{ required: true }],
          })(
            <Select
              onSearch={v => setSearch(v)}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
            >
              {products
                .filter(p => !ownedProducts.includes(p.__id))
                .map(p => (
                  <Select.Option key={p.__id}>{p.name}</Select.Option>
                ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={t('expiredAt')}>
          {getFieldDecorator('expiredAt')(<DatePicker format="DD/MM/YYYY" />)}
        </Form.Item>
        <Button htmlType="submit">{t('create')}</Button>
      </Form>
    </>
  );
}

function EditProduct({ form, t, reference, onClose, id, product }) {
  const { getFieldDecorator } = form;

  const submit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, data) => {
        if (!err) {
          try {
            await reference
              .collection('userProducts')
              .doc(id)
              .update({
                expiredAt:
                  (data.expiredAt &&
                    firebase.firestore.Timestamp.fromDate(data.expiredAt.toDate())) ||
                  null,
                enabled: data.enabled,
                modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
              });

            notification.success({ message: t('product-edited') });
            onClose();
          } catch (error) {
            console.log(error);
            notification.error({ message: error.message });
          }
        }
      });
    },
    [form, id, onClose, reference, t],
  );

  return (
    <>
      <p>
        <ReferenceLink
          reference={reference.firestore.collection('products').doc(id)}
          formatter={data => <b>{data.name}</b>}
          to={data => `/app/products/${data.__id}`}
        />
      </p>

      <p>
        {t('createdAt')}
        <br />
        {product && product.createdAt
          ? moment(product.createdAt.toDate()).format('DD/MM/YYYY')
          : t('never')}
      </p>
      <p>
        {t('modifiedAt')}
        <br />
        {product && product.modifiedAt
          ? moment(product.modifiedAt.toDate()).format('DD/MM/YYYY')
          : t('never')}
      </p>

      <Form onSubmit={submit}>
        <Form.Item label={t('expiredAt')}>
          {getFieldDecorator('expiredAt', { initialValue: product.export })(
            <DatePicker format="DD/MM/YYYY" />,
          )}
        </Form.Item>
        <Form.Item label={t('enabled')}>
          {getFieldDecorator('enabled', {
            valuePropName: 'checked',
            initialValue: product.enabled,
          })(<Switch />)}
        </Form.Item>
        <Button htmlType="submit">{t('save')}</Button>
      </Form>
    </>
  );
}

const EditProductForm = Form.create()(EditProduct);
const AddProductForm = Form.create()(AddProduct);

export default function Products({ reference }) {
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(null);
  const { t } = useTranslation('users');
  const { data: products, loading } = useCollectionWhere('userProducts', reference, WHERE, ORDER);

  const { run, isPending } = useAsync({
    deferFn: deleteProduct,
    reference,
    onReject: () => notification.error({ message: t('delete-error') }),
  });

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '2rem' }}>
        {t('products')}
      </Typography.Title>
      <Button onClick={() => setAddDrawer(true)}>{t('add-product')}</Button>
      <Drawer
        title={t('add-product')}
        visible={addDrawer}
        closable
        onClose={() => setAddDrawer(false)}
      >
        <AddProductForm t={t} reference={reference} ownedProducts={products.map(p => p.__id)} />
      </Drawer>
      <Drawer
        title={t('edit-product')}
        visible={!!editDrawer}
        closable
        destroyOnClose
        onClose={() => setEditDrawer(null)}
      >
        {editDrawer && (
          <EditProductForm
            t={t}
            reference={reference}
            id={editDrawer}
            product={find(products, p => p.__id === editDrawer)}
            onClose={() => setEditDrawer(null)}
          />
        )}
      </Drawer>
      <Table
        rowKey="__id"
        columns={[
          {
            title: t('product'),
            render: (_, { __id: id }) => (
              <ReferenceLink
                reference={reference.firestore.collection('products').doc(id)}
                formatter={data => data.name}
                to={data => `/app/products/${data.__id}`}
              />
            ),
          },
          {
            title: t('sku'),
            dataIndex: '__id',
          },
          {
            title: t('enabled'),
            dataIndex: 'enabled',
            render: value => <Icon type={value ? 'check' : 'close'} />,
          },
          {
            title: t('createdAt'),
            dataIndex: 'createdAt',
            render: data => {
              return data ? moment(data.toDate()).format('DD/MM/YYYY') : t('never');
            },
          },
          {
            title: t('modifiedAt'),
            dataIndex: 'modifiedAt',
            render: data => {
              return data ? moment(data.toDate()).format('DD/MM/YYYY H:mm') : t('never');
            },
          },
          {
            title: t('expiredAt'),
            dataIndex: 'expiredAt',
            render: data => {
              return data ? moment(data.toDate()).format('DD/MM/YYYY') : t('never');
            },
          },
          {
            title: t('actions'),
            render: (_, record) => (
              <>
                <Popconfirm
                  title={t('confirm-delete-product')}
                  key="delete"
                  onConfirm={() => run(record.__id)}
                >
                  <Button type="danger" loading={isPending}>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
                <Divider type="vertical" />
                <Button key="edit" loading={isPending} onClick={() => setEditDrawer(record.__id)}>
                  <Icon type="edit" />
                </Button>
              </>
            ),
          },
        ]}
        loading={loading}
        dataSource={products}
      />
    </>
  );
}
