import { useFirebase } from '@betwyll/btw-core-backoffice';
import { useCallback } from 'react';

export default function useUploadProfileRequest({ userId }) {
  const firebase = useFirebase();

  return useCallback(async ({ onSuccess, onError, file }) => {
    const token = await firebase.auth().currentUser.getIdToken(true);

    const data = new FormData();
    data.append('image', file);
    data.append('userId', userId);
    try {
      const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/uploadUserImage`, {
        body: data,
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      const response = await res.json();
      onSuccess({ download: response.filePath });
    } catch (e) {
      onError(e);
    }
  });
}
