import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Icon, PageHeader, Row, Table, Menu } from 'antd';
import { useCollectionWhere, useFirestore, ReferenceLink } from '@betwyll/btw-core-backoffice';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import FirebaseImage from '../../components/FirebaseImage';
import Orderable from '../../components/Orderable';
import FilterComposer from '../../components/FilterComposer';
import { FILTERS } from '../../constants';
import useQuerystring from '../../hooks/useQuerystring';

const columns = t => [
  {
    title: t('status'),
    dataIndex: 'status',
    render: status => (
      <>
        {status === 'draft' && (
          <span>
            <Icon type="cloud" theme="twoTone" twoToneColor="blue" /> {t('draft')}
          </span>
        )}
        {status === 'published' && (
          <span>
            <Icon type="cloud" theme="twoTone" twoToneColor="green" /> {t('published')}
          </span>
        )}
        {status === 'archived' && (
          <span>
            <Icon type="cloud" theme="twoTone" twoToneColor="yellow" /> {t('archived')}
          </span>
        )}
        {status === 'deleted' && (
          <span>
            <Icon type="cloud" theme="twoTone" twoToneColor="red" /> {t('deleted')}
          </span>
        )}
      </>
    ),
  },
  {
    title: t('cover'),
    dataIndex: 'coverUrl',
    render: text => (
      <FirebaseImage alt="project cover" src={text} style={{ maxWidth: 150, maxHeight: 100 }} />
    ),
  },
  {
    title: t('name'),
    dataIndex: 'title',
    render: text => <span>{text}</span>,
  },
  {
    title: t('type'),
    dataIndex: 'visibility',
    render: text => <span>{t(`visibility:${text}`)}</span>,
  },
  {
    title: t('following'),
    dataIndex: 'following',
    render: (_, record) => {
      const ref = record.__ref.collection('projectStats').doc('followers');
      return (
        <ReferenceLink
          to={({ __id }) => `/app/users/?following=${record.__id}`}
          reference={ref}
          formatter={({ count }) => count}
        />
      );
    },
  },
  {
    title: t('twylls'),
    dataIndex: 'twylls',
    render: (_, record) => {
      const ref = record.__ref.collection('projectStats').doc('twylls');
      return (
        <ReferenceLink to={({ __id }) => '#'} reference={ref} formatter={({ count }) => count} />
      );
    },
  },
  {
    title: t('owner'),
    dataIndex: 'ownerRef',
    render: ref => (
      <ReferenceLink
        to={({ __id }) => `/users/${__id}/`}
        reference={ref}
        formatter={({ nickname }) => `@${nickname}`}
      />
    ),
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
        <Icon type="eye" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        <Icon type="inbox" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
      </span>
    ),
  },
];

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
// };

const FIELDS = t => [
  { value: 'modifiedAt', label: t('modifiedAt') },
  { value: 'createdAt', label: t('createdAt') },
];

const CONDITIONS = t => [
  {
    type: FILTERS.TYPES.DYNAMIC,
    field: {
      value: 'ownerRef',
      label: t('owner'),
    },
    op: [FILTERS.OPS.EQUALS],
    value: {
      collection: 'users',
      field: 'nickname',
    },
  },
  {
    field: {
      value: 'status',
      label: t('status'),
    },
    op: [FILTERS.OPS.EQUALS, FILTERS.OPS.IN],
    value: [
      { value: 'draft', label: t('draft') },
      { value: 'published', label: t('published') },
      { value: 'archived', label: t('archived') },
    ],
  },
];

const STYLE = { marginBottom: '1.5rem' };
const DEFAULT_FILTER = 'listed';

export default function ProjectsList() {
  const firestore = useFirestore();
  const { visibility = DEFAULT_FILTER, owner = null } = useQuerystring();
  const history = useHistory();
  const location = useLocation();

  const [order, setOrder] = useState({
    field: 'modifiedAt',
    direction: 'desc',
  });

  const orderMemoized = useMemo(() => [order.field, order.direction], [
    order.field,
    order.direction,
  ]);

  const [query, setQuery] = useState([]);

  useEffect(() => {
    if (owner) {
      setQuery([
        {
          field: 'ownerRef',
          op: '==',
          value: firestore()
            .collection('users')
            .doc(owner),
        },
      ]);
    }
  }, []);

  const queryMemoized = useMemo(
    () => [
      {
        field: 'deletedAt',
        op: '==',
        value: null,
      },
      ...query,
      {
        field: 'visibility',
        op: '==',
        value: visibility,
      },
    ],
    [query, visibility, owner],
  );

  const { data, loading } = useCollectionWhere('projects', null, queryMemoized, orderMemoized);

  const { t } = useTranslation(['projects', 'home', 'visibility']);

  const onChangeTab = e => {
    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ visibility: e.key }),
    });
  };

  return (
    <>
      <PageHeader title={t(`home:${visibility === 'template' ? 'templates' : 'projects'}`)} />
      <div style={STYLE}>
        {visibility !== 'template' && (
          <Link to="create/project/">
            <Button>{t('add_new_project')}</Button>
          </Link>
        )}
        {visibility === 'template' && (
          <Link to="create/template/">
            <Button>{t('add_new_template')}</Button>
          </Link>
        )}
        {/* <Col span={4}> */}
        {/*  <Row> */}
        {/*    <Col offset={16} span={8}> */}
        {/*      <Button> */}
        {/*        <Icon type="download" title="Export CSV" /> */}
        {/*      </Button> */}
        {/*    </Col> */}
        {/*  </Row> */}
        {/* </Col> */}
      </div>
      <Row type="flex" justify="space-between" style={STYLE}>
        <Col span={16}>
          <FilterComposer conditions={CONDITIONS(t)} onChange={setQuery} />
        </Col>
        <Col span={8}>
          <Orderable
            defaultDirection={order.direction}
            defaultField={order.field}
            fields={FIELDS(t)}
            onChange={setOrder}
          />
        </Col>
      </Row>
      {visibility !== 'template' && (
        <Menu mode="horizontal" onClick={onChangeTab} selectedKeys={[visibility]}>
          <Menu.Item key="listed">{t('visibility:listed')}</Menu.Item>
          <Menu.Item key="unlisted">{t('visibility:unlisted')}</Menu.Item>
          <Menu.Item key="private">{t('visibility:private')}</Menu.Item>
        </Menu>
      )}
      <Table columns={columns(t)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}

ProjectsList.whyDidYouRender = true;
