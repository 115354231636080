import { Switch, Route } from 'react-router-dom';
import React from 'react';

import ModerationDashboard from './Dashboard';
import Twylls from './Twylls';

export default function Moderation() {
  return (
    <Switch>
      <Route path="/app/moderation/twylls/" component={Twylls} />
      <Route path="" component={ModerationDashboard} />
    </Switch>
  );
}
