import { useFirestore } from '@betwyll/btw-core-backoffice';
import * as firebase from 'firebase';
import moment from 'moment';

function render(d) {
  if (d instanceof firebase.firestore.Timestamp) {
    return moment(d.toDate()).format('DD/MM/YYYY');
  }

  if (d instanceof firebase.firestore.DocumentReference) {
    return d.path;
  }

  if (d === null) {
    return '';
  }

  if (typeof d === 'undefined') {
    return '';
  }

  return d;
}

export default function useCsvExportBatch(batchId) {
  const firestore = useFirestore();

  return async () => {
    const docs = await firestore()
      .collection('promoCodes')
      .where(
        'batchRef',
        '==',
        firestore()
          .collection('promoCodeBatches')
          .doc(batchId),
      )
      .get();

    const lines = [];
    let keys = null;
    docs.forEach(snap => {
      if (!keys) {
        keys = Object.keys(snap.data());
        lines.push(['id', ...keys].join(';'));
      }
      const data = snap.data();
      lines.push([snap.ref.id, ...keys.map(k => render(data[k]))].join(';'));
    });

    const file = new Blob([lines.join('\n')], { type: 'text/csv' });
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = `export-batch-${batchId}.csv`;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
}
