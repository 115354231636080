import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GroupsList from './List';
import EditGroup from './Edit';

export default function Groups() {
  return (
    <Switch>
      <Route path="/app/groups/:groupId/" component={EditGroup} />
      <Route path="/app/groups/" component={GroupsList} />
    </Switch>
  );
}
