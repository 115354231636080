import React, { useEffect, useState } from 'react';
import { useFirebase } from '@betwyll/btw-core-backoffice';
import { Spin } from 'antd';

export default function FirebaseImage({ src, ...props }) {
  const [ready, setSrc] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    async function handleLoading() {
      try {
        const storage = firebase.storage();
        const gsReference = storage.refFromURL(src);
        const url = await gsReference.getDownloadURL();
        setSrc(url);
      } catch (e) {
        console.log(e);
        setSrc(null);
      }
    }

    setSrc(false);
    if (src && src.startsWith('http')) {
      setSrc(src);
    } else if (src && src.startsWith('gs')) {
      handleLoading();
    } else {
      setSrc(null);
    }
  }, [firebase, src]);

  if (ready === null) {
    return null;
  }

  if (!ready) {
    return <Spin />;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} src={ready} />;
}
