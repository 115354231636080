import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { FirebaseProvider, AuthedRoute, SpinnerPage } from '@betwyll/btw-core-backoffice';
import config from './firebase-config';

import LoginPage from './pages/Login';
import HomeWrapper from './pages/HomeWrapper';

function App() {
  return (
    <FirebaseProvider config={config}>
      <BrowserRouter>
        <Suspense fallback={<SpinnerPage />}>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <AuthedRoute path="/" name="Home" component={HomeWrapper} />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </FirebaseProvider>
  );
}

export default App;
