import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Input, InputNumber, Modal, notification, PageHeader, Table } from 'antd';
import { useBack, useCollectionWhere, useFirestore, useToggle } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import EditableField from '../../components/EditableField';
import RTEditorInput from '../../components/RTEditorInput';
import Img from '../Blocks/Img';
import ReferenceChoice from '../../components/ReferenceChoice';
import { getLabel } from '../../lib/utils';
import ReferenceLink from '../../components/ReferenceLink';

const ORDER = ['index', 'asc'];
const WHERE = [];
const ORDER_TEMPLATE = ['createdAt', 'asc'];
const ORDER_PILLS = ORDER;

async function add([data], { firestore, collection }) {
  await firestore()
    .collection(collection)
    .add(data);
}

async function update([ref, data, onSuccess]) {
  await ref.update(data);
  if (onSuccess) {
    onSuccess();
  }
}

async function remove([ref]) {
  await ref.delete();
}

function Twylls({ blockId, list, collection, materialRef, contentRef, didacticSetRef }) {
  const [state, toggle] = useToggle();
  const [create, setCreate] = useState(null);
  const [key, setKey] = useState(0);
  const [seletedCategory, setCategory] = useState(null);

  const { t, i18n } = useTranslation('materials');
  const data = useMemo(() => list.filter(l => l.blockRef.id === blockId), [list, blockId]);
  const firestore = useFirestore();

  const { run } = useAsync({
    deferFn: add,
    firestore,
    collection,
    onResolve: () => {
      setKey(key + 1);
    },
  });

  const { run: updateEl, isPending: updating } = useAsync({
    deferFn: update,
    onReject: error => {
      console.log(error);
      notification.error({ message: error.message });
    },
  });

  const { run: removeEl, isPending: removing } = useAsync({
    deferFn: remove,
    onReject: error => {
      console.log(error);
      notification.error({ message: error.message });
    },
  });

  const addToCollection = useCallback(() => {
    const payload = {
      body: create,
      materialRef,
      contentRef,
      didacticSetRef,
      blockRef: contentRef.collection('blocks').doc(blockId),
    };

    if (collection === 'twyllTemplates') {
      payload.createdAt = firestore.Timestamp.now();
    } else {
      payload.index = data.length === 0 ? 1 : Math.max(...data.map(d => d.index)) + 1;
      payload.didacticCategoryRef = seletedCategory;
    }

    run(payload);
    setCreate(null);
  }, [
    create,
    materialRef,
    contentRef,
    didacticSetRef,
    blockId,
    collection,
    run,
    firestore.Timestamp,
    data,
    seletedCategory,
  ]);

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <Button onClick={toggle}>{data.length}</Button>
      <Modal
        closable
        visible={state}
        onCancel={toggle}
        title={t(collection)}
        footer={null}
        width={700}
      >
        {data.map(pill => (
          <Card
            key={pill.__id}
            style={{ margin: '.5rem' }}
            bodyStyle={{ padding: '0 .5rem .5rem .5rem' }}
            actions={[
              <Button key="delete" loading={removing} onClick={() => removeEl(pill.__ref)}>
                {t('delete')}
              </Button>,
            ]}
          >
            <EditableField
              data={pill}
              InputComponent={
                collection === 'didacticPills' ? (
                  <RTEditorInput />
                ) : (
                  <Input.TextArea rows={3} maxLength={140} autoSize />
                )
              }
              render={__html => <div dangerouslySetInnerHTML={{ __html }} />}
              label={t('content')}
              field="body"
              updating={updating}
              update={(d, _, onSuccess) => updateEl(pill.__ref, d, onSuccess)}
              inputSpan={21}
              layout={{
                labelCol: { span: 0 },
                wrapperCol: { span: 24 },
              }}
            />
            {collection === 'didacticPills' && (
              <>
                <EditableField
                  data={pill}
                  InputComponent={<InputNumber placeholder="index" />}
                  label={t('index')}
                  field="index"
                  updating={updating}
                  update={(d, _, onSuccess) => updateEl(pill.__ref, d, onSuccess)}
                  inputSpan={21}
                  align="center"
                  layout={{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 18 },
                  }}
                />
                <EditableField
                  data={pill}
                  label={t('didacticCategory')}
                  field="didacticCategoryRef"
                  decoratorExtra={{ rules: [{ required: true }] }}
                  updating={updating}
                  update={(d, _, onSuccess) => updateEl(pill.__ref, d, onSuccess)}
                  layout={{
                    labelCol: { span: 6 },
                    wrapperCol: { span: 18 },
                  }}
                  inputSpan={21}
                  InputComponent={
                    <ReferenceChoice
                      collection="didacticCategories"
                      order={ORDER}
                      render={m => getLabel(m.labelL10n, activeLang)}
                    />
                  }
                  render={ref => {
                    if (!ref) {
                      return null;
                    }
                    return (
                      <ReferenceLink
                        to={({ __id }) => `/app/didacticCategories/${__id}/`}
                        reference={ref}
                        formatter={({ labelL10n }) => getLabel(labelL10n, activeLang)}
                      />
                    );
                  }}
                />
              </>
            )}
          </Card>
        ))}
        <Card
          style={{ margin: '.5rem', marginTop: '1rem' }}
          className="reduce-editor"
          bodyStyle={{ padding: 0 }}
          actions={[
            collection === 'didacticPills' && (
              <ReferenceChoice
                collection="didacticCategories"
                style={{ minWidth: '10rem' }}
                render={cat => getLabel(cat.labelL10n, activeLang)}
                value={seletedCategory}
                onChange={setCategory}
              />
            ),
            <Button key="add" onClick={addToCollection}>
              Add
            </Button>,
          ].filter(_ => _)}
        >
          {collection === 'didacticPills' ? (
            <RTEditorInput onChange={v => setCreate(v)} key={key} />
          ) : (
            <Input.TextArea
              onChange={e => setCreate(e.target.value)}
              value={create}
              maxLength={140}
              rows={3}
              autoSize
            />
          )}
        </Card>
      </Modal>
    </>
  );
}

export default function Blocks() {
  const { t } = useTranslation('materials');
  const { didacticSetId, contentId, materialId } = useParams();
  const firestore = useFirestore();
  const { basePath, contentRef, materialRef, didacticSetRef } = useMemo(() => {
    const fs = firestore();
    const material = fs.collection('materials').doc(materialId);

    const content = material.collection('contents').doc(contentId);

    return {
      materialRef: material,
      contentRef: content,
      didacticSetRef: fs.collection('didacticSets').doc(didacticSetId),
      basePath: content,
    };
  }, [firestore, materialId, contentId, didacticSetId]);

  const where = useMemo(() => {
    return [
      {
        field: 'contentRef',
        value: basePath,
        op: '==',
      },
      {
        field: 'didacticSetRef',
        value: didacticSetRef,
        op: '==',
      },
    ];
  }, [basePath, didacticSetRef]);

  const { data, loading } = useCollectionWhere('blocks', basePath, WHERE, ORDER);
  const { data: pills } = useCollectionWhere('didacticPills', null, where, ORDER_PILLS);
  const { data: templates } = useCollectionWhere('twyllTemplates', null, where, ORDER_TEMPLATE);

  const goBack = useBack(`/app/didacticSet/${didacticSetId}/`);

  return (
    <>
      <PageHeader title={t('blocks')} onBack={goBack} />
      <Table
        dataSource={data}
        loading={loading}
        rowKey="__id"
        pagination={false}
        columns={[
          {
            title: t('twyllTemplates'),
            render: record => (
              <Twylls
                firestore={firestore}
                blockId={record.__id}
                list={templates}
                collection="twyllTemplates"
                materialRef={materialRef}
                contentRef={contentRef}
                didacticSetRef={didacticSetRef}
              />
            ),
          },
          {
            title: t('didacticPills'),
            render: record => (
              <Twylls
                firestore={firestore}
                blockId={record.__id}
                list={pills}
                collection="didacticPills"
                materialRef={materialRef}
                contentRef={contentRef}
                didacticSetRef={didacticSetRef}
              />
            ),
          },
          {
            title: t('block'),
            render: record => {
              if (record.type === 'image') {
                return <Img {...record} />;
              }

              return <div dangerouslySetInnerHTML={{ __html: record.text }} />;
            },
          },
        ]}
      />
    </>
  );
}
