import { Link } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import ReferenceQueryRender from '../../components/ReferenceQueryRender';

const columns = (t, reference) => [
  {
    title: t('name'),
    dataIndex: 'title',
    render: text => <span>{text}</span>,
  },
  {
    title: t('reported'),
    render: (_, record) => (
      <ReferenceQueryRender
        reference={reference}
        query={{ field: 'projectRef', op: '==', value: record.__ref }}
        render={data => data.length}
      />
    ),
  },
  {
    title: t('need-check'),
    render: (_, record) => (
      <ReferenceQueryRender
        reference={reference}
        query={{ field: 'projectRef', op: '==', value: record.__ref }}
        render={data => data.filter(report => !report.reviewOutcome).length}
      />
    ),
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`twylls/?project=${record.__id}`}>
          <Button>{t('go-to-list')}</Button>
        </Link>
      </span>
    ),
  },
];

const ORDER = ['modifiedAt', 'desc'];

export default function ProjectsList({ visibility = 'listed' }) {
  const WHERE = useMemo(
    () => [
      {
        field: 'deletedAt',
        op: '==',
        value: null,
      },
      {
        field: 'visibility',
        op: Array.isArray(visibility) ? 'in' : '==',
        value: visibility,
      },
    ],
    [visibility],
  );
  const { data, loading } = useCollectionWhere('projects', null, WHERE, ORDER);

  const firestore = useFirestore();
  const reportingsPath = firestore().collection('twyllReportings');
  const { t } = useTranslation(['moderation']);

  return (
    <>
      <Table
        columns={columns(t, reportingsPath)}
        dataSource={data}
        loading={loading}
        rowKey="__id"
      />
    </>
  );
}
