import { Button, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';

const columns = t => [
  {
    title: 'Group',
    dataIndex: 'title',
  },
  {
    title: 'actions',
    dataIndex: '__id',
    render: id => (
      <Link to={`/app/groups/${id}/`}>
        <Button>{t('view')}</Button>
      </Link>
    ),
  },
];

function GroupWidget({ data, loading, title, t }) {
  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '1rem' }}>
        {title}
      </Typography.Title>
      <Table loading={loading} dataSource={data} rowKey="__id" columns={columns(t)} />
    </>
  );
}

export function GroupsOwned({ reference }) {
  const { t } = useTranslation('users');
  const where = useMemo(() => {
    return [
      {
        field: 'ownerRef',
        op: '==',
        value: reference,
      },
    ];
  }, [reference]);
  const { data, loading } = useCollectionWhere('groups', null, where);

  return <GroupWidget loading={loading} data={data} title={t('groups-owned')} t={t} />;
}

export function GroupsIn({ reference }) {
  const { t } = useTranslation('users');
  const where = useMemo(() => {
    return [
      {
        field: 'usersRefs',
        op: 'array-contains',
        value: reference,
      },
    ];
  }, [reference]);
  const { data, loading } = useCollectionWhere('groups', null, where);

  return <GroupWidget loading={loading} data={data} title={t('groups-in')} t={t} />;
}
