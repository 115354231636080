import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SpinnerPage, ClientHomePage } from '@betwyll/btw-core-backoffice';

import AdminLayout from '../sections/admin/Layout';
import PermissionsContext from '../contexts/Permissions';
import { PERMISSIONS } from '../permissions';

export default function Home() {
  const permissions = useContext(PermissionsContext);
  if (permissions === null) {
    return <SpinnerPage />;
  }

  const canAccess = PERMISSIONS.ALLOWED_TO_LOGIN.some(p => permissions[p]);

  return (
    <Switch>
      <Route
        path="/app"
        render={() => {
          if (!canAccess) {
            return <ClientHomePage />;
          }
          return <AdminLayout />;
        }}
      />
      <Route exact path="/" render={() => <Redirect to="/app" />} />
      <Route path="" render={() => <Redirect to="/" />} />
    </Switch>
  );
}
