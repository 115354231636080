import { Button, Modal, notification, PageHeader, Popconfirm, Spin, Tag } from 'antd';
import {
  ChoiceSelect,
  SpinnerBlock,
  useBack,
  useDocument,
  useFirestore,
  ExportMaterial,
  useFunction,
  useGetDefaultLanguage,
} from '@betwyll/btw-core-backoffice';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import RTEditorInput from '../../components/RTEditorInput';
import { normalizeUpload } from '../../lib/utils';
import EditableField from '../../components/EditableField';
import ContentsList from '../Contents/List';
import FirebaseImage from '../../components/FirebaseImage';
import { LANG } from '../../constants';
import useUploadMaterialRequest from '../../hooks/useUploadMaterialRequest';
import ImgCropUploadInput from '../../components/ImgCropUploadInput';
import { usePermissions } from '../../contexts/Permissions';
import { ROLES } from '../../permissions';

async function updateMaterial([data, _, onSuccess], { basePath, firestore }) {
  console.log(data);
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

async function updateMaterialName(
  [data, _, onSuccess],
  { basePath, firestore, checkMaterialName, original, t },
) {
  const { name } = data;
  let materialName = name;

  console.log('here', original, materialName, name, data);

  async function runUpdate() {
    try {
      await basePath.update({ name: materialName, modifiedAt: firestore.Timestamp.now() });
      if (onSuccess) onSuccess();
      notification.success({
        message: t('success'),
      });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }

  if (original !== materialName) {
    try {
      const { result } = await checkMaterialName({ inputName: name });
      materialName = result.materialName;
    } catch (e) {
      notification.error({ message: e });
      return;
    }
  }
  if (materialName !== name) {
    Modal.confirm({
      content: t('confirm-name-change', { oldName: name, newName: materialName }),
      onOk: runUpdate,
    });
  } else {
    await runUpdate();
  }
}

export default function EditMaterial() {
  const { materialId } = useParams();
  const { t } = useTranslation('materials');
  const firestore = useFirestore();
  const permissions = usePermissions();

  const { data: material, loading: loadingMaterial } = useDocument(materialId, 'materials');
  const goBack = useBack('../');
  const basePath = firestore()
    .collection('materials')
    .doc(materialId);

  const { run, isPending: updating } = useAsync({
    deferFn: updateMaterial,
    basePath,
    firestore,
    onResolve: () => {
      notification.success({
        message: t('success'),
      });
    },
  });

  const checkMaterialName = useFunction({ name: 'materialCheckOrGenerateNameCall' });

  const { run: updateName } = useAsync({
    deferFn: updateMaterialName,
    basePath,
    firestore,
    original: material.name,
    checkMaterialName,
    t,
  });

  const [deleting, setDeleting] = useState(false);
  const onStartDeleting = useCallback(() => setDeleting(true), []);

  const archive = useFunction({
    name: 'materialArchive',
    preBody: { materialDocumentId: materialId },
    onSuccess: () => {
      goBack();
      notification.success({ message: t('archive_notification') });
    },
    onError: e => notification.error({ message: e.message }),
    onStart: onStartDeleting,
  });

  const remove = useFunction({
    name: 'materialDeleteRequest',
    preBody: { materialDocumentId: materialId },
    onSuccess: goBack,
    onStart: onStartDeleting,
  });

  const defaultLang = useGetDefaultLanguage(LANG);

  const customRequest = useUploadMaterialRequest({ materialId });

  return (
    <>
      <PageHeader
        title={
          <>
            {t('material')} {loadingMaterial ? <Spin /> : material.name}
          </>
        }
        tags={
          material && [
            material.archivedAt && (
              <Tag color="blue" key="archived">
                {t('archived')}
              </Tag>
            ),
          ]
        }
        onBack={goBack}
        extra={[
          !material.publishedAt && (
            <Button
              key="publish"
              onClick={() => {
                run({
                  publishedAt: material.publishedAt ? null : firestore.Timestamp.now(),
                });
              }}
            >
              {material.publishedAt ? t('draft') : t('publish')}
            </Button>
          ),
          <Popconfirm
            key="archive"
            placement="left"
            title={t('confirm_archive')}
            onConfirm={archive}
          >
            <Button loading={deleting}>{t('archive')}</Button>
          </Popconfirm>,
          permissions[ROLES.ADMIN] && (
            <Popconfirm
              key="delete"
              placement="left"
              title={t('confirm_delete')}
              onConfirm={remove}
            >
              <Button loading={deleting} type="danger">
                {t('delete')}
              </Button>
            </Popconfirm>
          ),
          <ExportMaterial id={materialId} path={basePath} />,
        ].filter(_ => _)}
      />
      {loadingMaterial && <SpinnerBlock />}
      {material && (
        <>
          <EditableField
            data={material}
            label={t('name')}
            field="name"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={updateName}
            updating={updating}
            readonly={!permissions[ROLES.ADMIN]}
          />
          <EditableField
            data={material}
            label={t('title')}
            field="title"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={material}
            label={t('abstract')}
            field="description"
            update={run}
            updating={updating}
            InputComponent={<RTEditorInput />}
            render={__html => <div dangerouslySetInnerHTML={{ __html }} />}
          />
          <EditableField
            data={material}
            label={t('cover')}
            field="coverUrl"
            update={run}
            updating={updating}
            decoratorExtra={{
              valuePropName: 'fileList',
              initialValue: null,
              rules: [{ required: true }],
              getValueFromEvent: normalizeUpload,
            }}
            transform={data => ({ coverUrl: data.coverUrl[0].response.download })}
            InputComponent={
              <ImgCropUploadInput
                aspect={440 / 640}
                customRequest={customRequest}
                text={t('cover_upload_text')}
              />
            }
            render={url => (
              <FirebaseImage src={url} alt="cover" style={{ maxWidth: 250, maxHeight: 200 }} />
            )}
          />
          <EditableField
            data={material}
            label={t('author')}
            field="author"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={material}
            label={t('lang')}
            field="languageCode"
            initial={defaultLang}
            decoratorExtra={{
              rules: [{ required: true }],
            }}
            update={run}
            updating={updating}
            render={v => LANG[v]}
            InputComponent={<ChoiceSelect choices={LANG} showSearch optionFilterProp="children" />}
          />
        </>
      )}
      <ContentsList />
    </>
  );
}
