import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Button, notification, PageHeader, Spin, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import {
  SpinnerBlock,
  useBack,
  useCollectionWhere,
  useDocument,
  useFirestore,
} from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import BlockView from './BlockView';
// import HTMLInsertForm from './HTMLInsertForm';
import BlockSwitch from './BlockSwitch';
import { LastFocusContext, LastFocusProvider } from './LastFocusProvider';

async function getBlocks({ basePath, setItems }) {
  const qs = await basePath.orderBy('index').get();
  const blocks = [];
  qs.forEach(d =>
    blocks.push({
      ...d.data(),
      __id: d.id,
    }),
  );
  setItems(blocks);
}

async function batchUpdateBlocks(_, { firestore, basePath, blocks, onSuccess, deleteItems, t }) {
  try {
    const batch = firestore().batch();
    blocks.forEach((block, index) => {
      console.log(block);
      batch.set(basePath.doc(block.__id), { ...omit(block, '__id'), index });
    });
    deleteItems.forEach(id => {
      batch.delete(basePath.doc(id));
    });
    await batch.commit();

    onSuccess();
  } catch (e) {
    console.log(e);
    notification.error({ message: t('changes_not_saved') });
  }
}

/* async function processHTML([data], { items, setItems, onSuccess, basePath }) {
  try {
    const { html } = data;

    const doc = new DOMParser().parseFromString(html, 'text/html');
    const converted = Array.from(doc.body.children).map(node => ({
      text: node.outerHTML,
      type: 'paragraph',
      __id: basePath.doc().id,
    }));
    setItems(items.concat(converted));
    onSuccess();
  } catch (e) {
    console.log(e);
  }
  return Promise.resolve();
} */

async function mergeBlock(
  [index, directionAbove = true],
  { items, setItems, addToDelete, cleanTwylls, setChanged },
) {
  try {
    const result = Array.from(items);
    cleanTwylls.current.push(result[index].__id);
    if (directionAbove) {
      result[index].text = `${result[index - 1].text}${result[index].text}`;
      addToDelete(result[index - 1].__id);
      cleanTwylls.current.push(result[index - 1].__id);
      result.splice(index - 1, 1);
    } else {
      result[index].text += result[index + 1].text;
      addToDelete(result[index + 1].__id);
      cleanTwylls.current.push(result[index + 1].__id);
      result.splice(index + 1, 1);
    }
    setChanged(true);
    setItems(result);
  } catch (e) {
    console.log(e);
  }
  return Promise.resolve();
}

async function splitBlock(
  [index, oldText, newText],
  { items, setItems, basePath, cleanTwylls, setChanged },
) {
  const result = Array.from(items);
  const toAdd = {
    text: newText,
    type: 'paragraph',
    __id: basePath.doc().id,
    twyllable: true,
  };
  cleanTwylls.current.push(result[index].__id);
  result[index].text = oldText;
  result.splice(index + 1, 0, toAdd);
  setItems(result);
  setChanged(true);
  return Promise.resolve();
}

function addImageBefore(items, setItems, basePath, direction = 'up') {
  return index => {
    const result = Array.from(items);
    const toAdd = {
      text: null,
      text2: null,
      uri: null,
      type: 'image',
      __id: basePath.doc().id,
      twyllable: true,
    };
    result.splice(direction === 'up' ? index - 1 : index + 1, 0, toAdd);
    setItems(result);
  };
}

async function deleteBlock([index], { items, setItems, addToDelete, cleanTwylls, setChanged }) {
  const result = Array.from(items);
  addToDelete(result[index].__id);
  cleanTwylls.current.push(result[index].__id);
  result.splice(index, 1);
  setItems(result);
  setChanged(true);
  return Promise.resolve();
}

function toggleTwyllableBlock([index], { items, setItems, cleanTwylls, setChanged }) {
  const result = Array.from(items);
  result[index].twyllable = !result[index].twyllable;
  cleanTwylls.current.push(result[index].__id);
  setItems(result);
  setChanged(true);
}

function Blocks() {
  const firestore = useFirestore();
  const [lastFocus] = useContext(LastFocusContext);
  const { materialId, contentId } = useParams();
  // const [visible, { open, close }] = useModal();
  const [toDelete, setToDelete] = useState([]);
  const addToDelete = useCallback(id => setToDelete([...toDelete, id]), [toDelete]);

  const [editBlock, setEditBlock] = useState(false);

  const basePath = useMemo(
    () =>
      firestore()
        .collection('materials')
        .doc(materialId)
        .collection('contents'),
    [firestore, materialId],
  );

  const blocksPath = useMemo(() => basePath.doc(contentId).collection('blocks'), [
    basePath,
    contentId,
  ]);

  const { data: content, loading: loadingContent } = useDocument(contentId, null, basePath);
  const [items, setItems] = useState([]);
  const cleanTwylls = useRef([]);
  const [hasChangedBlocks, setChanged] = useState(false);

  const { t } = useTranslation(['blocks', 'common']);

  const WHERE = useMemo(
    () => [
      {
        field: 'contentRef',
        op: '==',
        value: basePath.doc(contentId),
      },
      {
        field: 'deletedAt',
        op: '==',
        value: null,
      },
    ],
    [basePath, contentId],
  );

  const { data: twylls } = useCollectionWhere('twylls', null, WHERE);

  console.log(twylls);

  const contentHasTwylls = twylls.length > 0;

  const toggleEditBlock = () => {
    const scrollToFocus = () =>
      setTimeout(() => {
        const el = document.getElementById(lastFocus);
        if (el) {
          el.scrollIntoView();
        }
      }, 1000);
    if (!editBlock && contentHasTwylls) {
      Modal.confirm({
        title: t('content-has-twylls-title'),
        content: t('content-has-twyll-content'),
        onOk() {
          setEditBlock(!editBlock);
          scrollToFocus();
        },
      });
    } else {
      setEditBlock(!editBlock);
      scrollToFocus();
    }
  };

  const updateItem = useCallback(
    (item, cb) => {
      console.log(item);
      setItems(
        items.map(i => {
          if (i.__id === item.__id) {
            return { ...i, ...item };
          }
          return i;
        }),
      );
      if (cb) {
        cb();
      }
    },
    [items, setItems],
  );

  const addImage = addImageBefore(items, setItems, blocksPath, 'up');
  const addImageAfter = addImageBefore(items, setItems, blocksPath, 'down');

  const { isPending: loadingBlocks, reload } = useAsync({
    promiseFn: getBlocks,
    basePath: blocksPath,
    setItems,
  });

  const { run, isPending: updating } = useAsync({
    deferFn: batchUpdateBlocks,
    blocks: items,
    basePath: blocksPath,
    firestore,
    onSuccess: reload,
    deleteItems: toDelete,
    t,
  });

  // const { run: processHTMLImport, isPending: loadingHTML } = useAsync({
  //   deferFn: processHTML,
  //   setItems,
  //   onSuccess: close,
  //   basePath: blocksPath,
  //   items,
  // });

  const runAddBlock = useCallback(
    (...args) =>
      mergeBlock(args, {
        setItems,
        addToDelete,
        items,
        cleanTwylls,
        setChanged,
      }),
    [items, setItems, addToDelete, cleanTwylls],
  );

  const runDeleteBlock = useCallback(
    (...args) =>
      deleteBlock(args, {
        setItems,
        items,
        addToDelete,
        cleanTwylls,
        setChanged,
      }),
    [items, setItems, addToDelete, cleanTwylls],
  );

  const runSplitBlock = useCallback(
    (...args) =>
      splitBlock(args, {
        setItems,
        items,
        basePath,
        cleanTwylls,
        setChanged,
      }),
    [items, setItems, basePath, cleanTwylls],
  );

  const runToggleTwyllable = useCallback(
    (...args) =>
      toggleTwyllableBlock(args, {
        setItems,
        items,
        cleanTwylls,
        setChanged,
      }),
    [items, setItems],
  );

  const goBack = useBack(`/app/materials/${materialId}/`);

  return (
    <>
      <PageHeader
        title={loadingContent ? <Spin /> : content.title}
        onBack={goBack}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={
              hasChangedBlocks
                ? () =>
                    Modal.confirm({
                      title: t('confirm-save-title'),
                      content: t('confirm-save-content'),
                      onOk: run,
                    })
                : run
            }
            loading={updating}
          >
            {t('common:save')}
          </Button>,
          <Button key="2" onClick={toggleEditBlock} id="switch">
            {editBlock ? t('edit_content') : t('edit_blocks')}
          </Button>,
        ]}
      />
      {(loadingBlocks || updating) && <SpinnerBlock />}
      {!(loadingBlocks || updating) && editBlock && (
        <BlockView
          blocks={items}
          setBlocks={setItems}
          addBlock={runAddBlock}
          splitBlock={runSplitBlock}
          toggleTwyllable={runToggleTwyllable}
        />
      )}
      {!(loadingBlocks || updating) && !editBlock && (
        <>
          {items.map((item, index) => (
            <BlockSwitch
              key={item.__id}
              {...item}
              update={updateItem}
              remove={runDeleteBlock}
              addImage={addImage}
              addImageAfter={addImageAfter}
              index={index}
            />
          ))}
        </>
      )}
    </>
  );
}

export default function BlockWrapper() {
  return (
    <LastFocusProvider>
      <Blocks />
    </LastFocusProvider>
  );
}
