import { Button, Icon, notification, PageHeader, Popconfirm, Spin, Tooltip } from 'antd';
import {
  dateFormatter,
  ReferenceLink,
  SpinnerBlock,
  useBack,
  useDocument,
  useFirestore,
  EditableField,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { PromoCodeTable } from '../PromoCodes/ListPaginated';
import useCsvExportBatch from './useCSVExport';

async function updatePromoCodes([data, _, onSuccess], { basePath, firestore }) {
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function EditPromoCodes() {
  const { promoCodeBatchId } = useParams();
  const { t } = useTranslation('promoCodeBatches');
  const firestore = useFirestore();
  const { data: promoCodeBatchData, loading: loadingPromoCodes } = useDocument(
    promoCodeBatchId,
    'promoCodeBatches',
  );

  const promoCodeBatch =
    promoCodeBatchData && !Array.isArray(promoCodeBatchData) ? promoCodeBatchData : null;

  console.log(promoCodeBatch);

  const goBack = useBack('../');
  const basePath = useMemo(
    () =>
      firestore()
        .collection('promoCodeBatches')
        .doc(promoCodeBatchId),
    [promoCodeBatchId, firestore],
  );

  const collectionReference = useMemo(() => {
    return firestore()
      .collection('promoCodes')
      .where('batchRef', '==', basePath);
  }, [basePath, firestore]);

  const { run, isPending: updating } = useAsync({
    deferFn: updatePromoCodes,
    basePath,
    firestore,
  });

  const runCSVExport = useCsvExportBatch(promoCodeBatchId);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('batch')} {loadingPromoCodes && !promoCodeBatch ? <Spin /> : promoCodeBatch.__id}
          </>
        }
        onBack={goBack}
        extra={[
          <Button onClick={runCSVExport}>{t('export-csv')}</Button>,
          <Popconfirm
            placement="left"
            title={t('confirm-delete')}
            onConfirm={() => notification.info('not implemented')}
          >
            <Tooltip placement="left" title={t('delete')}>
              <Button type="danger">
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </Popconfirm>,
        ]}
      />
      {loadingPromoCodes && <SpinnerBlock />}
      {promoCodeBatch && (
        <>
          <EditableField
            data={promoCodeBatch}
            label={t('notes')}
            field="notes"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField data={promoCodeBatch} label={t('duration')} field="duration" readonly />
          <EditableField
            data={promoCodeBatch}
            label={t('createdAt')}
            field="createdAt"
            render={date => dateFormatter({ date, t })}
            readonly
          />
          <EditableField
            data={promoCodeBatch}
            label={t('createdBy')}
            field="createdByRef"
            render={ref => (
              <ReferenceLink
                to={({ __id: id }) => `/app/users/${id}/`}
                reference={ref}
                formatter={({ nickname }) => nickname}
              />
            )}
            readonly
          />
          <EditableField
            data={promoCodeBatch}
            label={t('product')}
            field="productRef"
            render={ref => (
              <ReferenceLink
                to={({ __id: id }) => `/app/products/${id}/`}
                reference={ref}
                formatter={({ name }) => name}
              />
            )}
            readonly
          />
          <PageHeader title={t('promo-codes')} />
          <PromoCodeTable collectionReference={collectionReference} />
        </>
      )}
    </>
  );
}
