import React from 'react';
import { Form, Input } from 'antd';
import { useHandleSubmit } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';

function OfflineMessageForm({ form, handleSubmit, children, initialValues, ...otherProps }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('system');

  const onSubmit = useHandleSubmit({ form, handler: handleSubmit });

  return (
    <>
      <Form onSubmit={onSubmit} {...otherProps}>
        <Form.Item label={t('offline_message')} help={t('offline_message_hint')}>
          {getFieldDecorator('maintenanceReason', {
            initialValue: initialValues && initialValues.maintenanceReason,
          })(<Input.TextArea rows={4} />)}
        </Form.Item>
        {children}
      </Form>
    </>
  );
}

export default Form.create()(OfflineMessageForm);
