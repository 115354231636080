import React from 'react';
import Item from './BlockItem';

export default function BlockView({ blocks, addBlock, splitBlock, toggleTwyllable }) {
  return (
    <div className="block-items-editor">
      {blocks.map((item, index) => (
        <Item
          key={item.__id}
          index={index}
          nextIsParagraph={blocks[index + 1] && blocks[index + 1].type === 'paragraph'}
          prevIsParagraph={blocks[index - 1] && blocks[index - 1].type === 'paragraph'}
          item={item}
          addBlock={addBlock}
          onSplit={splitBlock}
          toggleTwyllable={toggleTwyllable}
        />
      ))}
    </div>
  );
}
