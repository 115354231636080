import React, { PureComponent } from 'react';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createSideToolbarPlugin from '@draft-js-plugins/side-toolbar';
import createSoftNewLinePlugin from '@jimmycode/draft-js-soft-newline-plugin';
import createLinkPlugin from '@draft-js-plugins/anchor';
import { ItalicButton, BoldButton, UnderlineButton, CodeButton } from '@draft-js-plugins/buttons';

import { EditorState } from 'draft-js';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/side-toolbar/lib/plugin.css';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, Menu, MenuProvider } from 'react-contexify';
import { withTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import './block-editor.css';
import { withLastFocus } from '../LastFocusProvider';

function htmlToEditor(html) {
  // let editor;
  // const contentBlock = htmlToDraft(html);
  // if (contentBlock) {
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const contentState = stateFromHTML(html);
  const editor = EditorState.createWithContent(contentState);
  // } else {
  //   editor = EditorState.createEmpty();
  // }
  return editor;
}

class BlockEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.plugins = [
      createInlineToolbarPlugin(),
      createSideToolbarPlugin(),
      createSoftNewLinePlugin(),
      createLinkPlugin(),
    ];

    this.state = {
      editor: htmlToEditor(props.text),
      // editorHTML: '',
    };
  }

  onEditorStateChange = editor => {
    // const editorHTML = draftToHtml(convertToRaw(editor.getCurrentContent()));
    this.setState({ editor /* editorHTML */ });
  };

  submit = () => {
    const { update, ...item } = this.props;
    const { editor } = this.state;
    const editorHTML = stateToHTML(editor.getCurrentContent());
    update({ ...pick(item, 'twyllable', 'index', 'type', '__id'), text: editorHTML });
  };

  focus = () => {
    const { __id: id, setLastFocus } = this.props;
    setLastFocus(id);
    console.log('focus', id);
  };

  render() {
    const { __id: id, remove, index, t, addImage, addImageAfter } = this.props;
    const { editor } = this.state;
    const { InlineToolbar } = this.plugins[0];
    const { SideToolbar } = this.plugins[1];
    const { LinkButton } = this.plugins[3];

    return (
      <div style={{ marginLeft: 80 }} id={id} className="inline-editor">
        <MenuProvider id={id}>
          <Editor
            editorState={editor}
            onChange={this.onEditorStateChange}
            plugins={this.plugins}
            onBlur={this.submit}
            onFocus={this.focus}
          />
        </MenuProvider>
        <Menu id={id}>
          <Item onClick={() => remove(index)}>{t('remove')}</Item>
          <Item onClick={() => addImage(index)}>{t('add-image-before')}</Item>
          <Item onClick={() => addImageAfter(index)}>{t('add-image-after')}</Item>
        </Menu>
        <InlineToolbar>
          {props => (
            <>
              <BoldButton {...props} />
              <ItalicButton {...props} />
              <UnderlineButton {...props} />
              <CodeButton {...props} />
              <LinkButton {...props} />
            </>
          )}
        </InlineToolbar>
        <SideToolbar />
      </div>
    );
  }
}

export default withLastFocus(withTranslation('blocks')(BlockEditor));
