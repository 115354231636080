import React, { useState } from 'react';
import { Button, Col, Icon, PageHeader, Row, Table, Tag } from 'antd';
import { useCollectionWhere, ImportMaterial } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FirebaseImage from '../../components/FirebaseImage';
import { usePermissions } from '../../contexts/Permissions';
import { ROLES } from '../../permissions';

const columns = t => [
  {
    title: t('cover'),
    dataIndex: 'coverUrl',
    render: text => (
      <FirebaseImage alt="project cover" src={text} style={{ maxWidth: 150, maxHeight: 100 }} />
    ),
  },
  {
    title: t('title'),
    dataIndex: 'title',
    render: text => <span>{text}</span>,
  },
  {
    title: t('name'),
    dataIndex: 'name',
    render: text => <span>{text}</span>,
  },
  {
    title: t('author'),
    dataIndex: 'author',
    render: text => <span>{text}</span>,
  },
  {
    title: t('published'),
    dataIndex: 'publishedAt',
    render: p => p && <Icon type="check-circle" />,
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
        {/* <Icon type="delete" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} /> */}
      </span>
    ),
  },
];

const WHERE = {
  field: 'archivedAt',
  op: '==',
  value: null,
};

const WHERE_ARCHIVED = {
  field: 'archivedAt',
  op: '!=',
  value: null,
};

const ORDER = ['modifiedAt', 'desc'];
const ORDER_ARCHIVED = ['archivedAt', 'desc'];

export default function MaterialsList() {
  const [activeMaterials, setActiveMaterials] = useState(true);
  const { data, loading } = useCollectionWhere(
    'materials',
    null,
    activeMaterials ? WHERE : WHERE_ARCHIVED,
    activeMaterials ? ORDER : ORDER_ARCHIVED,
  );
  const { t } = useTranslation(['materials', 'home']);
  const permissions = usePermissions();

  return (
    <>
      <PageHeader
        title={t('home:materials')}
        tags={[
          <Tag color={activeMaterials ? 'green' : 'orange'}>
            {activeMaterials ? t('active') : t('archived')}
          </Tag>,
        ]}
        extra={
          permissions[ROLES.ADMIN] ? (
            <Button onClick={() => setActiveMaterials(!activeMaterials)}>
              {activeMaterials ? t('show-archived') : t('show-active')}
            </Button>
          ) : null
        }
      />
      <Row type="flex" justify="space-between" style={{ marginBottom: '1.5rem' }}>
        <Col span={4}>
          <Link to="create/">
            <Button>{t('add_new')}</Button>
          </Link>
          <ImportMaterial />
        </Col>
      </Row>
      <Table columns={columns(t)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}
