import React, { useMemo } from 'react';
import { Icon, PageHeader, Table } from 'antd';
import {
  usePaginatedQuery,
  useFirestore,
  dateFormatter,
  ReferenceLink,
} from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const columns = t => [
  {
    title: t('code'),
    dataIndex: '__id',
  },
  {
    title: t('duration'),
    dataIndex: 'duration',
  },
  {
    title: t('product'),
    dataIndex: 'productRef',
    render: ref => (
      <ReferenceLink
        to={({ __id: id }) => `/app/products/${id}/`}
        reference={ref}
        formatter={({ name }) => name}
      />
    ),
  },
  {
    title: t('redeemedBy'),
    dataIndex: 'redeemedByRef',
    render: ref => (
      <ReferenceLink
        to={({ __id: id }) => `/app/users/${id}/`}
        reference={ref}
        formatter={({ nickname }) => nickname}
      />
    ),
  },
  {
    title: t('batch'),
    dataIndex: 'batchRef',
    render: ref => (
      <ReferenceLink
        to={({ __id: id }) => `/app/promoCodeBatches/${id}/`}
        reference={ref}
        formatter={({ __id }) => __id}
      />
    ),
  },
  {
    title: t('redeemedAt'),
    dataIndex: 'redeemedAt',
    render: date => dateFormatter({ date, t }),
  },
  {
    title: t('createdAt'),
    dataIndex: 'createdAt',
    render: date => dateFormatter({ date, t }),
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
      </span>
    ),
  },
];

const PAGE_LIMIT = 10;

export function PromoCodeTable({ collectionReference }) {
  const { t } = useTranslation('promoCodes');

  const paginatedQuery = useMemo(
    () => ({
      t,
      collectionReference,
      pageSize: PAGE_LIMIT,
    }),
    [collectionReference, t],
  );

  const { pagination, data, loading } = usePaginatedQuery(paginatedQuery);

  return (
    <>
      {pagination}
      <Table
        columns={columns(t)}
        dataSource={data}
        pagination={false}
        loading={loading}
        rowKey="__id"
      />
      {pagination}
    </>
  );
}

function PromoCodeList() {
  const firestore = useFirestore();
  const { t } = useTranslation('promoCodes');

  const dataTable = useMemo(
    () => <PromoCodeTable collectionReference={firestore().collection('promoCodes')} />,
    [firestore],
  );

  return (
    <>
      <PageHeader
        title={t('home:promo-codes')}
        extra={
          [
            // <Link to="create/" key="1">
            //   <Button>{t('create')}</Button>
            // </Link>,
          ]
        }
      />
      {/* <Input.Search */}
      {/*  onSearch={onSearch} */}
      {/*  style={{ width: 250 }} */}
      {/*  placeholder={t('nickname-starts-by')} */}
      {/* /> */}
      {dataTable}
    </>
  );
}

export default PromoCodeList;
