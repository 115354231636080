import React from 'react';
import { Button, Col, Form, Input, notification, PageHeader, Row } from 'antd';
import {
  useBack,
  useHandleSubmit,
  useFirestore,
  useAuth,
  ChoiceSelect,
  useFunction,
  useGetDefaultLanguage,
} from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import RTEditorInput from '../../components/RTEditorInput';
import { normalizeUpload } from '../../lib/utils';
import { LANG } from '../../constants';
import useUploadMaterialRequest from '../../hooks/useUploadMaterialRequest';
import ImgCropUploadInput from '../../components/ImgCropUploadInput';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading, id }) {
  const { getFieldDecorator } = form;

  const { t } = useTranslation('materials');
  const customRequest = useUploadMaterialRequest({ materialId: id });

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  const defaultLang = useGetDefaultLanguage(LANG);

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('name')} extra={t('name_hint')}>
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('title')} extra={t('title_hint')}>
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('abstract')} extra={t('abstract_hint')}>
        {getFieldDecorator('description', { initialValue: '<p></p>', valuePropName: 'text' })(
          <RTEditorInput />,
        )}
      </Form.Item>
      <Form.Item label={t('cover')}>
        {getFieldDecorator('coverUrl', {
          valuePropName: 'fileList',
          initialValue: null,
          rules: [{ required: true }],
          getValueFromEvent: normalizeUpload,
        })(
          <ImgCropUploadInput
            aspect={440 / 640}
            customRequest={customRequest}
            text={t('cover_upload_text')}
          />,
        )}
      </Form.Item>
      <Form.Item label={t('author')}>
        {getFieldDecorator('author', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('lang')}>
        {getFieldDecorator('languageCode', {
          initialValue: defaultLang,
          rules: [{ required: true }],
        })(<ChoiceSelect choices={LANG} showSearch optionFilterProp="children" />)}
      </Form.Item>
      <Row type="flex" justify="end">
        <Col span={4}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('create')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const MaterialCreateForm = Form.create()(CreateForm);

async function createMaterial([data, clean], { ref, firestore, onSuccess, checkMaterial }) {
  const { name } = data;
  let materialName = name;
  try {
    const { result } = await checkMaterial({ inputName: name });
    materialName = result.materialName;
  } catch (e) {
    notification.error({ message: e });
    return;
  }

  try {
    await ref.set({
      ...omit(data, 'coverUrl', 'name'),
      name: materialName,
      coverUrl: data.coverUrl[0].response.download,
      createdAt: firestore.Timestamp.now(),
      modifiedAt: firestore.Timestamp.now(),
      archivedAt: null,
    });
    clean();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function MaterialsCreate() {
  const onBack = useBack();
  const firestore = useFirestore();
  const auth = useAuth();

  const ref = firestore()
    .collection('materials')
    .doc();

  const checkMaterialName = useFunction({ name: 'materialCheckOrGenerateNameCall' });

  const { run, isPending: creating } = useAsync({
    deferFn: createMaterial,
    firestore,
    ref,
    auth,
    onSuccess: onBack,
    checkMaterial: checkMaterialName,
  });

  const { t } = useTranslation('materials');

  return (
    <>
      <PageHeader title={t('new_material')} onBack={onBack} />
      <MaterialCreateForm onSubmit={run} loading={creating} id={ref.id} />
    </>
  );
}
