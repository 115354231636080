import { Form, Input, Spin, Tabs, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCollection } from '@betwyll/btw-core-backoffice';
import { CreateModal } from './Create';
import ResetPassword from './ResetPassword';
import SyncToggle from './SyncToggle';

function PearsonTab({ data, userRef }) {
  const { t } = useTranslation('users');

  return (
    <div>
      <Form>
        <Form.Item label={t('pearson-id')}>
          <Input value={data.pearsonIesUserId} readOnly disabled />
        </Form.Item>
        <Form.Item label={t('pearson-sync')}>
          <SyncToggle key="sync" reference={userRef.collection('userAccounts')} />
        </Form.Item>
      </Form>
    </div>
  );
}

function LocalTab({ data, userId }) {
  const { t } = useTranslation('users');

  return (
    <div>
      <Form>
        <Form.Item label={t('email')}>
          <Input value={data.email} readOnly disabled />
        </Form.Item>
      </Form>
      <ResetPassword key="reset" userId={userId} />
    </div>
  );
}

export default function Accounts({ reference }) {
  const { t } = useTranslation('users');

  const { data, isPending } = useCollection('userAccounts', reference);

  const hasLocal = useMemo(() => {
    if (data && data.length) {
      return data.map(d => d.__id).includes('emailPassword');
    }
    return false;
  }, [data]);

  if (isPending) {
    return <Spin />;
  }

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '1rem' }}>
        {t('accounts')}
      </Typography.Title>
      {data && data.length > 0 && (
        <Tabs>
          {data.map(d => (
            <Tabs.TabPane key={d.providerId} tab={t(`provider-${d.providerId}`)}>
              {d.providerId === 'pearsonIes' && <PearsonTab data={d} userRef={reference} />}
              {d.providerId === 'emailPassword' && <LocalTab data={d} userId={reference.id} />}
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
      {!hasLocal && <CreateModal userId={reference.id} />}
    </>
  );
}
