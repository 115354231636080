import { notification, PageHeader, Spin } from 'antd';
import { SpinnerBlock, useBack, useDocument, useFirestore } from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import EditableField from '../../components/EditableField';
import ReferenceLink from '../../components/ReferenceLink';
import Contents from './Contents';
import ReferenceChoice from '../../components/ReferenceChoice';
import { getLabel } from '../../lib/utils';

const WHERE = {
  field: 'publishedAt',
  op: '!=',
  value: null,
};

const ORDER = ['index', 'asc'];

async function updatedidacticSet([data, _, onSuccess], { basePath, firestore }) {
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function EditdidacticSet() {
  const { didacticSetId } = useParams();
  const { t, i18n } = useTranslation('materials');
  const firestore = useFirestore();
  const { data: didacticSet, loading: loadingdidacticSet } = useDocument(
    didacticSetId,
    'didacticSets',
  );
  const goBack = useBack('../');
  const basePath = firestore()
    .collection('didacticSets')
    .doc(didacticSetId);

  const { run, isPending: updating } = useAsync({
    deferFn: updatedidacticSet,
    basePath,
    firestore,
  });

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('didacticSet')} {loadingdidacticSet ? <Spin /> : didacticSet.title}
          </>
        }
        onBack={goBack}
      />
      {loadingdidacticSet && <SpinnerBlock />}
      {didacticSet && (
        <>
          <EditableField
            data={didacticSet}
            label={t('title')}
            field="title"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={didacticSet}
            label={t('label')}
            field="label"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={didacticSet}
            label={t('material')}
            field="materialRef"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            InputComponent={
              <ReferenceChoice collection="materials" where={WHERE} render={m => m.title} />
            }
            render={ref => {
              if (!ref) {
                return <SpinnerBlock />;
              }
              return (
                <ReferenceLink
                  to={({ __id }) => `/app/materials/${__id}/`}
                  reference={ref}
                  formatter={({ title }) => title}
                />
              );
            }}
          />
          <EditableField
            data={didacticSet}
            label={t('defaultDidacticCategory')}
            field="defaultDidacticCategoryRef"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            InputComponent={
              <ReferenceChoice
                collection="didacticCategories"
                order={ORDER}
                render={m => getLabel(m.labelL10n, activeLang)}
              />
            }
            render={ref => {
              if (!ref) {
                return null;
              }
              return (
                <ReferenceLink
                  to={({ __id }) => `/app/didacticCategories/${__id}/`}
                  reference={ref}
                  formatter={({ labelL10n }) => getLabel(labelL10n, activeLang)}
                />
              );
            }}
          />
        </>
      )}
      {didacticSet && didacticSet.materialRef && (
        <Contents
          didacticSetRef={basePath}
          materialRef={didacticSet.materialRef}
          key={didacticSet.materialRef.id}
        />
      )}
    </>
  );
}
