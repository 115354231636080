import { Button, Form, Input, notification, PageHeader } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useHandleSubmit } from '@betwyll/btw-core-backoffice';

function PRForm({ onSubmit, form }) {
  const { t } = useTranslation('login');

  const compareToFirstPassword = useCallback(
    (rule, value, callback) => {
      if (value && value !== form.getFieldValue('password')) {
        callback(t('password-no-match'));
      } else {
        callback();
      }
    },
    [form, t],
  );

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  const { getFieldDecorator } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('login:new-password')}>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label={t('login:verify-password')}>
        {getFieldDecorator('verifyPassword', {
          rules: [
            {
              required: true,
            },
            {
              validator: compareToFirstPassword,
            },
          ],
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">{t('login:save')}</Button>
      </Form.Item>
    </Form>
  );
}

const PasswordResetForm = Form.create()(PRForm);

export default function PasswordReset() {
  const { t } = useTranslation(['home', 'login']);
  const auth = useAuth();
  const resetPassword = useCallback(async data => {
    console.log(data);
    try {
      await auth().currentUser.updatePassword(data.password);
      notification.success({ message: t('login:password-changed') });
    } catch (e) {
      notification.error({ message: e.message });
    }
  });

  return (
    <>
      <PageHeader title={t('password-reset')} />
      <PasswordResetForm onSubmit={resetPassword} />
    </>
  );
}
