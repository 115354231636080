import { PageHeader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TwyllsByGroup from './TwyllsByGroup';

export default function ModerationDashboard() {
  const { t } = useTranslation('home');
  return (
    <>
      <PageHeader title={t('moderation')} />
      <TwyllsByGroup />
      <TwyllsByGroup showPublic={false} style={{ marginTop: '1rem' }} />
    </>
  );
}
