import React from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Icon,
  Popconfirm,
  Row,
  Typography,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SpinnerBlock, useCollection } from '@betwyll/btw-core-backoffice';
import moment from 'moment';
import { useAsync } from 'react-async';

async function deleteDevice(_, { reference, id }) {
  await reference
    .collection('userDevices')
    .doc(id)
    .delete();
}

function Device({ label, lastCheckIn, langCode, __id: id, reference }) {
  const { t } = useTranslation('users');
  const { run, isPending } = useAsync({
    deferFn: deleteDevice,
    reference,
    id,
    onReject: () => notification.error({ message: t('delete-error') }),
  });

  return (
    <Col span={6}>
      <Card
        style={{ marginBottom: '1rem' }}
        title={label}
        actions={[
          <Popconfirm title={t('confirm-delete-device')} key="delete" onConfirm={run}>
            <Button type="danger" loading={isPending}>
              <Icon type="delete" />
            </Button>
          </Popconfirm>,
        ]}
      >
        <Descriptions layout="vertical" column={1}>
          <Descriptions.Item label={t('last-checkin')}>
            {lastCheckIn ? moment(lastCheckIn.toDate()).format('DD/MM/YYYY HH:mm') : t('never')}
          </Descriptions.Item>
          <Descriptions.Item label={t('language')}>{langCode}</Descriptions.Item>
        </Descriptions>
      </Card>
    </Col>
  );
}

export default function Devices({ reference }) {
  const { t } = useTranslation('users');
  const { data: devices, loading } = useCollection('userDevices', reference);

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '1rem' }}>
        {t('devices')}
      </Typography.Title>
      {loading && <SpinnerBlock />}
      {!loading && devices.length > 0 && (
        <Row gutter={16}>
          {devices.map(d => (
            <Device key={d.__id} {...d} reference={reference} />
          ))}
        </Row>
      )}
    </>
  );
}
