import { Menu } from 'antd';
import React, { Suspense } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { SpinnerBlock } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';

// TODO: Lazy load this pages
import SystemPage from '../../pages/System';
import DefaultLayout from '../../components/Layout';
import Materials from '../../pages/Materials';
import MaterialCategories from '../../pages/MaterialCategories';
import Projects from '../../pages/Projects';
import Users from '../../pages/Users';
import Moderation from '../../pages/Moderation';
import Products from '../../pages/Products';
import { ROLES } from '../../permissions';
import { usePermissions } from '../../contexts/Permissions';
import PasswordReset from '../../pages/PasswordReset';
import DidacticSet from '../../pages/DidacticSet';
import DidacticCategories from '../../pages/DidacticCategories';
import Groups from '../../pages/Groups';
import Notifications from '../../pages/Notifications';
import PromoCodes from '../../pages/PromoCodes';
import PromoCodesBatch from '../../pages/PromoCodesBatch';

export default function AdminHome() {
  const { t } = useTranslation(['home', 'visibility']);
  const permissions = usePermissions();

  return (
    <DefaultLayout
      menuItems={[
        (permissions[ROLES.ADMIN] || permissions[ROLES.COMMUNITY_MODERATOR]) && (
          <Menu.Item key="/app/moderation/">
            <span>{t('moderation')}</span>
            <Link to="/app/moderation/" />
          </Menu.Item>
        ),
        ...(permissions[ROLES.ADMIN]
          ? [
              <Menu.Item key="/app/projects/">
                <span>{t('projects')}</span>
                <Link to="/app/projects/" />
              </Menu.Item>,
              <Menu.Item key="/app/projects/?visibility=template">
                <span>{t('visibility:template')}</span>
                <Link to="/app/projects/?visibility=template" />
              </Menu.Item>,
            ]
          : []),
        ...(permissions[ROLES.ADMIN] || permissions[ROLES.MATERIAL_EDITOR]
          ? [
              <Menu.Item key="/app/materials/">
                <span>{t('materials')}</span>
                <Link to="/app/materials/" />
              </Menu.Item>,
              <Menu.Item key="/app/material-categories/">
                <span>{t('material-categories')}</span>
                <Link to="/app/material-categories/" />
              </Menu.Item>,
            ]
          : []),
        (permissions[ROLES.ADMIN] || permissions[ROLES.MATERIAL_EDITOR]) && (
          <Menu.Item key="/app/didacticSet/">
            <span>{t('didacticSets')}</span>
            <Link to="/app/didacticSet/" />
          </Menu.Item>
        ),
        permissions[ROLES.ADMIN] && (
          <Menu.SubMenu title={t('users')}>
            <Menu.Item key="/app/users/paginated/">
              <span>{t('users-paginated')}</span>
              <Link to="/app/users/paginated/" />
            </Menu.Item>
            <Menu.Item key="/app/users/">
              <span>{t('users-filters')}</span>
              <Link to="/app/users/" />
            </Menu.Item>
          </Menu.SubMenu>
        ),
        permissions[ROLES.ADMIN] && (
          <Menu.Item key="/app/groups/">
            <span>{t('groups')}</span>
            <Link to="/app/groups/" />
          </Menu.Item>
        ),
        (permissions[ROLES.ADMIN] || permissions[ROLES.GROWTH_MANAGER]) && (
          <Menu.Item key="/app/notifications/">
            <span>{t('messages')}</span>
            <Link to="/app/notifications/" />
          </Menu.Item>
        ),
        permissions[ROLES.ADMIN] && (
          <Menu.Item key="/app/updates/">
            <span>{t('updates')}</span>
            <Link to="/app/updates/" />
          </Menu.Item>
        ),
        permissions[ROLES.ADMIN] && (
          <Menu.Item key="/app/stats/">
            <span>{t('stats')}</span>
            <Link to="/app/stats/" />
          </Menu.Item>
        ),
        (permissions[ROLES.ADMIN] || permissions[ROLES.PRODUCT_MANAGER]) && (
          <Menu.SubMenu title={t('products')}>
            <Menu.Item key="/app/products/">
              <span>{t('products')}</span>
              <Link to="/app/products/" />
            </Menu.Item>
            <Menu.Item key="/app/promoCodes/">
              <span>{t('promo-codes')}</span>
              <Link to="/app/promoCodes/" />
            </Menu.Item>
            <Menu.Item key="/app/promoCodeBatches/">
              <span>{t('promo-codes-batch')}</span>
              <Link to="/app/promoCodeBatches/" />
            </Menu.Item>
          </Menu.SubMenu>
        ),
        permissions[ROLES.ADMIN] && (
          <Menu.Item key="/app/system/">
            <span>{t('system')}</span>
            <Link to="/app/system/" />
          </Menu.Item>
        ),
      ]}
    >
      <Suspense fallback={<SpinnerBlock />}>
        <Switch>
          <Route exact path="/app/" render={() => null} />
          <Route path="/app/products/" component={Products} />
          <Route path="/app/system/" component={SystemPage} />
          <Route path="/app/materials/" component={Materials} />
          <Route path="/app/material-categories/" component={MaterialCategories} />
          <Route path="/app/didacticSet/" component={DidacticSet} />
          <Route path="/app/projects/" component={Projects} />
          <Route path="/app/promoCodes/" component={PromoCodes} />
          <Route path="/app/promoCodeBatches/" component={PromoCodesBatch} />
          <Route path="/app/users/" component={Users} />
          <Route path="/app/groups/" component={Groups} />
          <Route path="/app/moderation/" component={Moderation} />
          <Route path="/app/password-reset/" component={PasswordReset} />
          <Route path="/app/didacticCategories/" component={DidacticCategories} />
          <Route path="/app/notifications/" component={Notifications} />
        </Switch>
      </Suspense>
    </DefaultLayout>
  );
}
