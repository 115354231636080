import React, { useMemo } from 'react';
import { Button, Empty, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';

const WHERE = [];
const ORDER = ['index', 'asc'];

export default function Contents({ materialRef, didacticSetRef }) {
  const { t } = useTranslation('materials');
  const where = useMemo(() => {
    return [
      {
        field: 'didacticSetRef',
        op: '==',
        value: didacticSetRef,
      },
    ];
  }, [didacticSetRef]);
  const { data, loading } = useCollectionWhere('contents', materialRef, WHERE, ORDER);
  const { data: twylls } = useCollectionWhere('twyllTemplates', null, where);
  const { data: pills } = useCollectionWhere('didacticPills', null, where);

  if (!materialRef) {
    return <Empty description={t('no-contents')} />;
  }

  return (
    <Table
      dataSource={data}
      loading={loading}
      rowKey="__id"
      style={{ marginTop: '1rem' }}
      columns={[
        {
          title: t('title'),
          dataIndex: 'title',
        },
        {
          title: t('twyllTemplates'),
          render: record => twylls.filter(e => e.contentRef.id === record.__id).length,
        },
        {
          title: t('didacticPills'),
          render: record => pills.filter(e => e.contentRef.id === record.__id).length,
        },
        {
          title: t('actions'),
          render: record => (
            <Link to={`materials/${materialRef.id}/contents/${record.__id}/`}>
              <Button>{t('edit')}</Button>
            </Link>
          ),
        },
      ]}
    />
  );
}
