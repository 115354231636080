import { Button, Icon } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Text } from './Text';
import Img from './Img';
import { LastFocusContext } from './LastFocusProvider';

function getStyle(height) {
  return {
    marginTop: `${Math.round(height / 2) - 12}px`,
  };
}

export default function Item({
  item,
  addBlock,
  index,
  onSplit,
  toggleTwyllable,
  nextIsParagraph,
  prevIsParagraph,
}) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const style = useMemo(() => getStyle(height));
  // eslint-disable-next-line no-unused-vars
  const [_focus, setFocus] = useContext(LastFocusContext);

  const isParagraph = item.type === 'paragraph';

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref, setHeight, item.text]);

  return (
    <div className={classNames('block-item', { twyllable: item.twyllable })} id={item.__id}>
      {item.twyllable && isParagraph && prevIsParagraph && (
        <Button
          onClick={() => {
            setFocus(item.__id);
            addBlock(index);
          }}
          size="small"
          className="block-action merge-up"
        >
          <Icon type="vertical-align-top" />
        </Button>
      )}
      <Button
        onClick={() => {
          setFocus(item.__id);
          toggleTwyllable(index);
        }}
        size="small"
        className="block-action toggle-twyllable"
        style={style}
        type={item.twyllable ? 'danger' : 'primary'}
      >
        <Icon type={item.twyllable ? 'close' : 'plus'} />
      </Button>
      <div className="block-item__content" ref={ref}>
        {isParagraph && (
          <Text
            text={item.text}
            index={index}
            split={(...data) => {
              setFocus(item.__id);
              onSplit(...data);
            }}
            canSplit={item.twyllable}
          />
        )}
        {item.type === 'image' && <Img {...item} />}
      </div>
      {item.twyllable && isParagraph && nextIsParagraph && (
        <Button
          onClick={() => {
            setFocus(item.__id);
            addBlock(index, false);
          }}
          size="small"
          className="block-action merge-down"
        >
          <Icon type="vertical-align-bottom" />
        </Button>
      )}
    </div>
  );
}
