import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { List, Form, Col, Row, Button, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import FieldFormWrapper from './FieldFormWrapper';

export default function EditableReferenceArray({
  data,
  field,
  label,
  update = _ => _,
  updating,
  InputComponent = () => null,
  mapping = doc => doc.id,
  renderItem = ({ title }) => (
    <List.Item>
      <List.Item.Meta title={title} />
    </List.Item>
  ),
}) {
  const [editing, setEditing] = useState(false);
  const toggleEdit = () => setEditing(!editing);
  const { t } = useTranslation('common');

  const list = useMemo(() => {
    if (data && field && data[field]) {
      return data[field];
    }
    return [];
  }, [data, field]);

  if (editing) {
    return (
      <FieldFormWrapper onSubmit={update} onSuccess={toggleEdit}>
        {fieldDecorator => (
          <Form.Item label={label}>
            {fieldDecorator(field, {
              initialValue: list.map(mapping),
            })(<InputComponent />)}
            <Row type="flex" gutter={16} justify="end">
              <Col>
                <Button onClick={toggleEdit}>{t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={updating}>
                  {t('save')}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        )}
      </FieldFormWrapper>
    );
  }

  console.log(list);

  return (
    <Row gutter={16} style={{ marginTop: '1.5rem' }}>
      <Col span={3}>{label}</Col>
      <Col span={10}>
        <Row gutter={16} type="flex" justify="space-between">
          <Col span={20}>
            <List dataSource={list} renderItem={renderItem} />
          </Col>
          <Col span={4}>
            <Button onClick={toggleEdit}>
              <Icon type="edit" />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

EditableReferenceArray.propTypes = {
  label: PropTypes.string.isRequired,
  renderItem: PropTypes.func,
  mapping: PropTypes.func,
};

EditableReferenceArray.defaultProps = {
  renderItem: ({ title }) => (
    <List.Item>
      <List.Item.Meta title={title} />
    </List.Item>
  ),
  mapping: doc => doc.id,
};
