import React from 'react';
import { Icon, PageHeader, Table } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReferenceLink from '../../components/ReferenceLink';

const columns = t => [
  {
    title: t('name'),
    dataIndex: 'title',
    render: text => <span>{text}</span>,
  },
  {
    title: t('owner'),
    dataIndex: 'ownerRef',
    render: (_, record) => (
      <ReferenceLink
        reference={record.ownerRef}
        to={({ __id }) => `/app/users/${__id}/`}
        formatter={({ nickname }) => nickname}
      />
    ),
  },
  {
    title: t('people'),
    dataIndex: 'usersRefs',
    render: users => (users ? users.length : 0),
  },
  {
    title: t('projects'),
    dataIndex: 'projectsRefs',
    render: projects => (projects ? projects.length : 0),
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
        {/* <Icon type="delete" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} /> */}
      </span>
    ),
  },
];

const WHERE = {
  field: 'deletedAt',
  op: '==',
  value: null,
};

const ORDER = null;

export default function MaterialsList() {
  const { data, loading } = useCollectionWhere('groups', null, WHERE, ORDER);
  const { t } = useTranslation(['groups', 'home']);

  console.log(data);

  return (
    <>
      <PageHeader title={t('home:groups')} />
      <Table columns={columns(t)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}
