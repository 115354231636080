import React, { useState } from 'react';
import { Form, PageHeader, Select, Input, Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useHandleSubmit } from '@betwyll/btw-core-backoffice';
import ReferenceChoice from '../../components/ReferenceChoice';
import useFunction from '../../hooks/useFunction';
import { NOTIFICATION_TYPES_OPTIONS } from './config';

const WHERE_NOT_PROJECT_DELETED = [
  {
    field: 'deletedAt',
    op: '==',
    value: null,
  },
];

function SendNotificationForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  const { t } = useTranslation('messages');

  const type = form.getFieldValue('notificationType');

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('type')}>
        {getFieldDecorator('notificationType', { rules: [{ required: true }] })(
          <Select>
            {NOTIFICATION_TYPES_OPTIONS(t).map(opt => (
              <Select.Option key={opt.value} value={opt.value}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label={t('title')}>
        {getFieldDecorator('notificationTitle', { rules: [{ required: true }] })(<Input />)}
      </Form.Item>
      <Form.Item label={t('text')}>
        {getFieldDecorator('notificationBody', { rules: [{ required: true }] })(
          <Input.TextArea rows={3} />,
        )}
      </Form.Item>
      {type === 'broadcast_link_project' && (
        <Form.Item label={t('project')}>
          {getFieldDecorator('projectId', { rules: [{ required: true }] })(
            <ReferenceChoice
              collection="projects"
              where={WHERE_NOT_PROJECT_DELETED}
              render={p => p.title}
            />,
          )}
        </Form.Item>
      )}
      {type === 'broadcast_link_news' && (
        <Form.Item label={t('url')}>
          {getFieldDecorator('attachmentUri', { rules: [{ required: true }] })(
            <Input type="url" />,
          )}
        </Form.Item>
      )}
      {type === 'broadcast_external_resource' && (
        <Form.Item label={t('URL')}>
          {getFieldDecorator('attachmentUri', { rules: [{ required: true }] })(
            <Input type="url" />,
          )}
        </Form.Item>
      )}
      <Button htmlType="submit" type="primary" loading={loading} disabled={loading}>
        {t('send')}
      </Button>
    </Form>
  );
}

const NotificationForm = Form.create()(SendNotificationForm);

export default function Send({ location }) {
  const history = useHistory();
  const { t } = useTranslation('messages');
  const ids = location.state || [];
  const [loading, setLoading] = useState(false);

  const callfn = useFunction({
    name: 'startNotificationMulticast',
    onSuccess: () => {
      setLoading(false);
      notification.success({ message: t('notifications-sent-success') });
    },
    onError: e => {
      setLoading(false);
      notification.error({ message: e.message });
    },
    preBody: {
      recipientUsersIds: ids,
    },
  });

  return (
    <>
      <PageHeader
        title={
          <>
            {t('send-notification-to')} {ids.length} {t('users')}
          </>
        }
        onBack={history.goBack}
      />
      <NotificationForm
        loading={loading}
        onSubmit={data => {
          Modal.confirm({
            title: t('are-you-sure'),
            content: (
              <>
                {t('you-selected')} {ids.length} {t('users')}
              </>
            ),
            async onOk() {
              setLoading(true);
              if (data.projectId) {
                // eslint-disable-next-line no-param-reassign
                data.projectId = data.projectId.id;
              }
              await callfn(data);
            },
          });
        }}
      />
    </>
  );
}
