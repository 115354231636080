import React from 'react';
import clone from 'lodash/clone';
import { Button, Col, Form, Input, InputNumber, notification, PageHeader, Row } from 'antd';
import {
  useBack,
  useHandleSubmit,
  useFirestore,
  useAuth,
  L10nField,
  LinkedMaterialField,
} from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;

  const { t } = useTranslation('materials');

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('name')} extra={t('title_hint')}>
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('index')} extra={t('title_hint')}>
        {getFieldDecorator('index', {
          rules: [
            {
              required: true,
            },
          ],
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label={t('label')}>
        {getFieldDecorator('labelL10n', {
          rules: [
            {
              required: true,
            },
          ],
        })(<L10nField languages={LANGUAGES} />)}
      </Form.Item>
      <Form.Item label={t('linked_materials')}>
        {getFieldDecorator('materialRefs', {
          valuePropsName: 'materials',
          initialValue: [],
        })(<LinkedMaterialField />)}
      </Form.Item>
      <Row type="flex" justify="end">
        <Col span={4}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('create')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const MaterialCategoryCreateForm = Form.create()(CreateForm);

async function createMaterialCategory([data, clean], { ref, firestore, onSuccess }) {
  try {
    const d = clone(data);
    d.materialRefs = d.materialRefs.map(id =>
      firestore()
        .collection('materials')
        .doc(id),
    );
    await ref.set(d);
    clean();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function MaterialCategoryCreate() {
  const onBack = useBack();
  const firestore = useFirestore();
  const auth = useAuth();

  const ref = firestore()
    .collection('materialCategories')
    .doc();

  const { run, isPending: creating } = useAsync({
    deferFn: createMaterialCategory,
    firestore,
    ref,
    auth,
    onSuccess: onBack,
  });

  const { t } = useTranslation('materials');

  return (
    <>
      <PageHeader title={t('new_materialCategory')} onBack={onBack} />
      <MaterialCategoryCreateForm onSubmit={run} loading={creating} id={ref.id} />
    </>
  );
}
