import React, { useMemo } from 'react';
import { Button, Icon, PageHeader, Table } from 'antd';
import {
  usePaginatedQuery,
  useFirestore,
  dateFormatter,
  ReferenceLink,
} from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCSVExport from './useCSVExport';

const ExportButton = ({ id }) => {
  const run = useCSVExport(id);
  return (
    <Button
      onClick={run}
      icon="download"
      type="link"
      style={{ fontSize: '1.3rem', marginRight: '.5rem' }}
    />
  );
};

const columns = t => [
  {
    title: t('product'),
    dataIndex: 'productRef',
    render: ref => (
      <ReferenceLink
        to={({ __id: id }) => `/app/products/${id}/`}
        reference={ref}
        formatter={({ name }) => name}
      />
    ),
  },
  {
    title: t('createdBy'),
    dataIndex: 'createdByRef',
    render: ref => (
      <ReferenceLink
        to={({ __id: id }) => `/app/users/${id}/`}
        reference={ref}
        formatter={({ nickname }) => nickname}
      />
    ),
  },
  {
    title: t('size'),
    dataIndex: 'batchSize',
  },
  {
    title: t('duration'),
    dataIndex: 'duration',
  },
  {
    title: t('createdAt'),
    dataIndex: 'createdAt',
    render: date => dateFormatter({ date, t }),
  },
  {
    title: t('notes'),
    dataIndex: 'notes',
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
        <ExportButton id={record.__id} />
      </span>
    ),
  },
];

const PAGE_LIMIT = 10;

function PromoCodeList() {
  const firestore = useFirestore();
  const { t } = useTranslation('promoCodeBatches');

  const paginatedQuery = useMemo(
    () => ({
      t,
      collectionReference: firestore()
        .collection('promoCodeBatches')
        .orderBy('createdAt', 'desc'),
      pageSize: PAGE_LIMIT,
    }),
    [firestore, t],
  );

  const { pagination, data, loading } = usePaginatedQuery(paginatedQuery);

  console.log(data);

  return (
    <>
      <PageHeader
        title={t('home:promo-codes-batch')}
        extra={[
          <Link to="create/" key="1">
            <Button>{t('create')}</Button>
          </Link>,
        ]}
      />
      {/* <Input.Search */}
      {/*  onSearch={onSearch} */}
      {/*  style={{ width: 250 }} */}
      {/*  placeholder={t('nickname-starts-by')} */}
      {/* /> */}
      {pagination}
      <Table
        columns={columns(t, firestore)}
        dataSource={data}
        pagination={false}
        loading={loading}
        rowKey="__id"
      />
      {pagination}
    </>
  );
}

export default PromoCodeList;
