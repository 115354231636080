import React from 'react';
import { Layout, Card } from 'antd';
import LoginForm from './LoginForm';

function LoginPage() {
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ maxWidth: '40em', margin: 20 }}>
          <LoginForm />
        </Card>
      </Layout.Content>
    </Layout>
  );
}

export default LoginPage;
