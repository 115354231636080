import React from 'react';
import { Button, Col, Icon, PageHeader, Row, Table } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FirebaseImage from '../../components/FirebaseImage';

const columns = t => [
  {
    title: t('cover'),
    dataIndex: 'image',
    render: text => (
      <FirebaseImage alt="project cover" src={text} style={{ maxWidth: 150, maxHeight: 100 }} />
    ),
  },
  {
    title: t('sku'),
    dataIndex: '__id',
    render: text => <span>{text}</span>,
  },
  {
    title: t('name'),
    dataIndex: 'name',
    render: text => <span>{text}</span>,
  },
  {
    title: t('description'),
    dataIndex: 'description',
    render: text => <span>{text}</span>,
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
        <Icon type="eye" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        <Icon type="inbox" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
      </span>
    ),
  },
];

const STYLE = { marginBottom: '1.5rem' };

const WHERE = {
  field: 'deletedAt',
  op: '==',
  value: null,
};

export default function ProductsList() {
  const { data, loading } = useCollectionWhere('products', null, WHERE);

  const { t } = useTranslation(['products', 'home']);

  return (
    <>
      <PageHeader title={t('home:products')} />
      <Row type="flex" justify="space-between" style={STYLE}>
        <Col span={4}>
          <Link to="create/">
            <Button>{t('add_new')}</Button>
          </Link>
        </Col>
        {/* <Col span={4}> */}
        {/*  <Row> */}
        {/*    <Col offset={16} span={8}> */}
        {/*      <Button> */}
        {/*        <Icon type="download" title="Export CSV" /> */}
        {/*      </Button> */}
        {/*    </Col> */}
        {/*  </Row> */}
        {/* </Col> */}
      </Row>
      <Table columns={columns(t)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}

ProductsList.whyDidYouRender = true;
