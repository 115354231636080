import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Input,
  InputNumber,
  List,
  notification,
  PageHeader,
  Popconfirm,
  Select,
  Spin,
  Switch,
  Upload,
} from 'antd';
import { SpinnerBlock, useBack, useDocument, useFirestore } from '@betwyll/btw-core-backoffice';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import { normalizeUpload } from '../../lib/utils';
import EditableReferenceArray from '../../components/EditableReferenceArray';
import EditableField from '../../components/EditableField';
import FirebaseImage from '../../components/FirebaseImage';
import ArrayField from '../../components/ArrayField';
import useUploadProductRequest from '../../hooks/useUploadProductRequest';
import { ROLES } from '../../permissions';

async function updateProject([data, _, onSuccess], { basePath, firestore }) {
  console.log(data);
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

async function deleteProject(_, { basePath, firestore, onSuccess, t }) {
  try {
    await basePath.update({
      deletedAt: firestore.Timestamp.now(),
    });
    onSuccess();
    notification.success({ message: t('delete_notification') });
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function EditProject() {
  const { productId } = useParams();
  const firestore = useFirestore();
  const { data: product, loading: loadingProject } = useDocument(productId, 'products');
  const { t } = useTranslation(['products', 'common', 'status-field', 'visibility', 'roles']);
  const goBack = useBack();
  const basePath = firestore()
    .collection('products')
    .doc(productId);

  const { run, isPending: updating } = useAsync({
    deferFn: updateProject,
    basePath,
    firestore,
  });

  const { run: remove, isPending: deleting } = useAsync({
    deferFn: deleteProject,
    firestore,
    onSuccess: goBack,
    basePath,
    t,
  });

  const customRequest = useUploadProductRequest({ productId });

  return (
    <>
      <PageHeader
        title={
          <>
            {t('product')} {loadingProject ? <Spin /> : product.name}
          </>
        }
        onBack={goBack}
        extra={[
          <Popconfirm key="delete" placement="left" title={t('confirm_delete')} onConfirm={remove}>
            <Button loading={deleting} type="danger">
              {t('common:delete')}
            </Button>
          </Popconfirm>,
        ]}
      />
      {loadingProject && <SpinnerBlock />}
      {product && (
        <>
          <EditableField data={product} label={t('sku')} field="__id" readonly="readonly" />
          <EditableField
            data={product}
            label={t('name')}
            field="name"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={product}
            label={t('description')}
            field="description"
            decoratorExtra={{ rules: [{ required: true }] }}
            InputComponent={<Input.TextArea rows={4} />}
            update={run}
            updating={updating}
          />
          <EditableField
            data={product}
            label={t('isSubscription')}
            field="isSubscription"
            decoratorExtra={{ rules: [{ required: true }], valuePropName: 'checked' }}
            InputComponent={<Switch />}
            update={run}
            updating={updating}
            render={value => <Icon type={value ? 'check' : 'close'} />}
          />
          {product.isSubscription && (
            <EditableField
              data={product}
              label={t('subscriptionId')}
              field="subscriptionId"
              decoratorExtra={{ rules: [{ required: true }] }}
              update={run}
              updating={updating}
            />
          )}
          {product.isSubscription && (
            <EditableField
              data={product}
              label={t('planId')}
              field="planId"
              decoratorExtra={{ rules: [{ required: true }] }}
              update={run}
              updating={updating}
            />
          )}
          {product.isSubscription && (
            <>
              <EditableField
                data={product}
                label={t('ownedGroupsLimit')}
                field="ownedGroupsLimit"
                decoratorExtra={{ rules: [{ required: true }] }}
                InputComponent={<InputNumber min={0} />}
                update={run}
                updating={updating}
              />
              <EditableField
                data={product}
                label={t('groupUsersLimit')}
                field="groupUsersLimit"
                decoratorExtra={{ rules: [{ required: true }] }}
                InputComponent={<InputNumber min={0} />}
                update={run}
                updating={updating}
              />
              <EditableField
                data={product}
                label={t('ownedPrivateProjectsLimit')}
                field="ownedPrivateProjectsLimit"
                decoratorExtra={{ rules: [{ required: true }] }}
                InputComponent={<InputNumber min={0} />}
                update={run}
                updating={updating}
              />
              <EditableField
                data={product}
                label={t('privateProjectUsersLimit')}
                field="privateProjectUsersLimit"
                decoratorExtra={{ rules: [{ required: true }] }}
                InputComponent={<InputNumber min={0} />}
                update={run}
                updating={updating}
              />
              <EditableField
                data={product}
                label={t('privateProjectGroupsLimit')}
                field="privateProjectGroupsLimit"
                decoratorExtra={{ rules: [{ required: true }] }}
                InputComponent={<InputNumber min={0} />}
                update={run}
                updating={updating}
              />
            </>
          )}
          <EditableField
            data={product}
            label={t('cover')}
            field="image"
            update={run}
            updating={updating}
            decoratorExtra={{
              valuePropName: 'fileList',
              initialValue: null,
              rules: [{ required: true }],
              getValueFromEvent: normalizeUpload,
            }}
            transform={data => ({ image: data.image[0].response.download })}
            InputComponent={
              <Upload.Dragger
                customRequest={customRequest}
                listType="picture"
                showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">{t('cover_upload_text')}</p>
              </Upload.Dragger>
            }
            render={url => (
              <FirebaseImage src={url} alt="cover" style={{ maxWidth: 250, maxHeight: 200 }} />
            )}
          />
          <EditableReferenceArray
            data={product}
            label={t('linked-objects')}
            field="linkedObjectsIds"
            InputComponent={ArrayField}
            decoratorExtra={{ rules: [{ required: true }] }}
            mapping={doc => doc}
            update={run}
            updating={updating}
            renderItem={title => (
              <List.Item>
                <List.Item.Meta title={title} />
              </List.Item>
            )}
          />
          <EditableField
            data={product}
            label={t('grantsRole')}
            field="grantsRole"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            InputComponent={
              <Select>
                <Select.Option value={ROLES.STRONG}>{t(`roles:${ROLES.STRONG}`)}</Select.Option>
                <Select.Option value={ROLES.SUPER}>{t(`roles:${ROLES.SUPER}`)}</Select.Option>
              </Select>
            }
          />
        </>
      )}
    </>
  );
}

EditableReferenceArray.propTypes = {
  label: PropTypes.string.isRequired,
  renderItem: PropTypes.func,
};

EditableReferenceArray.defaultProps = {
  renderItem: ({ title }) => (
    <List.Item>
      <List.Item.Meta title={title} />
    </List.Item>
  ),
};
