import { useFirebase } from '@betwyll/btw-core-backoffice';
import { useCallback } from 'react';
import * as uuid from 'uuid';

export default function useUploadRequest(path, random = false) {
  const firebase = useFirebase();
  const storageRef = firebase.storage().ref();

  // eslint-disable-next-line no-param-reassign
  path += random ? uuid.v4() : '';

  return useCallback(
    async ({ onSuccess, onError, file }) => {
      const ref = storageRef.child(path);
      try {
        await ref.put(file);
        const download = `gs://${ref.bucket}/${path}`;
        onSuccess({ download });
      } catch (e) {
        onError(e);
      }
    },
    [path, storageRef],
  );
}
