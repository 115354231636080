export const ADMIN = 'admin';
export const TECHNICIAN = 'technician';
export const ROLE_OPTIONS = [
  { value: ADMIN, label: 'Amministratore' },
  { value: TECHNICIAN, label: 'Tecnico' },
];

export const EXCLUDE_DEVICES_FIELDS = ['__id', 'authorizedUsers'];

export const COLLECTION_DISPLAY_MAPPER = {
  companies: 'name',
  users: 'email',
};

export const FUNCTIONS_ENDPOINT = 'https://europe-west1-tlab-iot.cloudfunctions.net';

export const LOAD_START_ACTION_HOOK = 'startLoading';
export const LOAD_END_ACTION_HOOK = 'endLoading';

export const TOPICS = ['System', 'Marketing', 'Service'];

export const VISIBILITY_OPTIONS = t => ({
  private: t('visibility:private'),
  unlisted: t('visibility:unlisted'),
  listed: t('visibility:listed'),
});

export const DEFAULT_TEXT_MESSAGE = 'This is a test message.';

export const FILTERS = {
  TYPES: {
    QUERY: 'query',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
    DYNAMIC: 'dynamic',
  },
  OPS: {
    IN: { value: 'in', label: 'In' },
    EQUALS: { value: '==', label: '=' },
  },
};

export const LANG = {
  'ar-SA': 'Arabic (Saudi Arabia)',
  'zh-CN': 'Chinese (China)',
  'zh-HK': 'Chinese (Hong Kong SAR China)',
  'zh-TW': 'Chinese (Taiwan)',
  'cs-CZ': 'Czech (Czech Republic)',
  'da-DK': 'Danish (Denmark)',
  'nl-BE': 'Dutch (Belgium)',
  'nl-NL': 'Dutch (Netherlands)',
  'en-AU': 'English (Australia)',
  'en-IE': 'English (Ireland)',
  'en-ZA': 'English (South Africa)',
  'en-GB': 'English (United Kingdom)',
  'en-US': 'English (United States)',
  'fi-FI': 'Finnish (Finland)',
  'fr-CA': 'French (Canada)',
  'fr-FR': 'French (France)',
  'de-DE': 'German (Germany)',
  'el-GR': 'Greek (Greece)',
  'he-IL': 'Hebrew (Israel)',
  'hi-IN': 'Hindi (India)',
  'hu-HU': 'Hungarian (Hungary)',
  'id-ID': 'Indonesian (Indonesia)',
  'it-IT': 'Italian (Italy)',
  'ja-JP': 'Japanese (Japan)',
  'ko-KR': 'Korean (South Korea)',
  'no-NO': 'Norwegian (Norway)',
  'pl-PL': 'Polish (Poland)',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  'ro-RO': 'Romanian (Romania)',
  'ru-RU': 'Russian (Russia)',
  'sk-SK': 'Slovak (Slovakia)',
  'es-MX': 'Spanish (Mexico)',
  'es-ES': 'Spanish (Spain)',
  'sv-SE': 'Swedish (Sweden)',
  'th-TH': 'Thai (Thailand)',
  'tr-TR': 'Turkish (Turkey)',
};

export const LANGUAGES = {
  it: 'Italiano',
  en: 'English',
};

export const FULL_LANGUAGES = Object.keys(LANG)
  .map(k => [k.split('-')[0], LANG[k].split(' ')[0]])
  .reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {});
