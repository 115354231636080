import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { SpinnerPage, useAuth, useFirestore } from '@betwyll/btw-core-backoffice';
import { notification } from 'antd';

const PermissionsContext = React.createContext(null);

export const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);

  const firestore = useFirestore();
  const auth = useAuth();

  useEffect(
    () =>
      auth().onAuthStateChanged(async user => {
        if (user) {
          try {
            const perms = {};
            const qs = await firestore()
              .collection('users')
              .doc(user.uid)
              .collection('userRoles')
              .get();
            qs.forEach(doc => {
              perms[doc.id] = doc.data().value;
            });
            setPermissions(perms);
            setLoading(false);
          } catch (e) {
            notification.error({ message: e.toString() });
          }
        }
      }),
    [setPermissions, auth, firestore],
  );

  if (loading) {
    return <SpinnerPage />;
  }

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export default PermissionsContext;

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function usePermissions() {
  const permissions = useContext(PermissionsContext);
  if (permissions === undefined) {
    throw Error('Must be used inside Permissions Context');
  }
  return permissions;
}

export function withPermissions(Component) {
  return props => {
    const permissions = useContext(PermissionsContext);
    return <Component {...props} permissions={permissions} />;
  };
}
