import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProjectsList from './List';
import ProjectsCreate from './Create';
import EditProject from './Edit';

export default function Projects() {
  return (
    <Switch>
      <Route path="/app/projects/" exact component={ProjectsList} />
      <Route path="/app/projects/create/project/" exact component={ProjectsCreate} />
      <Route
        path="/app/projects/create/template/"
        exact
        render={props => <ProjectsCreate {...props} isProject={false} />}
      />
      <Route path="/app/projects/:projectId/" component={EditProject} />
    </Switch>
  );
}
