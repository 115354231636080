import React from 'react';
import { Icon, Spin } from 'antd';
import { useAsync } from 'react-async';

async function getData({ reference }) {
  if (!reference) {
    throw new Error();
  }

  const doc = await reference.get();
  return {
    ...doc.data(),
    __id: doc.id,
  };
}

export default function ReferenceRender({ reference, render }) {
  const { data, isPending, isResolved, isRejected } = useAsync({ promiseFn: getData, reference });

  if (!reference) {
    return null;
  }

  if (isPending) {
    return <Spin />;
  }

  if (isRejected) {
    return <Icon type="danger" />;
  }

  if (isResolved) {
    return render(data);
  }
}
