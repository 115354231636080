import { Button, PageHeader, Table } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth, useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import moment from 'moment';
import ReferenceLink from '../../components/ReferenceLink';
import { usePermissions } from '../../contexts/Permissions';
import { ROLES } from '../../permissions';
import { NOTIFICATION_TYPES } from './config';

const BASE_WHERE = {
  field: 'type',
  op: '==',
  value: 'NOTIFICATION_BROADCAST_SENT',
};

const order = ['createdAt', 'desc'];

export default function NotificationsList() {
  const { t } = useTranslation('messages');
  const permission = usePermissions();
  const auth = useAuth();
  const firestore = useFirestore();

  const where = useMemo(() => {
    if (permission[ROLES.ADMIN]) {
      return BASE_WHERE;
    }
    const { uid } = auth().currentUser;
    return [
      BASE_WHERE,
      {
        field: 'userRef',
        value: firestore()
          .collection('users')
          .doc(uid),
        op: '==',
      },
    ];
  }, [permission]);

  const { data, loading } = useCollectionWhere('backofficeLogs', null, where, order);

  return (
    <>
      <PageHeader
        title={t('messages')}
        extra={[
          <Link key="create" to="create/">
            <Button>{t('create')}</Button>
          </Link>,
        ]}
      />
      <Table
        loading={loading}
        dataSource={data}
        rowKey="__id"
        columns={[
          {
            title: t('title'),
            dataIndex: 'notificationTitle',
          },
          {
            title: t('text'),
            dataIndex: 'notificationBody',
          },
          {
            title: t('type'),
            dataIndex: 'notificationType',
            render: v => NOTIFICATION_TYPES(t)[v],
          },
          {
            title: t('sent-date'),
            dataIndex: 'createdAt',
            render: v => moment(v.toDate()).format('DD/MM/YYYY HH:mm'),
          },
          {
            title: t('recipients'),
            dataIndex: 'recipientCount',
          },
          {
            title: t('sender'),
            dataIndex: 'userRef',
            render: ref => (
              <ReferenceLink
                reference={ref}
                to={u => `/app/users/${u.__id}/`}
                formatter={u => u.nickname}
              />
            ),
          },
        ]}
      />
    </>
  );
}
