import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import ProductsList from './List';
import ProductsCreate from './Create';
import EditProduct from './Edit';

function Products(prop) {
  console.log(prop);

  return (
    <Switch>
      <Route path="/app/products/create/" exact component={ProductsCreate} />
      <Route path="/app/products/:productId/" component={EditProduct} />
      <Route path="/app/products/" exact component={ProductsList} />
    </Switch>
  );
}

export default withRouter(Products);
