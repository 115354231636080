export default function htmlProccess(html, generateId, baseIndex = 0, doubleNewLine = true) {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  if (doc.body.children.length === 0 && doubleNewLine) {
    return html.split('\n\n').map((t, index) => ({
      text: `<p>${t.replace(/\n/g, '<br />')}</p>`,
      type: 'paragraph',
      __id: generateId(),
      index: baseIndex + index,
      twyllable: true,
    }));
  }

  if (doc.body.children.length === 0 && !doubleNewLine) {
    return html.split('\n').map((t, index) => ({
      text: `<p>${t}</p>`,
      type: 'paragraph',
      __id: generateId(),
      index: baseIndex + index,
      twyllable: true,
    }));
  }

  return Array.from(doc.body.children).map((node, index) => ({
    text: node.outerHTML,
    type: 'paragraph',
    __id: generateId(),
    index: baseIndex + index,
    twyllable: true,
  }));
}
