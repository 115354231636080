import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Icon, Avatar, Drawer, Button } from 'antd';
import { useToggle, IsSuperAdmin, useAuth, useLogout } from '@betwyll/btw-core-backoffice';

import logo from '../assets/logo-backoffice.svg';

const { Header, Sider, Content } = Layout;

function getUser(auth) {
  return auth().currentUser.email;
}

export default function DefaultLayout({ menuItems, children, extraDrawer }) {
  const [drawer, toggleDrawer] = useToggle();
  const { t } = useTranslation(['home', 'roles']);
  const auth = useAuth();
  const history = useHistory();

  const logout = useLogout();
  const user = useMemo(() => getUser(auth), [auth]);
  const goToReset = useCallback(() => {
    toggleDrawer();
    history.push('/app/password-reset/');
  }, [toggleDrawer, history]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          background: '#fff',
          padding: '0 24px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>
          <img src={logo} style={{ maxHeight: '50px' }} alt="logo" />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={toggleDrawer} type="link">
            <Avatar>{user.charAt(0)}</Avatar>
            <span className="ml-2">{user}</span>
          </Button>
        </div>
      </Header>
      <Layout>
        <Sider>
          <Menu theme="dark" mode="inline">
            {menuItems}
          </Menu>
        </Sider>
        <Content style={{ margin: '24px 16px', padding: 24, background: '#fff' }}>
          {children}
        </Content>
        <Drawer title="" placement="right" closable onClose={toggleDrawer} visible={drawer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'top',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 40,
            }}
          >
            <Avatar size={40}>{user.charAt(0)}</Avatar>
            <span>{user}</span>
            <IsSuperAdmin>
              <span>
                <Icon type="crown" /> {t('roles:backofficeAdmin')}
              </span>
            </IsSuperAdmin>
            <Button style={{ marginTop: '2rem' }} onClick={goToReset}>
              {t('password-reset')}
            </Button>
            <Button style={{ marginTop: '.5rem' }} onClick={logout}>
              {t('exit')}
            </Button>
            {extraDrawer}
          </div>
        </Drawer>
      </Layout>
    </Layout>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
  menuItems: PropTypes.node,
  extraDrawer: PropTypes.node,
};

DefaultLayout.defaultProps = {
  children: null,
  menuItems: null,
  extraDrawer: null,
};
