import React, { useCallback, useMemo, useState } from 'react';
import { Button, Drawer, Select, Tag, Typography } from 'antd';
import { find, cloneDeep } from 'lodash';
import { useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';
import { FILTERS } from '../constants';

const initial = { field: null, op: null, value: null };

function SingleFilterSelector({ selected, setState, state }) {
  const { t } = useTranslation(['filter']);

  return (
    <>
      {state.field && (
        <div style={{ marginBottom: '1rem' }}>
          <Typography.Title level={4}>{t('op')}</Typography.Title>
          <Select
            style={{ marginRight: '1rem', minWidth: '7rem' }}
            onChange={value => setState({ ...state, op: value })}
          >
            {selected.op.map(op => (
              <Select.Option key={op.value} value={op.value}>
                {op.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
      {state.field && state.op && (
        <div style={{ marginBottom: '1rem' }}>
          <Typography.Title level={4}>{t('value')}</Typography.Title>
          <Select
            style={{ marginRight: '1rem', minWidth: '7rem' }}
            onChange={value => setState({ ...state, value })}
            mode={state.op === FILTERS.OPS.IN.value ? 'multiple' : 'default'}
          >
            {selected.value.map(val => (
              <Select.Option key={val.value} value={val.value}>
                {val.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
}

const MIN_WIDTH = { minWidth: '10rem' };

function RemoteSelect({ selected, setState, state }) {
  const { t } = useTranslation(['filter']);

  const [text, setText] = useState({
    field: selected.value.field,
    op: '>=',
    value: '',
  });

  console.log(selected);
  const order = useMemo(() => [selected.value.field, 'asc'], [selected]);

  const { data = [] } = useCollectionWhere(selected.value.collection, null, text, order);

  console.log(data);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography.Title level={4}>{t('value')}</Typography.Title>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        style={MIN_WIDTH}
        showArrow={false}
        filterOption={false}
        onChange={value => setState({ ...state, op: FILTERS.OPS.EQUALS.value, value })}
        onSearch={strSearch => {
          const strlength = strSearch.length;
          const strFrontCode = strSearch.slice(0, strlength - 1);
          const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
          const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

          setText([
            {
              field: selected.value.field,
              op: '>=',
              value: strSearch,
            },
            {
              field: selected.value.field,
              op: '<',
              value: endcode,
            },
          ]);
        }}
      >
        {data.map(d => (
          <Select.Option key={`${d.__id};${d[selected.value.field]}`}>
            {d[selected.value.field]}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

function CreateFilter({ conditions, onAdd }) {
  const { t } = useTranslation(['filter']);

  const [state, setState] = useState({ ...initial });

  const submit = useCallback(() => {
    onAdd(state);
  });

  const selected = useMemo(() => {
    return find(conditions, c => c.field.value === state.field);
  }, [state, conditions]);

  const isQuery = selected && selected.type === FILTERS.TYPES.QUERY;
  const isRemote = selected && selected.type === FILTERS.TYPES.DYNAMIC;

  const canSubmit = isQuery || (state.field && state.value && state.op);

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>
        <div style={{ marginBottom: '1rem' }}>
          <Typography.Title level={4}>{t('field')}</Typography.Title>
          <Select
            onChange={value => setState({ field: value, op: null, value: null })}
            style={{ marginRight: '1rem', minWidth: '7rem' }}
          >
            {conditions.map(c => (
              <Select.Option key={c.field.value} value={c.field.value}>
                {c.field.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        {state.field && !(isQuery || isRemote) && (
          <SingleFilterSelector selected={selected} setState={setState} state={state} />
        )}
        {isRemote && <RemoteSelect selected={selected} setState={setState} state={state} />}
      </div>
      <Button disabled={!canSubmit} onClick={submit}>
        {t('create')}
      </Button>
    </>
  );
}

export default function FilterComposer({ conditions, onChange }) {
  const { t } = useTranslation(['filter']);

  const firestore = useFirestore();
  const [state, setState] = useState({ filters: [], modal: false });

  const setModal = useCallback(
    value => {
      setState({ ...state, modal: value });
    },
    [state],
  );

  const addFilter = useCallback(
    filter => {
      const condition = find(conditions, c => c.field.value === filter.field);

      console.log(condition, filter);

      const nextState = {
        modal: false,
      };

      if (condition.type === FILTERS.TYPES.QUERY) {
        nextState.filters = [
          ...state.filters,
          { ...condition.query, label: condition.field.label },
        ];
      } else if (condition.type === FILTERS.TYPES.DYNAMIC) {
        nextState.filters = [
          ...state.filters,
          {
            field: condition.field.value,
            op: FILTERS.OPS.EQUALS.value,
            value: firestore()
              .collection(condition.value.collection)
              .doc(filter.value.split(';')[0]),
            label: `${condition.field.label} ${FILTERS.OPS.EQUALS.label} ${
              filter.value.split(';')[1]
            }`,
          },
        ];
      } else {
        const op = find(condition.op, o => filter.op === o.value);
        if (filter.op === FILTERS.OPS.IN.value) {
          const values = condition.value.filter(v => filter.value.includes(v.value));

          nextState.filters = [
            ...state.filters,
            {
              field: condition.field.value,
              op: op.value,
              value: values.map(v => v.value),
              label: `${condition.field.label} ${op.label} (${values
                .map(v => v.label)
                .join(', ')})`,
            },
          ];
        } else {
          const value = find(condition.value, v => filter.value === v.value);
          nextState.filters = [
            ...state.filters,
            {
              field: condition.field.value,
              op: op.value,
              value: value.value,
              label: `${condition.field.label} ${op.label} ${value.label}`,
            },
          ];
        }
      }

      setState(nextState);
      if (onChange) {
        onChange(
          nextState.filters.map(f => ({
            field: f.field,
            op: f.op,
            value: f.value,
          })),
        );
      }
    },
    [conditions, onChange, state.filters, firestore],
  );

  const removeFilter = useCallback(
    index => {
      const copy = cloneDeep(state.filters);

      copy.splice(index, 1);

      setState({ ...state, filters: copy });
      if (onChange) {
        onChange(copy);
      }
    },
    [state, onChange],
  );

  console.log(state.filters);

  return (
    <>
      {state.filters.map((c, i) => (
        <Tag key={c.label} closable onClose={() => removeFilter(i)}>
          {c.label}
        </Tag>
      ))}
      <Button onClick={() => setModal(true)}>{t('add')}</Button>
      <Drawer
        visible={state.modal}
        width={250}
        closable
        onClose={() => setModal(false)}
        title={t('add')}
        destroyOnClose
      >
        <CreateFilter conditions={conditions} onAdd={addFilter} />
      </Drawer>
    </>
  );
}
