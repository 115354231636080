import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  Popconfirm,
  Typography,
  notification,
  Table,
  Drawer,
  Form,
  Select,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollection, useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';

import ReferenceLink from '../../components/ReferenceLink';

async function deleteDidacticSet([id], { reference }) {
  await reference
    .collection('linkedDidacticSets')
    .doc(id)
    .delete();
}

function AddDidacticSet({ form, t, reference, ownedDidacticSets }) {
  const { getFieldDecorator, resetFields } = form;

  const submit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, data) => {
        if (!err) {
          const ds = ownedDidacticSets.filter(d => d.__id === data.didacticSet)[0];

          await reference.collection('linkedDidacticSets').add({
            materialRef: ds.materialRef,
            didacticSetRef: ds.__ref,
          });

          notification.success({ message: t('didacticSet-added') });

          resetFields();
        }
      });
    },
    [form, ownedDidacticSets, reference, resetFields, t],
  );

  return (
    <>
      <Form onSubmit={submit}>
        <Form.Item label={t('didacticSet')} required>
          {getFieldDecorator('didacticSet', {
            rules: [{ required: true }],
          })(
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
            >
              {ownedDidacticSets.map(p => (
                <Select.Option key={p.__id}>{p.title}</Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Button htmlType="submit">{t('add')}</Button>
      </Form>
    </>
  );
}

const AddDidacticSetForm = Form.create()(AddDidacticSet);

export default function DidacticSets({ reference, linkedMaterials, readonly }) {
  const [addDrawer, setAddDrawer] = useState(false);
  const { t } = useTranslation('projects');

  const where = useMemo(() => {
    if (!linkedMaterials || linkedMaterials.length === 0) {
      return [];
    }
    return [
      {
        field: 'materialRef',
        op: 'in',
        value: linkedMaterials,
      },
    ];
  }, [linkedMaterials]);

  console.log(where);

  const { data: didacticSets, loading } = useCollection('linkedDidacticSets', reference);

  const { data: allDidacticSets } = useCollectionWhere('didacticSets', null, where);

  const available = useMemo(() => {
    const used = didacticSets.map(d => d.didacticSetRef.id);
    return allDidacticSets.filter(d => !used.includes(d.__id));
  }, [didacticSets, allDidacticSets]);

  const { run, isPending } = useAsync({
    deferFn: deleteDidacticSet,
    reference,
    onReject: () => notification.error({ message: t('delete-error') }),
  });

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '2rem' }}>
        {t('didacticSets')}
      </Typography.Title>
      <Button disabled={readonly} onClick={() => setAddDrawer(true)}>
        {t('add-didacticSet')}
      </Button>
      <Drawer
        title={t('add-didacticSet')}
        visible={addDrawer}
        closable
        onClose={() => setAddDrawer(false)}
      >
        <AddDidacticSetForm t={t} reference={reference} ownedDidacticSets={available} />
      </Drawer>
      <Table
        rowKey="__id"
        columns={[
          {
            title: t('didacticSet'),
            render: record => (
              <ReferenceLink
                reference={record.didacticSetRef}
                formatter={data => (
                  <>
                    {data.title} {data.label && <Tag>{data.label}</Tag>}
                  </>
                )}
                to={data => `/app/didacticSet/${data.__id}`}
              />
            ),
          },
          {
            title: t('actions'),
            render: (_, record) => (
              <>
                <Popconfirm
                  title={t('confirm-delete-didacticSet')}
                  key="delete"
                  onConfirm={() => run(record.__id)}
                >
                  <Button disabled={readonly} type="danger" loading={isPending}>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </>
            ),
          },
        ]}
        loading={loading}
        dataSource={didacticSets}
      />
    </>
  );
}
