import {
  Button,
  Col,
  Icon,
  InputNumber,
  List,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import {
  SpinnerBlock,
  useBack,
  useDocument,
  useFirestore,
  L10nField,
  LinkedMaterialField,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import EditableField from '../../components/EditableField';
import { getLabel } from '../../lib/utils';
import { LANGUAGES } from '../../constants';
import ReferenceLink from '../../components/ReferenceLink';
import EditableReferenceArray from '../../components/EditableReferenceArray';

async function updateMaterialCategory([data, _, onSuccess], { basePath, firestore }) {
  try {
    if (data.materialRefs) {
      // eslint-disable-next-line no-param-reassign
      data.materialRefs = data.materialRefs.map(id =>
        firestore()
          .collection('materials')
          .doc(id),
      );
    }

    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

async function deleteMaterialCategory(_, { basePath, onSuccess }) {
  try {
    await basePath.delete();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function EditMaterialCategory() {
  const { materialCategoryId } = useParams();
  const { t, i18n } = useTranslation('materials');
  const firestore = useFirestore();
  const { data: materialCategoryData, loading: loadingMaterialCategory } = useDocument(
    materialCategoryId,
    'materialCategories',
  );

  const materialCategory =
    materialCategoryData && !Array.isArray(materialCategoryData) ? materialCategoryData : null;

  console.log(materialCategory);

  const goBack = useBack('../');
  const basePath = useMemo(
    () =>
      firestore()
        .collection('materialCategories')
        .doc(materialCategoryId),
    [materialCategoryId, firestore],
  );

  const { run, isPending: updating } = useAsync({
    deferFn: updateMaterialCategory,
    basePath,
    firestore,
  });

  const { run: remove, isPending: deleting } = useAsync({
    deferFn: deleteMaterialCategory,
    basePath,
    onSuccess: goBack,
  });

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('didacticCategory')}{' '}
            {loadingMaterialCategory || !materialCategory || !materialCategory.labelL10n ? (
              <Spin />
            ) : (
              getLabel(materialCategory.labelL10n, activeLang)
            )}
          </>
        }
        onBack={goBack}
        extra={[
          <Popconfirm
            placement="left"
            title={
              materialCategory &&
              materialCategory.materialRefs &&
              materialCategory.materialRefs.length > 0
                ? t('confirm-delete-category-has-materials')
                : t('confirm-delete-category')
            }
            onConfirm={remove}
          >
            <Tooltip placement="left" title={t('delete-category')}>
              <Button type="danger" loading={deleting}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </Popconfirm>,
        ]}
      />
      {loadingMaterialCategory && <SpinnerBlock />}
      {materialCategory && materialCategory.labelL10n && (
        <>
          <EditableField
            data={materialCategory}
            label={t('name')}
            field="name"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={materialCategory}
            label={t('index')}
            field="index"
            InputComponent={<InputNumber />}
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={materialCategory}
            label={t('languages')}
            field="labelL10n"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            InputComponent={<L10nField languages={LANGUAGES} />}
            updating={updating}
            render={data =>
              Object.keys(data).map(k => (
                <Row key={k} gutter={4}>
                  <Col span={8}>{LANGUAGES[k]}</Col>
                  <Col span={16}>{data[k]}</Col>
                </Row>
              ))
            }
          />
          <EditableReferenceArray
            field="materialRefs"
            data={materialCategory}
            InputComponent={LinkedMaterialField}
            label={t('materials')}
            update={run}
            updating={updating}
            renderItem={ref => (
              <List.Item>
                <Icon type="read" />{' '}
                <ReferenceLink
                  reference={ref}
                  to={el => `/materials/${el.__id}/`}
                  formatter={m => m.title}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
}
