export const ROLES = {
  ADMIN: 'backofficeAdmin',
  TEACHER: 'teacher',
  MATERIAL_EDITOR: 'materialEditor',
  COMMUNITY_MODERATOR: 'communityModerator',
  PRODUCT_MANAGER: 'productManager',
  GROWTH_MANAGER: 'growthManager',
  STUDENT: 'student',

  STRONG: 'strong',
  SUPER: 'super',
};

export const PERMISSIONS = {
  ALLOWED_TO_LOGIN: [
    ROLES.ADMIN,
    ROLES.TEACHER,
    ROLES.MATERIAL_EDITOR,
    ROLES.COMMUNITY_MODERATOR,
    ROLES.PRODUCT_MANAGER,
    ROLES.GROWTH_MANAGER,
  ],
};

export const ROLES_KEYES = Object.keys(ROLES);
