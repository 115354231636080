import React from 'react';
import { Icon, Spin } from 'antd';
import { useAsync } from 'react-async';

async function getData({ reference, query }) {
  if (!reference || !query) {
    throw new Error();
  }
  const docs = [];
  const qs = await reference.where(query.field, query.op, query.value).get();
  qs.forEach(doc =>
    docs.push({
      ...doc.data(),
      __id: doc.id,
    }),
  );
  return docs;
}

export default function ReferenceQueryRender({ reference, query, render }) {
  const { data, isPending, isResolved, isRejected } = useAsync({
    promiseFn: getData,
    reference,
    query,
  });

  if (!reference) {
    return null;
  }

  if (isPending) {
    return <Spin />;
  }

  if (isRejected) {
    return <Icon type="danger" />;
  }

  if (isResolved) {
    return render(data);
  }
}
