import PropTypes from 'prop-types';
import {
  Button,
  Col,
  DatePicker,
  Icon,
  List,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Spin,
  Statistic,
  Tag,
} from 'antd';
import {
  ChoiceSelect,
  LinkedMaterialField,
  SpinnerBlock,
  useBack,
  useDocument,
  useFirestore,
  useGetDefaultLanguage,
  ChangeProjectOwner,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import ReferenceLink from '../../components/ReferenceLink';
import RTEditorInput from '../../components/RTEditorInput';
import { LANGUAGES as LANG, VISIBILITY_OPTIONS } from '../../constants';
import { normalizeUpload, normalizeUploadMultiple } from '../../lib/utils';
import EditableReferenceArray from '../../components/EditableReferenceArray';
import EditableField from '../../components/EditableField';
import FirebaseImage from '../../components/FirebaseImage';
import Products from './Products';
import useUploadProjectRequest from '../../hooks/useUploadProjectRequest';
import ImgCropUploadInput from '../../components/ImgCropUploadInput';
import DidacticSets from './DidacticSets';
import useFunction from '../../hooks/useFunction';
import ReferenceRender from '../../components/ReferenceRender';

const NO_HTML = ['template', 'private'];

async function updateProject([data, _, onSuccess], { current, invoke }) {
  if (NO_HTML.includes(data.visibility) && !NO_HTML.includes(current.visibility)) {
    const d = document.createElement('div');
    d.innerHTML = current.description;
    // eslint-disable-next-line no-param-reassign
    data.description = d.innerText;
  }

  if (data.linkedMaterials) {
    /* eslint-disable no-param-reassign */
    data.linkedMaterialsIds = [...data.linkedMaterials];
    delete data.linkedMaterials;
    /* eslint-enable no-param-reassign */
  }

  console.log(data);

  try {
    await invoke({ ...data, projectDocumentId: current.__id });
    if (onSuccess) onSuccess();
  } catch (e) {
    console.log(e);
    notification.error({ message: e.message });
  }
}

export default function EditProject() {
  const { projectId } = useParams();
  const firestore = useFirestore();
  const { data: project, loading: loadingProject } = useDocument(projectId, 'projects');
  const { t } = useTranslation(['projects', 'common', 'status-field', 'visibility']);
  const goBack = useBack();
  const basePath = firestore()
    .collection('projects')
    .doc(projectId);

  const followingRef = basePath.collection('projectStats').doc('followers');
  const twyllsRef = basePath.collection('projectStats').doc('twylls');

  const invoke = useFunction({ name: 'editProject' });

  const { run, isPending: updating } = useAsync({
    deferFn: updateProject,
    basePath,
    firestore,
    invoke,
    current: project,
    watch: project,
    onReject: e => console.error(e),
  });

  const functionArgs = useMemo(
    () => ({
      name: 'deleteProject',
      preBody: { projectDocumentId: projectId },
      onSuccess: goBack,
    }),
    [projectId, goBack],
  );

  const deleteProjectDocument = useFunction(functionArgs);

  const { run: remove, isPending: deleting } = useAsync({
    deferFn: deleteProjectDocument,
  });

  const archiveFunctionArgs = useMemo(
    () => ({
      name: 'archiveProject',
      preBody: { projectDocumentId: projectId },
    }),
    [projectId],
  );

  const archiveProjectDocument = useFunction(archiveFunctionArgs);

  const { run: archive, isPending: archiving } = useAsync({
    deferFn: archiveProjectDocument,
    onReject: e => {
      notification.error({ message: e.message });
    },
  });

  const isTemplate = project && project.visibility === 'template';

  const customRequest = useUploadProjectRequest({
    template: false,
    projectId,
  });

  const customRequestTemplate = useUploadProjectRequest({
    template: isTemplate,
    projectId,
  });

  const isReadonly = project.status === 'deleted';
  const defaultLang = useGetDefaultLanguage(LANG);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('project')} {loadingProject ? <Spin /> : project.name}
          </>
        }
        tags={
          project && [
            project.status === 'draft' && (
              <Tag color="blue" key="draft">
                {t('draft')}
              </Tag>
            ),
            project.status === 'published' && (
              <Tag color="green" key="published">
                {t('published')}
              </Tag>
            ),
            project.status === 'archived' && (
              <Tag color="yellow" key="archived">
                {t('archived')}
              </Tag>
            ),
            project.status === 'deleted' && (
              <Tag color="red" key="deleted">
                {t('deleted')}
              </Tag>
            ),
          ]
        }
        onBack={goBack}
        extra={[
          project.status === 'published' && (
            <Button key="draft" onClick={() => run({ publishedAt: null })}>
              {t('convert_draft')}
            </Button>
          ),
          ['draft'].includes(project.status) && (
            <Button
              type="primary"
              key="publish"
              onClick={() => run({ publishedAt: new Date().getTime(), archivedAt: null })}
            >
              {t('publish')}
            </Button>
          ),
          project.status === 'published' && (
            <Button key="archive" type="primary" loading={archiving} onClick={archive}>
              {t('archive')}
            </Button>
          ),
          project.status !== 'deleted' && (
            <Popconfirm
              key="delete"
              placement="left"
              title={t('confirm_delete')}
              onConfirm={remove}
            >
              <Button loading={deleting} type="danger">
                {t('common:delete')}
              </Button>
            </Popconfirm>
          ),
          <ChangeProjectOwner projectRef={basePath} key="change_owner" />,
        ]}
      />
      {loadingProject && <SpinnerBlock />}
      {project && (
        <>
          <Row gutter={16}>
            <Col span={6} offset={6}>
              <ReferenceRender
                reference={followingRef}
                render={({ count }) => (
                  <Statistic title={t('following')} value={count} prefix={<Icon type="team" />} />
                )}
              />
            </Col>
            <Col span={6}>
              <ReferenceRender
                reference={twyllsRef}
                render={({ count }) => (
                  <Statistic title={t('twylls')} value={count} prefix={<Icon type="fire" />} />
                )}
              />
            </Col>
          </Row>
          <EditableField
            data={project}
            label={t('name')}
            field="name"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            readonly={isReadonly}
          />
          <EditableField
            data={project}
            label={t('title')}
            field="title"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            readonly={isReadonly}
          />
          <EditableField
            data={project}
            label={t('access-criteria')}
            field="accessCriteria"
            update={run}
            updating={updating}
            InputComponent={<RTEditorInput />}
            readonly={isReadonly}
            render={__html => <div dangerouslySetInnerHTML={{ __html }} />}
            transform={value => {
              if (value.accessCriteria === '<p></p>\n') {
                // eslint-disable-next-line no-param-reassign
                value.accessCriteria = null;
              }
              return value;
            }}
          />
          {NO_HTML.includes(project.visibility) ? (
            <EditableField
              data={project}
              label={t('abstract')}
              field="description"
              update={run}
              updating={updating}
              readonly={isReadonly}
              render={__html => <div dangerouslySetInnerHTML={{ __html }} />}
            />
          ) : (
            <EditableField
              data={project}
              label={t('abstract')}
              field="description"
              update={run}
              updating={updating}
              InputComponent={<RTEditorInput />}
              readonly={isReadonly}
              render={__html => <div dangerouslySetInnerHTML={{ __html }} />}
            />
          )}
          {isTemplate ? (
            <Row gutter={16} style={{ marginTop: '1.5rem' }}>
              <Col span={3}>{t('type')}</Col>
              <Col span={10}>{t(`visibility:${project.visibility}`)}</Col>
            </Row>
          ) : (
            <EditableField
              data={project}
              label={t('type')}
              field="visibility"
              render={v => t(`visibility:${v}`)}
              update={run}
              updating={updating}
              readonly={isReadonly}
              InputComponent={<ChoiceSelect choices={VISIBILITY_OPTIONS(t)} />}
            />
          )}
          <EditableField
            data={project}
            label={t('cover')}
            field="coverUrl"
            update={run}
            updating={updating}
            readonly={isReadonly}
            decoratorExtra={{
              valuePropName: 'fileList',
              initialValue: null,
              rules: [{ required: true }],
              getValueFromEvent: normalizeUpload,
            }}
            transform={data => {
              return { coverUrl: data.coverUrl[0].response.download };
            }}
            InputComponent={
              <ImgCropUploadInput
                aspect={1440 / 688}
                text={t('cover_upload_text')}
                customRequest={customRequest}
              />
            }
            render={url => (
              <FirebaseImage src={url} alt="cover" style={{ maxWidth: 250, maxHeight: 200 }} />
            )}
          />
          {isTemplate && (
            <EditableField
              data={project}
              label={t('cover-multiple')}
              field="templateCoverUrls"
              update={run}
              updating={updating}
              readonly={isReadonly}
              decoratorExtra={{
                valuePropName: 'fileList',
                initialValue: null,
                rules: [{ required: true }],
                getValueFromEvent: normalizeUploadMultiple,
              }}
              transform={data => ({
                templateCoverUrls: [
                  ...(Array.isArray(project.templateCoverUrls) ? project.templateCoverUrls : []),
                  ...data.templateCoverUrls.map(c => c.response.download),
                ],
              })}
              InputComponent={
                <ImgCropUploadInput
                  aspect={1440 / 688}
                  customRequest={customRequestTemplate}
                  text={t('cover_upload_multiple_text')}
                />
              }
              render={urls => (
                <Row align="middle" type="flex" gutter={16}>
                  {urls &&
                    urls.map(url => (
                      <Col key={url}>
                        <Tag
                          closable
                          onClose={() => {
                            run({
                              templateCoverUrls: project.templateCoverUrls.filter(
                                src => src !== url,
                              ),
                            });
                          }}
                        >
                          <FirebaseImage
                            src={url}
                            alt="cover"
                            style={{ maxWidth: 250, maxHeight: 200 }}
                          />
                        </Tag>
                      </Col>
                    ))}
                </Row>
              )}
            />
          )}
          <EditableReferenceArray
            field="linkedMaterials"
            data={project}
            InputComponent={LinkedMaterialField}
            label={t('linked_materials')}
            update={run}
            updating={updating}
            readonly={isReadonly}
            renderItem={ref => (
              <List.Item>
                <Icon type="read" />{' '}
                <ReferenceLink
                  reference={ref}
                  to={el => `/materials/${el.__id}/`}
                  formatter={m => m.title}
                />
              </List.Item>
            )}
          />
          <EditableField
            data={project}
            label={t('open-date')}
            field="openedAt"
            update={run}
            updating={updating}
            readonly={isReadonly}
            InputComponent={
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="DD/MM/YYYY HH:mm"
                disabledDate={date => project.closedAt && moment(project.closedAt.toDate()) <= date}
              />
            }
            normalize={value => value && moment(value.toDate())}
            render={openedAt => openedAt && moment(openedAt.toDate()).format('DD/MM/YYYY HH:mm')}
            transform={data => ({
              openedAt: data.openedAt && data.openedAt.toDate().getTime(),
            })}
          />
          <EditableField
            data={project}
            label={t('close-date')}
            field="closedAt"
            update={run}
            updating={updating}
            readonly={isReadonly}
            InputComponent={
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="DD/MM/YYYY HH:mm"
                disabledDate={date => project.openedAt && moment(project.openedAt.toDate()) >= date}
              />
            }
            normalize={value => value && moment(value.toDate())}
            render={closedAt => closedAt && moment(closedAt.toDate()).format('DD/MM/YYYY HH:mm')}
            transform={data => ({
              closedAt: data.closedAt && data.closedAt.toDate().getTime(),
            })}
          />
          <EditableField
            data={project}
            label={t('topic')}
            field="topic"
            update={run}
            updating={updating}
            readonly={isReadonly}
            InputComponent={<Select />}
          />
          <EditableField
            data={project}
            label={t('lang')}
            field="languageCode"
            initial={defaultLang}
            decoratorExtra={{
              rules: [{ required: true }],
            }}
            update={run}
            updating={updating}
            render={v => LANG[v]}
            InputComponent={<ChoiceSelect choices={LANG} showSearch optionFilterProp="children" />}
          />
          <DidacticSets
            reference={basePath}
            linkedMaterials={project.linkedMaterials}
            readonly={isReadonly}
          />
          <Products
            reference={basePath}
            products={project.accessWithProducts}
            readonly={isReadonly}
          />
        </>
      )}
    </>
  );
}

EditableReferenceArray.propTypes = {
  label: PropTypes.string.isRequired,
  renderItem: PropTypes.func,
};

EditableReferenceArray.defaultProps = {
  renderItem: ({ title }) => (
    <List.Item>
      <List.Item.Meta title={title} />
    </List.Item>
  ),
};
