import { Button, Form, Input, Modal, notification, PageHeader } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFirebase, useHandleSubmit } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';

function CreateForm({ form, onSubmit, loading, userId = '' }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('users');

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
            },
            {
              type: 'email',
              message: t('not-valid-email'),
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('password')}>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('displayName')}>
        {getFieldDecorator('displayName', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('userId')}>
        {getFieldDecorator('userId', { initialValue: userId })(<Input />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={loading}>
          {t('create')}
        </Button>
      </Form.Item>
    </Form>
  );
}

const CreateUserForm = Form.create()(CreateForm);

async function createUser([data], { firebase }) {
  const token = await firebase.auth().currentUser.getIdToken(true);
  const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/createLocalUser`, {
    body: JSON.stringify({
      data: {
        ...data,
        userId: data.userId || null,
      },
    }),
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  });

  const response = await res.json();
  if (response.error) {
    throw new Error(response.error.message);
  }
  return response.result.userId;
}

export default function Create() {
  const { t } = useTranslation('users');
  const firebase = useFirebase();
  const history = useHistory();

  const { run, isPending } = useAsync({
    deferFn: createUser,
    firebase,
    onResolve: uid => {
      history.push(`/app/users/${uid}/`);
    },
    onReject: e => {
      notification.error({ message: e.message });
    },
  });

  return (
    <>
      <PageHeader title={t('create')} onBack={() => history.goBack()} />
      <CreateUserForm onSubmit={run} loading={isPending} />
    </>
  );
}

export function CreateModal({ userId }) {
  const firebase = useFirebase();
  const { t } = useTranslation('users');

  const [visible, setVisible] = useState(false);

  const { run, isPending } = useAsync({
    deferFn: createUser,
    firebase,
    onResolve: () => {
      setVisible(false);
    },
    onReject: e => {
      notification.error({ message: e.message });
    },
  });

  return (
    <>
      <Modal visible={visible} closable onCancel={() => setVisible(false)} footer={null}>
        <CreateUserForm onSubmit={run} loading={isPending} userId={userId} />
      </Modal>
      <Button onClick={() => setVisible(true)}>{t('create-local-user')}</Button>
    </>
  );
}
