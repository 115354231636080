import React, { useMemo } from 'react';
import { Button, Col, Icon, PageHeader, Row, Table, Tag } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const columns = t => [
  {
    title: t('name'),
    dataIndex: 'name',
  },
  {
    title: t('index'),
    dataIndex: 'index',
  },
  {
    title: t('materials'),
    dataIndex: 'materialRefs',
    render: data => data.length || 0,
  },
  {
    title: t('languages'),
    dataIndex: 'labelL10n',
    render: data => Object.keys(data).map(k => <Tag>{k}</Tag>),
  },
  {
    title: t('actions'),
    render: record => (
      <span>
        <Link to={`${record.__id}/`}>
          <Button>
            <Icon type="edit" />
          </Button>
        </Link>
        {/* <Divider type="vertical" /> */}
        {/* <Popconfirm title={t('are-you-sure')} onConfirm={() => record.__ref.delete()}> */}
        {/*  <Button type="danger"> */}
        {/*    <Icon type="delete" /> */}
        {/*  </Button> */}
        {/* </Popconfirm> */}

        {/* <Icon type="delete" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} /> */}
      </span>
    ),
  },
];

const ORDER = ['index', 'asc'];
const WHERE = [];

export default function MaterialsList() {
  const { data, loading } = useCollectionWhere('materialCategories', null, WHERE, ORDER);
  const { t, i18n } = useTranslation(['materials']);

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader title={t('materialCategories')} />
      <Row type="flex" justify="space-between" style={{ marginBottom: '1.5rem' }}>
        <Col span={4}>
          <Link to="create/">
            <Button>{t('add_new')}</Button>
          </Link>
        </Col>
      </Row>
      <Table columns={columns(t, activeLang)} dataSource={data} loading={loading} rowKey="__id" />
    </>
  );
}
