import React, { useCallback, useState } from 'react';
import { Button, Col, Icon, Input, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const STYLE = { marginRight: '1rem' };

function ArrayField({ value = [], onChange: dispatchChange, ...props }, ref) {
  console.log(value, props);
  const [data, setData] = useState(value || []);

  const onChange = useCallback(
    state => {
      setData(state);
      dispatchChange(state);
    },
    [dispatchChange],
  );

  const onAdd = useCallback(() => {
    onChange([...data, '']);
  }, [data, onChange]);

  const onRemove = useCallback(
    index => {
      const copy = [...data];
      copy.splice(index, 1);
      onChange(copy);
    },
    [data, onChange],
  );

  const onUpdate = useCallback(
    (e, index) => {
      const copy = [...data];
      copy.splice(index, 1, e.target.value);
      onChange(copy);
    },
    [data, onChange],
  );

  const { t } = useTranslation('common');

  return (
    <>
      <List
        locale={{ emptyText: t('empty') }}
        dataSource={data.map((d, i) => ({ id: i, value: d }))}
        ref={ref}
        rowKey={item => item.id}
        renderItem={item => (
          <List.Item
            extra={[
              <Button key="unlink" onClick={() => onRemove(item.id)}>
                <Icon type="minus" />
              </Button>,
            ]}
          >
            <Input value={item.value} onChange={val => onUpdate(val, item.id)} style={STYLE} />
          </List.Item>
        )}
      />
      <Row gutter={16}>
        <Col span={4}>
          <Button onClick={onAdd}>{t('add')}</Button>
        </Col>
      </Row>
    </>
  );
}

export default React.forwardRef(ArrayField);
