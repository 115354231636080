import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContentsList from './List';
import Blocks from '../Blocks';

export default function Contents() {
  return (
    <Switch>
      <Route path="/app/materials/:materialId/contents/:contentId/blocks/" component={Blocks} />
      <Route path="/app/materials/:materialId/contents/" component={ContentsList} />
    </Switch>
  );
}
