import React, { useState } from 'react';
import { Button, Drawer, Form, Icon, Slider, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHandleSubmit } from '@betwyll/btw-core-backoffice';
import { Item, Menu, MenuProvider } from 'react-contexify';
import { useParams } from 'react-router-dom';

import BlockEditor from '../EditorInline/BlockEditor';
import { normalizeUpload } from '../../../lib/utils';
import RTEditorInput from '../../../components/RTEditorInput';
import useFirebaseUploadRequest from '../../../hooks/useFirebaseUploadRequest';
import FirebaseImage from '../../../components/FirebaseImage';

function ImageForm({ form, onSubmit, initial, id }) {
  const { materialId, contentId } = useParams();
  const { getFieldDecorator } = form;
  const customRequest = useFirebaseUploadRequest(
    `materials/${materialId}/contents/${contentId}/blocks/${id}/image`,
  );
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  const { t } = useTranslation(['blocks', 'common']);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('image')}>
        {getFieldDecorator('uri', {
          valuePropName: 'fileList',
          initialValue: null,
          getValueFromEvent: normalizeUpload,
        })(
          <Upload.Dragger
            customRequest={customRequest}
            listType="picture"
            showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{t('image_upload_text')}</p>
          </Upload.Dragger>,
        )}
      </Form.Item>
      <Form.Item label={t('width')}>
        {getFieldDecorator('width', {
          initialValue: initial.width || 100,
        })(<Slider min={50} max={100} step={5} />)}
      </Form.Item>
      <Form.Item label={t('text-up')}>
        {getFieldDecorator('text', {
          initialValue: initial.text,
          valuePropName: 'text',
        })(<RTEditorInput autofocus={false} />)}
      </Form.Item>
      <Form.Item label={t('text-down')}>
        {getFieldDecorator('text2', {
          initialValue: initial.text2,
          valuePropName: 'text',
        })(<RTEditorInput autofocus={false} />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          {t('common:edit')}
        </Button>
      </Form.Item>
    </Form>
  );
}

const UpdateImage = Form.create()(ImageForm);

function ImageEditor({ uri, width, update, __id, text, text2, remove, index }) {
  const { t } = useTranslation('blocks');
  const [visible, setVisible] = useState(false);
  console.log(__id);

  return (
    <>
      <MenuProvider id={__id}>
        <div
          style={{
            width: `calc(${width}% - 80px)`,
            marginLeft: '80px',
          }}
        >
          <div style={{ position: 'absolute', marginTop: '0.1rem', marginLeft: '-80px' }}>
            <Button size="small" onClick={() => setVisible(true)}>
              <Icon type="setting" />
            </Button>
          </div>
          <div className="paragraph-content" dangerouslySetInnerHTML={{ __html: text }} />
          {!uri && (
            <div style={{ textAlign: 'center', background: '#f0f2f5' }}>
              <Icon type="picture" style={{ fontSize: '4rem', margin: '3rem 2rem' }} />
            </div>
          )}
          {uri && (
            <FirebaseImage
              src={uri}
              style={{ maxWidth: '100%', display: 'block', margin: 'auto' }}
              alt={text || text2}
            />
          )}
          <div className="paragraph-content" dangerouslySetInnerHTML={{ __html: text2 }} />
          <Drawer
            title={t('modify-image')}
            visible={visible}
            onClose={() => setVisible(false)}
            width={450}
          >
            <UpdateImage
              onSubmit={data => {
                console.log(data);
                if (data.uri) {
                  // eslint-disable-next-line no-param-reassign
                  data.uri = data.uri[0].response.download;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  delete data.uri;
                }
                update(
                  {
                    uri,
                    width,
                    text,
                    text2,
                    ...data,
                    __id,
                  },
                  () => setVisible(false),
                );
              }}
              initial={{ uri, width, text, text2 }}
              id={__id}
            />
          </Drawer>
        </div>
      </MenuProvider>
      <Menu id={__id}>
        <Item onClick={() => remove(index)}>{t('remove')}</Item>
      </Menu>
    </>
  );
}

export default function({ type, ...props }) {
  switch (type) {
    case 'image':
      return <ImageEditor {...props} />;
    default:
      return <BlockEditor {...props} />;
  }
}
