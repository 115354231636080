import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MaterialsList from './List';
import MaterialsCreate from './Create';
import EditMaterial from './Edit';

export default function DidacticCategories() {
  return (
    <Switch>
      <Route path="/app/material-categories/create/" exact component={MaterialsCreate} />
      <Route path="/app/material-categories/:materialCategoryId/" component={EditMaterial} />
      <Route path="/app/material-categories/" component={MaterialsList} />
    </Switch>
  );
}
