import ImgCrop from 'antd-img-crop';
import { Icon, Upload } from 'antd';
import React, { useCallback } from 'react';

function ImgCropUploadInput({ onChange, aspect, customRequest, text }, ref) {
  const handleChange = useCallback(
    e => {
      if (e.file.status === 'done') onChange(e);
    },
    [onChange],
  );

  return (
    <ImgCrop aspect={aspect}>
      <Upload.Dragger
        onChange={handleChange}
        customRequest={customRequest}
        listType="picture"
        showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
        ref={ref}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">{text}</p>
      </Upload.Dragger>
    </ImgCrop>
  );
}

export default React.forwardRef(ImgCropUploadInput);
