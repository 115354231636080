import { Button, Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProjectsList from './ProjectsList';

const PUBLIC = ['listed', 'unlisted'];
const PRIVATE = ['private'];

export default function TwyllsByGroup({ showPublic = true, style }) {
  const filter = showPublic ? PUBLIC : PRIVATE;

  const WHERE = useMemo(
    () => ({
      field: 'projectVisibility',
      op: Array.isArray(filter) ? 'in' : '==',
      value: filter,
    }),
    [filter],
  );

  const { data, loading } = useCollectionWhere('twyllReportings', null, WHERE);
  const { t } = useTranslation(['moderation']);
  const reportedTwylls = loading ? <Spin /> : data.length;
  const unchecked = loading ? <Spin /> : data.filter(report => !report.reviewOutcome).length;
  return (
    <Card style={style}>
      <Typography.Title level={4}>
        {showPublic ? t('public-projects') : t('private-projects')}
      </Typography.Title>
      <Row>
        <Col span={16}>
          <Typography.Paragraph>
            {t('total-reported')}: {reportedTwylls}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {t('need-check')}: {unchecked}
          </Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Link to={`twylls/?public=${showPublic}`}>
            <Button>{t('go-to-list')}</Button>
          </Link>
        </Col>
      </Row>
      {showPublic && <Divider />}
      {showPublic && <ProjectsList visibility={filter} />}
    </Card>
  );
}
