import React, { useCallback, useState } from 'react';
import { Select } from 'antd';

export default function Orderable({ defaultField, defaultDirection, fields, onChange }) {
  const [state, setState] = useState({ field: defaultField, direction: defaultDirection });

  const onSelect = useCallback(
    field => value => {
      const newState = { ...state, [field]: value };
      onChange(newState);
      setState(newState);
    },
    [state, setState, onChange],
  );

  const changeField = onSelect('field');
  const changeOrder = onSelect('direction');

  return (
    <>
      <Select defaultValue={defaultField} onChange={changeField} style={{ marginRight: '1rem' }}>
        {fields.map(f => (
          <Select.Option key={f.value} value={f.value}>
            {f.label}
          </Select.Option>
        ))}
      </Select>
      <Select defaultValue={defaultDirection} onChange={changeOrder}>
        <Select.Option value="asc">ASC</Select.Option>
        <Select.Option value="desc">DESC</Select.Option>
      </Select>
    </>
  );
}
