import React, { useCallback, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, notification, PageHeader, Row, Select } from 'antd';
import {
  useBack,
  useHandleSubmit,
  ChoiceSelect,
  useFirestore,
  useGetDefaultLanguage,
  LinkedMaterialField,
} from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { LANGUAGES as LANG, VISIBILITY_OPTIONS } from '../../constants';
import RTEditorInput from '../../components/RTEditorInput';
import { normalizeUpload, normalizeUploadMultiple } from '../../lib/utils';
import useUploadProjectRequest from '../../hooks/useUploadProjectRequest';
import ImgCropUploadInput from '../../components/ImgCropUploadInput';
import useFunction from '../../hooks/useFunction';

const NO_HTML = ['template', 'private'];

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading, id, isTemplate }) {
  console.log(isTemplate);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const { t } = useTranslation(['projects', 'home', 'visibility']);

  const customRequest = useUploadProjectRequest({
    template: false,
    projectId: id,
  });

  const customRequestTemplate = useUploadProjectRequest({
    template: isTemplate,
    projectId: id,
  });

  const currentVisibility = getFieldValue('visibility');

  useEffect(() => {
    if (NO_HTML.includes(currentVisibility)) {
      const d = document.createElement('div');
      d.innerHTML = getFieldValue('description');
      // eslint-disable-next-line no-param-reassign
      setFieldsValue({ description: d.innerText });
    }
  }, [currentVisibility, getFieldValue, setFieldsValue]);

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  const currentOpen = getFieldValue('openedAt');
  const currentClosed = getFieldValue('closedAt');
  const disabledBefore = useCallback(date => currentOpen && date <= currentOpen, [currentOpen]);
  const disabledAfter = useCallback(date => currentClosed && date >= currentClosed, [
    currentClosed,
  ]);

  const defaultLang = useGetDefaultLanguage(LANG);

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('name')} extra={t('name_hint')}>
        {getFieldDecorator('name', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('title')} extra={t('title_hint')}>
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      {!isTemplate && (
        <Form.Item label={t('type')}>
          {getFieldDecorator('visibility', {
            initialValue: 'private',
          })(<ChoiceSelect choices={VISIBILITY_OPTIONS(t)} />)}
        </Form.Item>
      )}
      {NO_HTML.includes(currentVisibility) ? (
        <Form.Item label={t('abstract')} extra={t('abstract_hint')}>
          {getFieldDecorator('description', { initialValue: '' })(<Input.TextArea rows={3} />)}
        </Form.Item>
      ) : (
        <Form.Item label={t('abstract')} extra={t('abstract_hint')}>
          {getFieldDecorator('description', { initialValue: '<p></p>', valuePropName: 'text' })(
            <RTEditorInput />,
          )}
        </Form.Item>
      )}
      <Form.Item label={t('cover')}>
        {getFieldDecorator('coverUrl', {
          valuePropName: 'fileList',
          initialValue: null,
          rules: [{ required: true }],
          getValueFromEvent: normalizeUpload,
        })(
          <ImgCropUploadInput
            aspect={1440 / 688}
            customRequest={customRequest}
            text={t('cover_upload_text')}
          />,
        )}
      </Form.Item>
      {isTemplate && (
        <Form.Item label={t('cover-multiple')}>
          {getFieldDecorator('templateCoverUrls', {
            valuePropName: 'fileList',
            initialValue: null,
            rules: [{ required: true }],
            getValueFromEvent: normalizeUploadMultiple,
          })(
            <ImgCropUploadInput
              aspect={1440 / 688}
              customRequest={customRequestTemplate}
              text={t('cover_upload_multiple_text')}
            />,
          )}
        </Form.Item>
      )}
      <Form.Item label={t('linked_materials')}>
        {getFieldDecorator('linkedMaterials', {
          valuePropsName: 'materials',
          initialValue: [],
        })(<LinkedMaterialField />)}
      </Form.Item>
      <Form.Item label={t('topic')}>
        {getFieldDecorator('topic', {
          initialValue: null,
        })(<Select />)}
      </Form.Item>
      {!isTemplate && (
        <>
          <Form.Item label={t('open-date')}>
            {getFieldDecorator('openedAt', {
              initialValue: null,
              normalize: value => value && moment(value.toDate()),
            })(
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="DD/MM/YYYY HH:mm"
                disabledDate={disabledAfter}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('close-date')}>
            {getFieldDecorator('closedAt', {
              initialValue: null,
              normalize: value => value && moment(value.toDate()),
            })(
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="DD/MM/YYYY HH:mm"
                disabledDate={disabledBefore}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('lang')}>
            {getFieldDecorator('languageCode', {
              initialValue: defaultLang,
              rules: [{ required: true }],
            })(<ChoiceSelect choices={LANG} showSearch optionFilterProp="children" />)}
          </Form.Item>
        </>
      )}
      <Row type="flex" justify="end" gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('save_as_draft')}
          </Button>
        </Col>
        <Col>
          <Button loading={loading} onClick={e => handleSubmit(e, 'publish')}>
            {t('save_and_publish')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const ProjectCreateForm = Form.create({})(CreateForm);

async function createProject([data, clean, publish], { onSuccess, invoke, isTemplate }) {
  console.log(data);

  const openedAt = data.openedAt ? data.openedAt.toDate().getTime() : null;

  const closedAt = data.closedAt ? data.closedAt.toDate().getTime() : null;

  try {
    await invoke({
      ...omit(data, 'coverUrl', 'status', 'templateCoverUrls'),
      coverUrl: data.coverUrl ? data.coverUrl[0].response.download : null,
      templateCoverUrls: data.templateCoverUrls
        ? data.templateCoverUrls.map(cover => cover.response.download)
        : null,
      createdAt: new Date().getTime(),
      publishedAt: publish ? new Date().getTime() : null,
      openedAt: isTemplate ? new Date().getTime() : openedAt,
      closedAt: isTemplate ? null : closedAt,
      deletedAt: null,
      archivedAt: null,
      modifiedAt: new Date().getTime(),
      visibility: isTemplate ? 'template' : data.visibility,
    });
    clean();
    onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function ProjectsCreate({ isProject = true }) {
  const onBack = useBack();
  const firestore = useFirestore();
  const invoke = useFunction({
    name: 'createProject',
  });

  const ref = firestore()
    .collection('projects')
    .doc();

  const { run, isPending: creating } = useAsync({
    deferFn: createProject,
    firestore,
    invoke,
    onSuccess: onBack,
    isTemplate: !isProject,
  });

  const { t } = useTranslation('projects');

  return (
    <>
      <PageHeader title={isProject ? t('new_project') : t('new_template')} onBack={onBack} />
      <ProjectCreateForm
        onSubmit={run}
        loading={creating}
        id={ref.id}
        isTemplate={!isProject}
        key={isProject}
      />
    </>
  );
}
