import { Button, Form, notification, PageHeader, Radio } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useHandleSubmit } from '@betwyll/btw-core-backoffice';
import usePredefinitedQueries from './queries';

function ExtraComposeForm({ form, onSubmit, query, loading }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('messages');
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  console.log(query);

  return (
    <Form onSubmit={handleSubmit}>
      {query.extra &&
        query.extra.map(f => (
          <Form.Item label={f.label} extra={f.hint || null}>
            {getFieldDecorator(f.name, f.options || {})(<f.input {...(f.extra || {})} />)}
          </Form.Item>
        ))}
      <Button htmlType="submit" disabled={loading} loading={loading}>
        {t('compose')}
      </Button>
    </Form>
  );
}

const ExtraForm = Form.create()(ExtraComposeForm);

const RADIO_STYLE = {
  display: 'block',
  marginBottom: '1rem',
};

export default function NotificationCreate() {
  const { t } = useTranslation('messages');
  const [state, setState] = useState(null);
  const history = useHistory();
  const queries = usePredefinitedQueries();
  const [executing, setExecuting] = useState(false);

  const submit = async data => {
    setExecuting(true);
    try {
      const userIds = await queries[state].query(data);
      history.push('../send/', userIds);
    } catch (e) {
      console.log(e);
      setExecuting(false);
      notification.error({ message: e.message });
    }
  };

  return (
    <>
      <PageHeader title={t('select-users')} onBack={history.goBack} />
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Radio.Group value={state} onChange={e => setState(e.target.value)} disabled={executing}>
          {queries.map((q, index) => (
            <Radio key={q.id} value={index} style={RADIO_STYLE}>
              {q.name}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {state !== null && (
        <ExtraForm onSubmit={submit} query={queries[state]} key={state} loading={executing} />
      )}
    </>
  );
}
