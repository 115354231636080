import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UserPaginateList from './ListNew';
import UserList from './List';
import EditUser from './Edit';
import UserCreate from './Create';

export default function Users() {
  return (
    <Switch>
      <Route path="/app/users/" exact component={UserList} />
      <Route path="/app/users/paginated/" exact component={UserPaginateList} />
      <Route path="/app/users/create/" exact component={UserCreate} />
      <Route path="/app/users/:userId/" component={EditUser} />
    </Switch>
  );
}
