import {
  Button,
  Icon,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Spin,
  Tooltip,
} from 'antd';
import {
  dateFormatter,
  ReferenceLink,
  SpinnerBlock,
  useBack,
  useDocument,
  useFirestore,
  EditableField,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

async function updatePromoCodes([data, _, onSuccess], { basePath, firestore }) {
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

export default function EditPromoCodes() {
  const { promoCodeId } = useParams();
  const { t } = useTranslation('promoCodes');
  const firestore = useFirestore();
  const { data: promoCodeData, loading: loadingPromoCodes } = useDocument(
    promoCodeId,
    'promoCodes',
  );

  const promoCode = promoCodeData && !Array.isArray(promoCodeData) ? promoCodeData : null;

  console.log(promoCode);

  const goBack = useBack('../');
  const basePath = useMemo(
    () =>
      firestore()
        .collection('promoCodes')
        .doc(promoCodeId),
    [promoCodeId, firestore],
  );

  const { run, isPending: updating } = useAsync({
    deferFn: updatePromoCodes,
    basePath,
    firestore,
  });

  return (
    <>
      <PageHeader
        title={
          <>
            {t('code')} {loadingPromoCodes && !promoCode ? <Spin /> : promoCode.__id}
          </>
        }
        onBack={goBack}
        extra={[
          <Popconfirm
            placement="left"
            title={t('confirm-delete')}
            onConfirm={() => notification.info('not implemented')}
          >
            <Tooltip placement="left" title={t('delete')}>
              <Button type="danger">
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </Popconfirm>,
        ]}
      />
      {loadingPromoCodes && <SpinnerBlock />}
      {promoCode && (
        <>
          <EditableField
            data={promoCode}
            label={t('duration')}
            field="duration"
            decoratorExtra={{ rules: [{ required: true }] }}
            InputComponent={<InputNumber />}
            update={run}
            updating={updating}
          />
          <EditableField
            data={promoCode}
            label={t('createdAt')}
            field="createdAt"
            render={date => dateFormatter({ date, t })}
            readonly
          />
          <EditableField
            data={promoCode}
            label={t('redeemedAt')}
            field="redeemedAt"
            render={date => dateFormatter({ date, t })}
            readonly
          />
          <EditableField
            data={promoCode}
            label={t('redeemedBy')}
            field="redeemedByRef"
            render={ref => (
              <ReferenceLink
                to={({ __id: id }) => `/app/users/${id}/`}
                reference={ref}
                formatter={({ nickname }) => nickname}
              />
            )}
            readonly
          />
          <EditableField
            data={promoCode}
            label={t('product')}
            field="productRef"
            render={ref => (
              <ReferenceLink
                to={({ __id: id }) => `/app/products/${id}/`}
                reference={ref}
                formatter={({ name }) => name}
              />
            )}
            readonly
          />
          <EditableField
            data={promoCode}
            label={t('batch')}
            field="batchRef"
            render={ref => (
              <ReferenceLink
                to={({ __id: id }) => `/app/promoCodeBatches/${id}/`}
                reference={ref}
                formatter={({ __id }) => __id}
              />
            )}
            readonly
          />
        </>
      )}
    </>
  );
}
