import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  Popconfirm,
  Typography,
  notification,
  Table,
  Drawer,
  Form,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';

import ReferenceLink from '../../components/ReferenceLink';

async function deleteProduct([ref], { reference, products }) {
  await reference.update({
    accessWithProducts: products.filter(r => !r.isEqual(ref)),
  });
}

const WHERE_NOT_DELETED = {
  field: 'deletedAt',
  op: '==',
  value: null,
};

function AddProduct({ form, t, reference, ownedProducts = [] }) {
  const { getFieldDecorator, resetFields } = form;
  const [search, setSearch] = useState('');
  const firestore = useFirestore();

  const submit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, { product }) => {
        if (!err) {
          console.log(product, ownedProducts);

          const data = product.map(id =>
            firestore()
              .collection('products')
              .doc(id),
          );

          await reference.update({
            accessWithProducts: [...ownedProducts, ...data],
          });

          notification.success({ message: t('needed-product-added') });

          resetFields();
        }
      });
    },
    [firestore, form, ownedProducts, reference, resetFields, t],
  );

  const where = useMemo(() => {
    if (!search) {
      return [WHERE_NOT_DELETED];
    }
    const strSearch = search;
    const strlength = strSearch.length;
    const strFrontCode = strSearch.slice(0, strlength - 1);
    const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
    const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return [
      WHERE_NOT_DELETED,
      {
        field: 'name',
        op: '>=',
        value: strSearch,
      },
      {
        field: 'name',
        op: '<',
        value: endcode,
      },
    ];
  }, [search]);

  const { data: products } = useCollectionWhere('products', null, where);

  return (
    <>
      <Form onSubmit={submit}>
        <Form.Item label={t('product')} required>
          {getFieldDecorator('product', {
            rules: [{ required: true }],
          })(
            <Select
              onSearch={v => setSearch(v)}
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              mode="multiple"
            >
              {products
                .filter(p => ownedProducts.filter(o => o.isEqual(p.__ref)).length === 0)
                .map(p => (
                  <Select.Option key={p.__id}>{p.name}</Select.Option>
                ))}
            </Select>,
          )}
        </Form.Item>
        <Button htmlType="submit">{t('insert')}</Button>
      </Form>
    </>
  );
}

const AddProductForm = Form.create()(AddProduct);

export default function Products({ reference, products = [], readonly = false }) {
  const [addDrawer, setAddDrawer] = useState(false);
  const { t } = useTranslation('products');

  const { run, isPending } = useAsync({
    deferFn: deleteProduct,
    reference,
    products,
    onReject: () => notification.error({ message: t('delete-error') }),
  });

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: '2rem' }}>
        {t('needed-products')}
      </Typography.Title>
      <Button disabled={readonly} onClick={() => setAddDrawer(true)}>
        {t('add-needed-product')}
      </Button>
      <Drawer
        title={t('add-needed-product')}
        visible={addDrawer}
        closable
        onClose={() => setAddDrawer(false)}
      >
        <AddProductForm t={t} reference={reference} ownedProducts={products} />
      </Drawer>
      <Table
        rowKey="__id"
        columns={[
          {
            title: t('product'),
            render: (_, ref) => (
              <ReferenceLink
                reference={ref}
                formatter={data => data.name}
                to={data => `/app/products/${data.__id}`}
              />
            ),
          },
          {
            title: t('actions'),
            render: (_, ref) => (
              <>
                <Popconfirm
                  title={t('confirm-remove-needed-product')}
                  key="delete"
                  onConfirm={() => run(ref)}
                >
                  <Button type="danger" loading={isPending} disabled={readonly}>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </>
            ),
          },
        ]}
        dataSource={products}
      />
    </>
  );
}
