import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './rteditor.css';

function htmlToEditor(html) {
  let editor;
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    editor = EditorState.createWithContent(contentState);
  } else {
    editor = EditorState.createEmpty();
  }
  return editor;
}

export default class RTEditorInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editor: htmlToEditor((props['data-__meta'] && props['data-__meta'].initialValue) || ''),
    };
  }

  onEditorStateChange = editor => {
    this.setState({ editor });
  };

  onEditEditorHTML = e => {
    const editorHTML = e.target.value;

    let editor;
    const contentBlock = htmlToDraft(editorHTML);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editor = EditorState.createWithContent(contentState);
    } else {
      editor = EditorState.createEmpty();
    }
    this.setState({ editor });
  };

  setEditorReference = ref => {
    this.editorReferece = ref;
    const { autofocus } = this.props;
    if (ref && autofocus) {
      ref.focus();
    }
  };

  onBlur = () => {
    const { editor } = this.state;
    const { onChange } = this.props;

    const editorHTML = draftToHtml(convertToRaw(editor.getCurrentContent()));
    onChange(editorHTML);
  };

  render() {
    const { editor } = this.state;

    return (
      <div>
        <Editor
          editorRef={this.setEditorReference}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'textAlign', 'remove', 'history', 'link'],
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
            },
          }}
          wrapperClassName="rteditor-wrapper"
          editorClassName="rteditor"
          toolbarClassName="rteditor-toolbar"
          editorState={editor}
          onEditorStateChange={this.onEditorStateChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}

RTEditorInput.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.shape({}),
  autofocus: PropTypes.bool,
};

RTEditorInput.defaultProps = {
  onChange: _ => _,
  style: {},
  autofocus: true,
};
