const stagingConfig = {
  apiKey: 'AIzaSyDnQW5ZyvxcPujlrnEzmP_4R9Pmca-7Mbw',
  authDomain: 'btw2-staging.firebaseapp.com',
  projectId: 'btw2-staging',
  storageBucket: 'btw2-staging.appspot.com',
  messagingSenderId: '577855275686',
  appId: '1:577855275686:web:5518e77eb68d2ecaf32a67',
  measurementId: 'G-2H0GB16M5J',
};

const prodConfig = {
  apiKey: 'AIzaSyB-YvKY2-9aguVGpyBRhibfxLJHmCKUN-4',
  authDomain: 'btw2-production.firebaseapp.com',
  projectId: 'btw2-production',
  storageBucket: 'btw2-production.appspot.com',
  messagingSenderId: '428730203001',
  appId: '1:428730203001:web:36a41ee65bf813add40da8',
  measurementId: 'G-9QCZ0WZQ44',
};

const config = process.env.REACT_APP_DEPLOY === 'PRODUCTION' ? prodConfig : stagingConfig;

export default config;
