import {
  Button,
  Col,
  Divider,
  Icon,
  InputNumber,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  SpinnerBlock,
  useBack,
  useCollectionWhere,
  useDocument,
  useFirestore,
  L10nField,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import EditableField from '../../components/EditableField';
import { getLabel } from '../../lib/utils';
import { LANGUAGES } from '../../constants';

async function updatedidacticCategory([data, _, onSuccess], { basePath, firestore }) {
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

const columns = t => [
  {
    title: t('name'),
    dataIndex: 'title',
    render: (text, record) => <Link to={`/app/didacticSet/${record.__id}/`}>{text}</Link>,
  },
  {
    title: t('label'),
    dataIndex: 'label',
    render: text => <span>{text}</span>,
  },
];

export default function EditdidacticCategory() {
  const { didacticCategoryId } = useParams();
  const { t, i18n } = useTranslation('materials');
  const firestore = useFirestore();
  const { data: didacticCategoryData, loading: loadingdidacticCategory } = useDocument(
    didacticCategoryId,
    'didacticCategories',
  );

  const didacticCategory =
    didacticCategoryData && !Array.isArray(didacticCategoryData) ? didacticCategoryData : null;

  console.log(didacticCategory);

  const goBack = useBack('../');
  const basePath = useMemo(
    () =>
      firestore()
        .collection('didacticCategories')
        .doc(didacticCategoryId),
    [didacticCategoryId, firestore],
  );

  const { run, isPending: updating } = useAsync({
    deferFn: updatedidacticCategory,
    basePath,
    firestore,
  });

  const where = useMemo(
    () => ({
      field: 'defaultDidacticCategoryRef',
      op: '==',
      value: basePath,
    }),
    [basePath],
  );

  const { data: didacticSets, loading: loadingSets } = useCollectionWhere(
    'didacticSets',
    null,
    where,
  );

  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader
        title={
          <>
            {t('didacticCategory')}{' '}
            {loadingdidacticCategory && !didacticCategory ? (
              <Spin />
            ) : (
              getLabel(didacticCategory.labelL10n, activeLang)
            )}
          </>
        }
        onBack={goBack}
        extra={[
          <Popconfirm
            placement="left"
            disabled={didacticSets.length > 0}
            title={t('confirm-delete-category')}
            onConfirm={() => notification.info('not implemented')}
          >
            <Tooltip
              placement="left"
              title={didacticSets.length > 0 ? t('cannot-delete-category') : t('delete-category')}
            >
              <Button type="danger" disabled={didacticSets.length > 0}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </Popconfirm>,
        ]}
      />
      {loadingdidacticCategory && <SpinnerBlock />}
      {didacticCategory && (
        <>
          <EditableField
            data={didacticCategory}
            label={t('index')}
            field="index"
            InputComponent={<InputNumber />}
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={didacticCategory}
            label={t('labels')}
            field="labelL10n"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            InputComponent={<L10nField languages={LANGUAGES} />}
            updating={updating}
            render={data =>
              Object.keys(data).map(k => (
                <Row key={k} gutter={4}>
                  <Col span={8}>{LANGUAGES[k]}</Col>
                  <Col span={16}>{data[k]}</Col>
                </Row>
              ))
            }
          />
        </>
      )}
      {didacticSets && didacticSets.length > 0 && (
        <>
          <Divider type="horizontal" />
          <Typography.Title level={4}>{t('default-in-sets')}</Typography.Title>
          <Typography.Paragraph>{t('cannot-delete-category-desc')}</Typography.Paragraph>
          <Table
            columns={columns(t)}
            dataSource={didacticSets}
            loading={loadingSets}
            rowKey="__id"
          />
        </>
      )}
    </>
  );
}
