import React, { useMemo } from 'react';
import { Alert, Button, Card, Col, Modal, PageHeader, Row, Statistic, Tag } from 'antd';
import {
  SpinnerBlock,
  useSystemStatus,
  useModal,
  useSetSystemOnline,
  useSetMaintenanceMessage,
  useCollectionWhere,
} from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';

import { firestore } from 'firebase';
import OfflineMessageForm from './OfflineMessageForm';

const today = new Date(Date.now() - 24 * 60 * 60 * 1000);
const lastHour = new Date(Date.now() - 60 * 60 * 1000);
const thisWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

function SystemPage() {
  const [modal, { open, close }] = useModal();
  const { data, isLoading } = useSystemStatus();
  const setOnline = useSetSystemOnline();

  const { t } = useTranslation(['system', 'common']);

  const where = useMemo(() => {
    return [
      {
        field: 'lastSeenOnlineDate',
        op: '>=',
        value: firestore.Timestamp.fromDate(thisWeek),
      },
    ];
  }, []);

  const { data: onlines } = useCollectionWhere('userStatus', null, where, null, true);

  const o = useMemo(() => {
    return onlines.map(({ lastSeenOnlineDate: value }) => value.toDate());
  }, [onlines]);

  const setOfflineMessageCloseModal = useSetMaintenanceMessage(clean => {
    close();
    clean();
  });
  const setOfflineMessage = useSetMaintenanceMessage();

  if (isLoading) {
    return <SpinnerBlock />;
  }

  const { online, maintenanceReason } = data;

  return (
    <div>
      <PageHeader
        title={t('system_status')}
        tags={
          online ? (
            <Tag color="green">{t('status_up')}</Tag>
          ) : (
            <Tag color="red">{t('status_down')}</Tag>
          )
        }
        extra={[
          online && (
            <Button key="offline" type="danger" onClick={open}>
              {t('go_offline')}
            </Button>
          ),
          !online && (
            <Button key="online" type="primary" onClick={setOnline}>
              {t('go_online')}
            </Button>
          ),
        ]}
      />
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic title={t('active_users_7')} value={o.length} />
            <Statistic title={t('active_users_24')} value={o.filter(d => d > today).length} />
            <Statistic title={t('active_users_1')} value={o.filter(d => d > lastHour).length} />
          </Card>
        </Col>
        {!online && (
          <Col span={16}>
            <Card>
              <OfflineMessageForm
                initialValues={{ maintenanceReason }}
                handleSubmit={setOfflineMessage}
              >
                <Button
                  htmlType="submit"
                  loading={false}
                  style={{ marginLeft: 'auto', display: 'block' }}
                >
                  {t('common:save')}
                </Button>
              </OfflineMessageForm>
            </Card>
          </Col>
        )}
      </Row>
      <Modal visible={modal} onCancel={close} footer={null} title={t('go_offline')}>
        <div className="mt-1">
          <Alert type="warning" message={t('go_offline_warning')} />
          <OfflineMessageForm
            handleSubmit={setOfflineMessageCloseModal}
            initialValues={{ maintenanceReason }}
          >
            <Row type="flex" justify="space-between">
              <Button onClick={close}>{t('common:close')}</Button>
              <Button type="danger" htmlType="submit" loading={false}>
                {t('go_offline')}
              </Button>
            </Row>
          </OfflineMessageForm>
        </div>
      </Modal>
    </div>
  );
}

export default SystemPage;
