import { Button, Divider, Icon, notification, PageHeader, Spin, Table } from 'antd';
import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth, useCollectionWhere, useFirestore } from '@betwyll/btw-core-backoffice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAsync } from 'react-async';
import ReferenceLink from '../../components/ReferenceLink';
import ReferenceRender from '../../components/ReferenceRender';
import ReferenceQueryRender from '../../components/ReferenceQueryRender';
import Img from '../Blocks/Img';
import useQuerystring from '../../hooks/useQuerystring';

function renderBlock(block) {
  switch (block.type) {
    case 'image':
      return <Img {...block} />;
    default:
      return <div dangerouslySetInnerHTML={{ __html: block.text }} />;
  }
}

async function getData({ id, firestore }) {
  const reference = firestore()
    .collection('twylls')
    .doc(id);
  const doc = await reference.get();

  const data = {
    ...doc.data(),
    __id: doc.id,
  };

  const isComment = data.replyToTwyllRef !== null;
  const baseRef = isComment ? data.replyToTwyllRef : data.blockRef;

  const res = await baseRef.get();
  return {
    ...res.data(),
    __id: res.id,
    isComment,
  };
}

function ReferenceTwyll({ id }) {
  const firestore = useFirestore();
  const asyncParams = useMemo(
    () => ({
      promiseFn: getData,
      id,
      firestore,
    }),
    [firestore, id],
  );

  const { data, isPending, isResolved, isRejected } = useAsync(asyncParams);

  if (!id) {
    return null;
  }

  if (isPending) {
    return <Spin />;
  }

  if (isRejected) {
    return <Icon type="danger" />;
  }

  if (isResolved) {
    return data.isComment ? data.body : renderBlock(data);
  }
}

class Wrapper extends React.PureComponent {
  render() {
    const { id } = this.props;
    return <ReferenceTwyll id={id} />;
  }
}

const columns = (t, reference, setOutcome) => {
  return [
    {
      title: t('date'),
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt.toDate()).format('DD/MM/YYYY hh:mm'),
    },
    {
      title: t('kind'),
      render: (_, record) => (
        <ReferenceRender
          reference={reference.collection('twylls').doc(record.__id)}
          render={data => (data.replyToTwyllRef === null ? t('twyll') : t('comment'))}
        />
      ),
    },
    {
      title: t('text'),
      render: (_, record) => (
        <ReferenceRender
          reference={reference.collection('twylls').doc(record.__id)}
          render={data => data.body}
        />
      ),
    },
    {
      title: t('anchor'),
      ellipsis: true,
      dataIndex: '__id',
      render: id => <Wrapper id={id} />,
    },
    {
      title: t('proj/mat/cont'),
      render: (_, record) => (
        <>
          <ReferenceLink
            reference={record.projectRef}
            to={data => `/app/projects/${data.__id}/`}
            formatter={data => data.name}
          />
          <Divider type="vertical" />
          <ReferenceLink
            reference={record.materialRef}
            to={data => `/app/materials/${data.__id}/`}
            formatter={data => data.title}
          />
          <Divider type="vertical" />
          <ReferenceLink
            reference={record.contentRef}
            to={data => `/app/materials/${record.materialRef.id}/contents/${data.__id}/blocks/`}
            formatter={data => data.title}
          />
        </>
      ),
    },
    {
      title: t('owner'),
      render: (_, record) => (
        <ReferenceLink
          reference={record.owner}
          to={data => `/app/users/${data.__id}/`}
          formatter={data => `@${data.nickname}`}
        />
      ),
    },
    {
      title: t('comments'),
      render: (_, record) => (
        <ReferenceRender
          reference={reference.collection('twylls').doc(record.__id)}
          render={data => data.commentsCount}
        />
      ),
    },
    {
      title: t('reports'),
      render: (_, record) => (
        <ReferenceQueryRender
          reference={reference
            .collection('twyllReportings')
            .doc(record.__id)
            .collection('twyllReportingEvents')}
          query={{ field: 'score', op: '>', value: 0 }}
          render={data => data.length}
        />
      ),
    },
    {
      title: t('score'),
      dataIndex: 'score',
    },
    {
      title: t('actions'),
      render: (_, record) => (
        <>
          <Button
            style={{ marginRight: '.5rem', marginBottom: '.5rem' }}
            onClick={() => setOutcome(record.__id, 'ok')}
          >
            <Icon type="check-circle" />
          </Button>
          <Button onClick={() => setOutcome(record.__id, 'deleted')}>
            <Icon type="stop" />
          </Button>
        </>
      ),
    },
  ];
};

function getQuery(qs, basePath) {
  const noOutcome = {
    field: 'reviewOutcome',
    op: '==',
    value: null,
  };

  if (typeof qs.public !== 'undefined') {
    return [
      {
        field: 'projectVisibility',
        op: 'in',
        value: qs.public === 'true' ? ['listed', 'unlisted'] : ['private'],
      },
      noOutcome,
    ];
  }
  if (typeof qs.project !== 'undefined') {
    return [
      {
        field: 'projectRef',
        op: '==',
        value: basePath.collection('projects').doc(qs.project),
      },
      noOutcome,
    ];
  }
  return [];
}

function getTitle(qs, basePath, t) {
  if (typeof qs.public !== 'undefined') {
    return qs.public === 'true' ? t('public-reports') : t('private-reports');
  }
  if (typeof qs.project !== 'undefined') {
    return (
      <ReferenceRender
        reference={basePath.collection('projects').doc(qs.project)}
        render={data => `${t('project-reports')} ${data.title}`}
      />
    );
  }

  return t('reports');
}

async function updateReport([id, reviewOutcome], { basePath, auth, firestore }) {
  if (reviewOutcome === 'deleted') {
    await basePath
      .collection('twylls')
      .doc(id)
      .update({
        deletedAt: firestore.Timestamp.now(),
      });
  }

  await basePath
    .collection('twyllReportings')
    .doc(id)
    .update({
      reviewOutcome,
      reviewRef: basePath.collection('users').doc(auth().currentUser.uid),
    });
}

const ORDER = [
  ['score', 'desc'],
  ['createdAt', 'desc'],
];

export default function Twylls() {
  const firestore = useFirestore();
  const auth = useAuth();
  const basePath = firestore();
  const history = useHistory();
  const qs = useQuerystring();
  const { t } = useTranslation(['moderation']);

  const q = useMemo(() => getQuery(qs, basePath), [basePath, qs]);
  const title = useMemo(() => getTitle(qs, basePath, t), [basePath, qs, t]);
  const onReject = useCallback(() => notification.error({ message: t('update-error') }), [t]);
  const goBack = useCallback(() => history.push('../'), [history]);

  const result = useCollectionWhere('twyllReportings', null, q, ORDER);
  const asyncOptions = useMemo(() => {
    return {
      deferFn: updateReport,
      basePath,
      auth,
      firestore,
      onReject,
    };
  }, [auth, basePath, firestore, onReject]);
  const { run } = useAsync(asyncOptions);

  const memoColumns = useMemo(() => {
    return columns(t, basePath, run);
  }, [t, basePath, run]);

  return (
    <>
      <PageHeader title={title} onBack={goBack} />
      <Table
        columns={memoColumns}
        dataSource={result.data}
        loading={result.loading}
        rowKey="__id"
        expandedRowRender={record => <Wrapper id={record.__id} />}
      />
    </>
  );
}
