import React, { useMemo } from 'react';
import { Button, Col, Divider, Icon, PageHeader, Popconfirm, Row, Table } from 'antd';
import { useCollectionWhere } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReferenceLink from '../../components/ReferenceLink';
import { usePermissions } from '../../contexts/Permissions';
import { ROLES } from '../../permissions';
import { getLabel } from '../../lib/utils';
import ReferenceRender from '../../components/ReferenceRender';

const columns = (t, lang, isAdmin) => [
  {
    title: t('name'),
    dataIndex: 'title',
    render: text => <span>{text}</span>,
  },
  {
    title: t('label'),
    dataIndex: 'label',
    render: text => <span>{text}</span>,
  },
  {
    title: t('material'),
    dataIndex: 'materialRef',
    render: ref => (
      <ReferenceLink
        to={({ __id }) => `/app/materials/${__id}/`}
        reference={ref}
        formatter={({ title }) => title}
      />
    ),
  },
  {
    title: t('defaultDidacticCategory'),
    dataIndex: 'defaultDidacticCategoryRef',
    render: ref => {
      if (!isAdmin) {
        return (
          <ReferenceRender reference={ref} render={({ labelL10n }) => getLabel(labelL10n, lang)} />
        );
      }
      return (
        <ReferenceLink
          to={({ __id }) => `/app/didacticCategories/${__id}/`}
          reference={ref}
          formatter={({ labelL10n }) => getLabel(labelL10n, lang)}
        />
      );
    },
  },
  {
    title: t('actions'),
    render: record => (
      <span>
        <Link to={`${record.__id}/`}>
          <Button>
            <Icon type="edit" />
          </Button>
        </Link>
        <Divider type="vertical" />
        <Popconfirm title={t('are-you-sure')} onConfirm={() => record.__ref.delete()}>
          <Button type="danger">
            <Icon type="delete" />
          </Button>
        </Popconfirm>

        {/* <Icon type="delete" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} /> */}
      </span>
    ),
  },
];

const WHERE = [];

export default function MaterialsList() {
  const { data, loading } = useCollectionWhere('didacticSets', null, WHERE);
  const { t, i18n } = useTranslation(['materials', 'home']);
  const permissions = usePermissions();
  const activeLang = useMemo(() => i18n.languages[0], [i18n]);

  return (
    <>
      <PageHeader title={t('home:didacticSet')} />
      <Row type="flex" justify="space-between" style={{ marginBottom: '1.5rem' }}>
        <Col span={4}>
          <Link to="create/">
            <Button>{t('add_new')}</Button>
          </Link>
        </Col>
        {permissions[ROLES.ADMIN] && (
          <Col span={4}>
            <Link to="/app/didacticCategories/">
              <Button>{t('didacticCategories')}</Button>
            </Link>
          </Col>
        )}
      </Row>
      <Table
        columns={columns(t, activeLang, permissions[ROLES.ADMIN])}
        dataSource={data}
        loading={loading}
        rowKey="__id"
      />
    </>
  );
}
