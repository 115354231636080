import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './ListPaginated';
import Create from './Create';
import Edit from './Edit';

export default function PromoCodes() {
  return (
    <Switch>
      <Route path="/app/promoCodes/create/" exact component={Create} />
      <Route path="/app/promoCodes/:promoCodeId/" component={Edit} />
      <Route path="/app/promoCodes/" component={List} />
    </Switch>
  );
}
