import { useFirebase } from '@betwyll/btw-core-backoffice';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useFunction({ name, method = 'POST', preBody, onSuccess, onError }) {
  const firebase = useFirebase();
  const t = useTranslation('errors');

  return useCallback(
    async body => {
      const token = await firebase.auth().currentUser.getIdToken(true);

      let data = {};
      if (body && !Array.isArray(body)) {
        data = {
          ...data,
          ...body,
        };
      }
      if (preBody && !Array.isArray(preBody)) {
        data = {
          ...data,
          ...preBody,
        };
      }
      try {
        const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/${name}`, {
          body: JSON.stringify({ data }),
          method,
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }),
        });

        if (res.status === 500) {
          throw Error(t('error-500'));
        }

        if (res.status === 204) {
          if (onSuccess) {
            onSuccess({ response: null });
          }
          return null;
        }

        const response = await res.json();

        if (response.error) {
          throw Error(response.error.message);
        }
        if (response.result && onSuccess) {
          onSuccess({ response });
        }
        return response;
      } catch (e) {
        if (onError) {
          onError(e);
        } else {
          throw e;
        }
      }
      return null;
    },
    [firebase, preBody, name, method, onSuccess, t, onError],
  );
}
