import React from 'react';
import { Button, Col, Form, Input, InputNumber, notification, PageHeader, Row } from 'antd';
import { useBack, useFunction, useHandleSubmit } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import ReferenceChoice from '../../components/ReferenceChoice';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function CreateForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;

  const { t } = useTranslation('promoCodeBatches');

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  return (
    <Form onSubmit={handleSubmit} {...formLayout}>
      <Form.Item label={t('size')} extra={t('batch-size-hint')}>
        {getFieldDecorator('batchSize', {
          rules: [
            {
              required: true,
            },
          ],
        })(<InputNumber min={0} />)}
      </Form.Item>
      <Form.Item label={t('product')}>
        {getFieldDecorator('productRef', {
          rules: [{ required: true }],
        })(<ReferenceChoice collection="products" render={product => product.name} />)}
      </Form.Item>
      <Form.Item label={t('duration')} extra={t('duration-hint')}>
        {getFieldDecorator('duration')(<InputNumber min={0} />)}
      </Form.Item>
      <Form.Item label={t('notes')}>{getFieldDecorator('notes')(<Input.TextArea />)}</Form.Item>
      <Row type="flex" justify="end">
        <Col span={4}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('create')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const PromoCodeCreateForm = Form.create()(CreateForm);

async function createPromoCode([data, clean], { invoke, onSuccess }) {
  try {
    await invoke({
      ...pick(data, 'duration', 'notes', 'batchSize'),
      productId: data.productRef.id,
    });
    if (clean) {
      clean();
    }
    onSuccess();
  } catch (e) {
    console.warn(e);
    notification.error({ message: e.message });
  }
}

export default function PromoCodeCreate() {
  const onBack = useBack();

  const invoke = useFunction({
    name: 'actions/promoCodesBatchCreation',
    base: process.env.REACT_APP_WORKER_FUNCTIONS_BASE,
  });

  const { run, isPending: creating } = useAsync({
    deferFn: createPromoCode,
    invoke,
    onSuccess: onBack,
  });

  const { t } = useTranslation('promoCodeBatches');

  return (
    <>
      <PageHeader title={t('new-promoCode-batch')} onBack={onBack} />
      <PromoCodeCreateForm onSubmit={run} loading={creating} />
    </>
  );
}
