import React, { useEffect, useMemo, useState } from 'react';
import { Button, Icon, PageHeader, Tag, Table, Row, Col, Input } from 'antd';
import { useFirestore } from '@betwyll/btw-core-backoffice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ROLES, ROLES_KEYES } from '../../permissions';
import ReferenceRender from '../../components/ReferenceRender';

const columns = (t, firestore) => [
  {
    title: t('nickname'),
    dataIndex: 'nickname',
    /* sorter: (a, b) => {
      const { nickname: nameA = '' } = a;
      const { nickname: nameB = '' } = b;
      return nameA.localeCompare(nameB);
    }, */
  },
  {
    title: t('name'),
    dataIndex: 'name',
    /* sorter: (a, b) => {
      const { name: nameA = '' } = a;
      const { name: nameB = '' } = b;
      return nameA.localeCompare(nameB);
    }, */
  },
  {
    title: t('twylls'),
    dataIndex: 'twyllsCount',
    /* sorter: (a, b) => {
      const { twyllsCount: parsedA = 0 } = a;
      const { twyllsCount: parsedB = 0 } = b;
      return parsedA - parsedB;
    }, */
    width: 100,
  },
  {
    title: t('last-login'),
    render: (_, record) => {
      const ref = firestore()
        .collection('users')
        .doc(record.__id)
        .collection('userStatus')
        .doc('lastSeenOnline');
      return (
        <ReferenceRender
          reference={ref}
          render={data => {
            return data.lastSeenOnlineDate
              ? moment(data.lastSeenOnlineDate.toDate()).format('DD/MM/YYYY H:mm')
              : t('never');
          }}
        />
      );
    },
  },
  {
    title: t('roles'),
    render: (_, record) => {
      const ref = firestore()
        .collection('users')
        .doc(record.__id);

      return (
        <>
          {ROLES_KEYES.map(rk => (
            <ReferenceRender
              key={rk}
              reference={ref.collection('userRoles').doc(ROLES[rk])}
              render={({ value }) => {
                return value ? (
                  <>
                    <Tag style={{ marginBottom: '.5rem' }}>{t(`roles:${ROLES[rk]}`)}</Tag>
                    <br />
                  </>
                ) : null;
              }}
            />
          ))}
        </>
      );
    },
  },
  {
    title: t('actions'),
    render: (_, record) => (
      <span>
        <Link to={`../${record.__id}/`}>
          <Icon type="edit" style={{ fontSize: '1.3rem', marginRight: '.5rem' }} />
        </Link>
      </span>
    ),
  },
];

function getTitle(t, options = {}) {
  if (options.following) {
    return t('following-project');
  }
  return t('home:users');
}

const PAGE_LIMIT = 10;

function searchByNickname(ref, strSearch) {
  const strlength = strSearch.length;
  const strFrontCode = strSearch.slice(0, strlength - 1);
  const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
  const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
  return ref.where('nickname', '>=', strSearch).where('nickname', '<', endcode);
}

async function addData(setState, state, firestore, reset = false) {
  setState({ ...state, loading: true });
  const prevCursors = reset ? [] : [...state.prevCursors];
  let ref = firestore()
    .collection('users')
    .orderBy('nickname');
  if (state.search) {
    ref = searchByNickname(ref, state.search);
  }
  if (!reset && state.data.length > 0) {
    const lastRef = state.data[state.data.length - 1].__doc;
    ref = ref.startAfter(lastRef);
    prevCursors.push(lastRef);
  }
  ref = ref.limit(PAGE_LIMIT);
  const qs = await ref.get();
  const res = qs.docs.map(d => ({ __id: d.id, __ref: d.ref, ...d.data(), __doc: d }));
  setState({
    prevCursors,
    data: res,
    loading: false,
    search: state.search,
    hasMore: res.length === PAGE_LIMIT,
  });
}

async function loadPrev(setState, state, firestore) {
  setState({ ...state, loading: true });
  const prevCursors = [...state.prevCursors];
  prevCursors.pop();
  let ref = firestore()
    .collection('users')
    .orderBy('nickname');
  if (state.search) {
    ref = searchByNickname(ref, state.search);
  }
  if (prevCursors.length > 0) {
    const lastRef = prevCursors[prevCursors.length - 1];
    ref = ref.startAfter(lastRef);
  }
  ref = ref.limit(PAGE_LIMIT);
  const qs = await ref.get();
  const res = qs.docs.map(d => ({ __id: d.id, __ref: d.ref, ...d.data(), __doc: d }));

  setState({
    prevCursors,
    data: res,
    loading: false,
    search: state.search,
    hasMore: res.length === PAGE_LIMIT,
  });
}

function UserList() {
  const [state, setState] = useState({
    prevCursors: [],
    data: [],
    loading: true,
    hasMore: true,
    search: '',
  });

  const firestore = useFirestore();

  useEffect(() => {
    console.log('reset');
    addData(setState, state, firestore, true);
  }, [state.search]);

  const { t } = useTranslation('users');

  const pagination = useMemo(() => {
    return (
      <Row type="flex" justify="space-between" style={{ margin: '1.5rem 0' }}>
        <Col md={4}>
          <Button
            disabled={state.loading || state.prevCursors.length === 0}
            onClick={() => loadPrev(setState, state, firestore)}
          >
            {t('prev')}
          </Button>
        </Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          <Button
            disabled={!state.hasMore || state.loading}
            onClick={() => addData(setState, state, firestore)}
          >
            {t('next')}
          </Button>
        </Col>
      </Row>
    );
  }, [state]);

  return (
    <>
      <PageHeader
        title={getTitle(t)}
        extra={[
          <Link to="../create/" key="1">
            <Button>{t('create')}</Button>
          </Link>,
        ]}
      />
      <Input.Search
        onSearch={v => setState({ ...state, search: v })}
        style={{ width: 250 }}
        placeholder={t('nickname-starts-by')}
      />
      {pagination}
      <Table
        columns={columns(t, firestore)}
        dataSource={state.data}
        pagination={false}
        loading={state.loading}
        rowKey="__id"
      />
      {pagination}
    </>
  );
}

export default UserList;
