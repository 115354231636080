import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Button, Icon } from 'antd';

function getElements(htmlText) {
  const doc = new DOMParser().parseFromString(htmlText, 'text/html');
  return Array.from(doc.body.children);
}

export function Text({ text, split, index, canSplit }) {
  const elements = useMemo(() => getElements(text), [text]);
  const splitBlock = useCallback(
    i => {
      const old = elements
        .slice(0, i)
        .map(e => e.outerHTML)
        .join('');
      const newBlock = elements
        .slice(i)
        .map(e => e.outerHTML)
        .join('');
      split(index, old, newBlock);
    },
    [elements, index, split],
  );
  return (
    <div style={{ width: '100%' }}>
      {elements.map((e, i) => (
        <>
          {i > 0 && canSplit && (
            <div style={{ height: '1rem' }}>
              <div
                style={{
                  borderTop: '1px dashed rgba(0,0,0,0.35)',
                  margin: '1rem auto',
                  height: 0,
                }}
              />
              <Button
                size="small"
                onClick={() => splitBlock(i)}
                style={{
                  position: 'relative',
                  top: '-1.7rem',
                  left: 'calc(50% - 25px)',
                }}
              >
                <Icon type="scissor" />
              </Button>
            </div>
          )}
          <div className="paragraph-content" dangerouslySetInnerHTML={{ __html: e.outerHTML }} />
        </>
      ))}
    </div>
  );
}

Text.propTypes = {
  text: PropTypes.string.isRequired,
};

export default null;
