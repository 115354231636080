import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Icon,
  List,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Statistic,
  Tag,
  Upload,
  Input,
  Typography,
} from 'antd';
import moment from 'moment';
import {
  ChoiceSelect,
  isOnline,
  SpinnerBlock,
  useBack,
  useCollection,
  useCollectionWhere,
  useDocument,
  useFirestore,
} from '@betwyll/btw-core-backoffice';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import { normalizeUpload } from '../../lib/utils';
import EditableReferenceArray from '../../components/EditableReferenceArray';
import EditableField from '../../components/EditableField';
import FirebaseImage from '../../components/FirebaseImage';
import Devices from './Devices';
import Products from './Products';
import useUploadProfileRequest from '../../hooks/useUploadProfileRequest';
import { ROLES, ROLES_KEYES } from '../../permissions';
import Accounts from './Accounts';
import useFunction from '../../hooks/useFunction';
import { GroupsIn, GroupsOwned } from './Groups';
import { FULL_LANGUAGES } from '../../constants';

async function updateUser([data, _, onSuccess], { basePath, firestore }) {
  console.log(data);
  try {
    await basePath.update({ ...data, modifiedAt: firestore.Timestamp.now() });
    if (onSuccess) onSuccess();
  } catch (e) {
    notification.error({ message: e.message });
  }
}

async function setUserPermission([role, value], { basePath }) {
  try {
    await basePath
      .collection('userRoles')
      .doc(role)
      .set({
        value,
        grantedByBackoffice: true,
        roleId: role,
      });
  } catch (e) {
    notification.error({ message: e.message });
  }
}

function UserTitle({ loadingUser, loadingLastSeen, lastSeen, user }) {
  const userText = useMemo(() => {
    return <>{loadingUser ? <Spin /> : user.nickname}</>;
  }, [user, loadingUser]);

  const onlineText = useMemo(() => {
    let color = null;
    if (loadingLastSeen) {
      color = 'grey';
    } else if (!loadingLastSeen && !lastSeen.lastSeenOnlineDate) {
      color = 'red';
    } else {
      const online = isOnline(lastSeen.lastSeenOnlineDate);
      color = online ? 'green' : 'red';
    }
    return <Icon type="cloud" twoToneColor={color} theme="twoTone" />;
  }, [lastSeen, loadingLastSeen]);

  return (
    <>
      {userText} {onlineText}
    </>
  );
}

function ProjectsCount({ user }) {
  const WHERE = useMemo(
    () => [
      {
        field: 'ownerRef',
        op: '==',
        value: user,
      },
    ],
    [user],
  );
  const { data, loading } = useCollectionWhere('projects', null, WHERE);
  const { t } = useTranslation(['users', 'common', 'roles']);

  if (loading) {
    return <Spin />;
  }

  return (
    <Link to={`/app/projects/?owner=${user.id}&visibility=private`}>
      <Statistic
        title={t('owned-projects')}
        value={data.length}
        prefix={<Icon type="project" />}
        valueStyle={{ color: '#1890ff' }}
      />
    </Link>
  );
}

export default function EditUser() {
  const { userId } = useParams();
  const firestore = useFirestore();
  const { data: user, loading: loadingProject } = useDocument(userId, 'users');
  const { t } = useTranslation(['users', 'common', 'roles']);
  const goBack = useBack();
  const basePath = firestore()
    .collection('users')
    .doc(userId);

  const { data: lastSeenOnline, isPending: loadingLastSeen } = useDocument(
    'lastSeenOnline',
    null,
    basePath.collection('userStatus'),
  );

  const { data: rolesRaw, isPending: loadingRoles } = useCollection('userRoles', basePath);

  const roles = useMemo(() => {
    const rolesDict = {};

    if (rolesRaw) {
      rolesRaw.forEach(r => {
        rolesDict[r.__id] = r.value;
      });
    }

    return rolesDict;
  }, [rolesRaw]);

  const { run, isPending: updating } = useAsync({
    deferFn: updateUser,
    basePath,
    firestore,
  });

  const deleteUser = useFunction({
    name: 'deleteUser',
    preBody: {
      userUid: userId,
    },
  });

  const { run: remove, isPending: deleting } = useAsync({
    deferFn: deleteUser,
    firestore,
    onResolve: () => {
      notification.success({ message: t('delete_notification') });
      goBack();
    },
    onReject: e => notification.error({ message: e.message }),
  });

  const { run: setPermission } = useAsync({
    deferFn: setUserPermission,
    basePath,
  });

  const customRequest = useUploadProfileRequest({ userId });

  return (
    <>
      <PageHeader
        title={
          <UserTitle
            user={user}
            loadingUser={loadingProject}
            lastSeen={lastSeenOnline}
            loadingLastSeen={loadingLastSeen}
          />
        }
        tags={ROLES_KEYES.filter(rk => roles[ROLES[rk]]).map(rk => (
          <Tag key={ROLES[rk]}>{t(`roles:${ROLES[rk]}`)}</Tag>
        ))}
        onBack={goBack}
        extra={[
          <Button
            key="suspend"
            onClick={() =>
              run({
                suspendedAt: user.suspendedAt ? null : firestore.Timestamp.now(),
              })
            }
          >
            {user.suspendedAt ? t('unsuspend') : t('suspend')}
          </Button>,
          <Popconfirm key="delete" placement="left" title={t('confirm_delete')} onConfirm={remove}>
            <Button loading={deleting} type="danger">
              {t('common:delete')}
            </Button>
          </Popconfirm>,
        ]}
      />
      {loadingProject && <SpinnerBlock />}
      {user && (
        <>
          <Row gutter={16}>
            <Col span={6}>
              <Statistic
                title={t('following-users')}
                value={user.followingUsersRefs && user.followingUsersRefs.length}
                prefix={<Icon type="team" />}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('followers')}
                value={user.followerUsersRefs && user.followerUsersRefs.length}
                prefix={<Icon type="team" />}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('following-projects')}
                value={user.followingProjectsRefs && user.followingProjectsRefs.length}
                prefix={<Icon type="project" />}
              />
            </Col>
            <Col span={6}>
              <ProjectsCount user={basePath} />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('comments')}
                value={user.commentsCount}
                prefix={<Icon type="message" />}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('twylls')}
                value={user.twyllsCount}
                prefix={<Icon type="fire" />}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('created')}
                value={user.createdAt && moment(user.createdAt.toDate()).format('DD/MM/YYYY')}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title={t('last-login')}
                value={
                  lastSeenOnline && lastSeenOnline.value
                    ? moment(lastSeenOnline.value.toDate()).format('DD/MM/YYYY HH:mm')
                    : t('never')
                }
              />
            </Col>
          </Row>
          <EditableField
            data={user}
            label={t('name')}
            field="name"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
          />
          <EditableField
            data={user}
            label={t('nickname')}
            field="nickname"
            decoratorExtra={{ rules: [{ required: true }] }}
            update={run}
            updating={updating}
            transform={({ nickname }) => {
              const lower = nickname ? nickname.toLowerCase() : nickname;
              return {
                nickname: lower,
              };
            }}
          />
          <EditableField
            data={user}
            label={t('bio')}
            field="bio"
            update={run}
            updating={updating}
            InputComponent={<Input.TextArea rows={2} />}
          />
          <EditableField
            data={user}
            label={t('profile-picture')}
            field="profilePictureUri"
            update={run}
            updating={updating}
            decoratorExtra={{
              valuePropName: 'fileList',
              initialValue: null,
              rules: [{ required: true }],
              getValueFromEvent: normalizeUpload,
            }}
            transform={data => ({ profilePictureUri: data.profilePictureUri[0].response.download })}
            InputComponent={
              <Upload.Dragger
                customRequest={customRequest}
                listType="picture"
                showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">{t('cover_upload_text')}</p>
              </Upload.Dragger>
            }
            render={url => (
              <FirebaseImage src={url} alt="cover" style={{ maxWidth: 250, maxHeight: 200 }} />
            )}
          />
          <EditableField
            data={user}
            label={t('preferredLanguages')}
            field="preferredLanguageCodes"
            update={run}
            render={value => (value ? value.map(v => FULL_LANGUAGES[v]).join(', ') : '')}
            updating={updating}
            InputComponent={<ChoiceSelect choices={FULL_LANGUAGES} mode="multiple" />}
          />
          <Accounts reference={basePath} />
          <Devices reference={basePath} />
          <div>
            <Typography.Title level={4} style={{ marginTop: '1rem' }}>
              {t('permissions')}
            </Typography.Title>
            <Row gutter={16} type="flex">
              {ROLES_KEYES.map(r => (
                <Col key={r}>
                  <Button
                    disabled={loadingRoles}
                    onClick={() =>
                      setPermission(ROLES[r], roles[ROLES[r]] ? !roles[ROLES[r]] : true)
                    }
                  >
                    {t(roles[ROLES[r]] ? 'common:unset' : 'common:set')} {t(`roles:${ROLES[r]}`)}
                  </Button>
                </Col>
              ))}
            </Row>
            <Products reference={basePath} />
            <GroupsOwned reference={basePath} />
            <GroupsIn reference={basePath} />
          </div>
        </>
      )}
    </>
  );
}

EditableReferenceArray.propTypes = {
  label: PropTypes.string.isRequired,
  renderItem: PropTypes.func,
};

EditableReferenceArray.defaultProps = {
  renderItem: ({ title }) => (
    <List.Item>
      <List.Item.Meta title={title} />
    </List.Item>
  ),
};
