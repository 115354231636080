import React from 'react';
import FirebaseImage from '../../components/FirebaseImage';

export default function Img({ uri, width = 'auto', height = 'auto', text, text2 }) {
  return (
    <>
      <div className="paragraph-content" dangerouslySetInnerHTML={{ __html: text }} />
      <FirebaseImage
        src={uri}
        style={{ maxWidth: `${width}%`, height, display: 'block', margin: '1rem auto' }}
        alt="inline"
      />
      <div className="paragraph-content" dangerouslySetInnerHTML={{ __html: text2 }} />
    </>
  );
}
