import React, { Suspense } from 'react';
import { SpinnerPage } from '@betwyll/btw-core-backoffice';
import { PermissionProvider } from '../contexts/Permissions';
import Home from './Home';

export default function HomeWrapper() {
  return (
    <PermissionProvider>
      <Suspense fallback={<SpinnerPage />}>
        <Home />
      </Suspense>
    </PermissionProvider>
  );
}
