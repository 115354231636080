import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase, useHandleSubmit, useToggle } from '@betwyll/btw-core-backoffice';
import { useAsync } from 'react-async';

function ResetForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('users');

  const compareToFirstPassword = useCallback(
    (rule, value, callback) => {
      if (value && value !== form.getFieldValue('newPassword')) {
        callback(t('password-no-match'));
      } else {
        callback();
      }
    },
    [form, t],
  );

  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('password')}>
        {getFieldDecorator('newPassword', {
          rules: [
            {
              required: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('confirmPassword')}>
        {getFieldDecorator('confirmPassword', {
          rules: [
            {
              required: true,
            },
          ],
          validator: compareToFirstPassword,
        })(<Input />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={loading}>
          {t('reset')}
        </Button>
      </Form.Item>
    </Form>
  );
}

const ResetUserForm = Form.create()(ResetForm);

async function resetUserPassword([data], { firebase, userId }) {
  const token = await firebase.auth().currentUser.getIdToken(true);
  const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/changeUserPassword`, {
    body: JSON.stringify({
      data: {
        ...data,
        userId,
      },
    }),
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  });

  const response = await res.json();
  if (response.error) {
    throw new Error(response.error.message);
  }
  console.log(response);
}

export default function ResetPassword({ userId }) {
  const firebase = useFirebase();
  const [state, toggle] = useToggle();
  const { t } = useTranslation('users');

  const { run, isPending } = useAsync({
    deferFn: resetUserPassword,
    firebase,
    userId,
    onResolve: toggle,
    onReject: e => {
      notification.error({ message: e.message });
    },
  });

  return (
    <>
      <Button onClick={toggle}>{t('reset-password')}</Button>
      <Modal visible={state} closable onCancel={toggle} footer={null} title={t('reset-password')}>
        <ResetUserForm onSubmit={run} loading={isPending} />
      </Modal>
    </>
  );
}
