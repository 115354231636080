import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Input, InputNumber, notification, Switch } from 'antd';
import { useFirebase } from '@betwyll/btw-core-backoffice';
import RoleSelect from '../../components/RoleSelect';
import RemoteSelect from '../../components/RemoteSelect';

const WHERE_NOT_PROJECT_DELETED = [
  {
    field: 'deletedAt',
    op: '==',
    value: null,
  },
];

const queries = (t, firebase) => [
  {
    id: 'inactive-users',
    name: t('users-inactive'),
    query: async data => {
      console.log(data);
      const first = [];
      const regDate = firebase.firestore.Timestamp.fromDate(
        new Date(Date.now() - data.regDays * 60 * 60 * 1000),
      );
      const qs = await firebase
        .firestore()
        .collection('users')
        .where('createdAt', '<', regDate)
        .get();

      qs.forEach(doc => first.push(doc.id));

      const lastTwyll = firebase.firestore.Timestamp.fromDate(
        new Date(Date.now() - data.lastTwyllDays * 60 * 60 * 1000),
      );

      console.log(first, lastTwyll);
      const promises = first.map(async userId => {
        const twylls = await firebase
          .firestore()
          .collection('twylls')
          .where(
            'owner',
            '==',
            firebase
              .firestore()
              .collection('users')
              .doc(userId),
          )
          .where('createdAt', '>=', lastTwyll)
          .get();

        if (twylls.size === 0) {
          return userId;
        }
        return null;
      });

      const result = await Promise.all(promises);
      console.log(result);
      return result.filter(_ => _);
    },
    extra: [
      {
        input: InputNumber,
        label: t('registration-days-ago'),
        name: 'regDays',
        options: {
          rules: [{ required: true, message: t('required') }],
        },
      },
      {
        input: InputNumber,
        label: t('last-twyll-since-days'),
        name: 'lastTwyllDays',
        options: {
          rules: [{ required: true, message: t('required') }],
        },
      },
    ],
  },
  {
    id: 'users-by-id',
    name: t('users-by-id'),
    query: async data => {
      const ids = data.ids.split(',').map(token => token.replace(/\s/g, ''));
      return ids;
    },
    extra: [
      {
        input: Input.TextArea,
        label: t('ids'),
        name: 'ids',
        hint: t('separate-with-comma'),
        options: {
          rows: 3,
          rules: [{ required: true, message: t('required') }],
        },
      },
    ],
  },
  {
    id: 'users-following-project-with-role',
    name: t('users-following-project-with-role'),
    query: async data => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/usersCustomQuery`, {
        body: JSON.stringify({
          data: {
            followingProjectsIds: [data.projects],
            userRoleId: data.role,
            languagePreferences: data.languagePreferences,
          },
        }),
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      });

      try {
        const response = await res.json();
        return response.result.userIdList;
      } catch (e) {
        console.log(e);
        notification.error({ message: t('error-fetching') });
        return [];
      }
    },
    extra: [
      {
        input: RemoteSelect,
        extra: {
          collection: 'projects',
          extraWhere: WHERE_NOT_PROJECT_DELETED,
          field: 'name',
          mode: 'single',
        },
        options: {
          rules: [{ required: true, message: t('required') }],
        },
        label: t('project'),
        name: 'projects',
      },
      {
        input: RoleSelect,
        label: t('role'),
        name: 'role',
      },
      {
        input: Switch,
        label: t('if-language-preferences'),
        name: 'languagePreferences',
      },
    ],
  },
  {
    id: 'users-with-role',
    name: t('users-with-role'),
    query: async data => {
      const token = await firebase.auth().currentUser.getIdToken(true);
      const res = await fetch(`${process.env.REACT_APP_FUNCTIONS_BASE}/usersCustomQuery`, {
        body: JSON.stringify({
          data: {
            followingProjectsIds: null,
            userRoleId: data.role,
          },
        }),
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      });

      try {
        const response = await res.json();
        return response.result.userIdList;
      } catch (e) {
        console.log(e);
        notification.error({ message: t('error-fetching') });
        return [];
      }
    },
    extra: [
      {
        input: RoleSelect,
        label: t('role'),
        name: 'role',
      },
    ],
  },
];

export default function usePredefinitedQueries() {
  const { t } = useTranslation('queries');
  const firebase = useFirebase();

  return useMemo(() => {
    return queries(t, firebase);
  }, [firebase, t]);
}
