import React from 'react';
import { Link } from 'react-router-dom';
import ReferenceRender from './ReferenceRender';

export default function ReferenceLink({ reference, formatter, to }) {
  return (
    <ReferenceRender
      reference={reference}
      render={data => <Link to={to(data)}>{formatter(data)}</Link>}
    />
  );
}
