import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MaterialsList from './List';
import Contents from '../Contents';
import MaterialsCreate from './Create';
import EditMaterial from './Edit';

export default function Materials() {
  return (
    <Switch>
      <Route path="/app/materials/:materialId/contents/" component={Contents} />
      <Route path="/app/materials/create/" exact component={MaterialsCreate} />
      <Route path="/app/materials/:materialId/" component={EditMaterial} />
      <Route path="/app/materials/" component={MaterialsList} />
    </Switch>
  );
}
